import React from "react";
import CircleAvatar from "../circle_avatar";
import ChatHeaderTag from "./tag";

function ChatHeader({ chat }) {
  return (
    <div className="min-h-[52px] px-2 flex justify-between">
      <div className="flex flex-col justify-center">
        <div className="text-[16px] font-medium">{chat?.name}</div>
        <div className="flex gap-2">
          {chat?.contact_info?.tags.length > 3 ? (
            <div className="flex gap-2">
              {chat?.contact_info?.tags.slice(0, 3).map((tag, key) => (
                 <ChatHeaderTag key={key} tag={tag}/>
              ))}
              <ChatHeaderTag tag={{color: "#5C42F9", tag : `+${chat?.contact_info?.tags.length - 2}`}}/>
            </div>
          ) : (
            chat?.contact_info?.tags.map((tag, key) => (
              <ChatHeaderTag key={key} tag={tag}/>
            ))
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="flex items-center gap-1">
          <label className=" text-[12px] text-gray-200">Assign to</label>
          <button className=" hover:bg-gray-50 text-[12px] border rounded-full border-gray-100 px-1 flex items-center gap-2">
            <CircleAvatar profileImage={null} name="John Doe" size="w-4 h-4" />
            <label className=" text-[12px] text-ellipsis">John Doe</label>
            <span className="material-symbols-outlined text-sm">
              expand_more
            </span>
          </button>
        </div>
        <button className="flex w-6 h-6 items-center justify-center hover:bg-primary-light hover:text-primary-color rounded-full">
          <span className="material-symbols-outlined text-[16px]">
            more_vert
          </span>
        </button>
      </div>
    </div>
  );
}

export default ChatHeader;
