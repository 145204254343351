import React, { useEffect } from "react";
import { ReactComponent as AppLogo } from "../../assets/logos/logo.svg";
import VideoPlayer from "../../components/video-player";
import Button from "../../components/button";
import AccountButton from "./account-button";

function ConnectWhatsApp() {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "971989590522603",
        cookie:   true, // enable cookies
        xfbml:    true, // parse social plugins on this page
        version:  'v18.0' //Graph API version
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(
      function (response) {
        console.log(response);
        if (response.authResponse) {
          // User is logged in, you can fetch user data here
          console.log("User is logged in:", response.authResponse);
        } else {
          // User canceled login or didn't authorize your app
          console.log("User canceled login or did not authorize the app");
        }
      },
      {
        // config_id: "1392076278392514", // right
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        scope: 'whatsapp_business_management, whatsapp_business_messaging',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {
          }
        }
      }
    );
  };

  return (
    <div className="flex h-[100vh]">
      <div className="hidden h-full md:block w-[65%]">
        <div className="flex flex-col items-center justify-center min-h-screen max-h-screen overflow-x-scroll no-scrollbar">
          <VideoPlayer />
          <h1 className="text-[34px] text-center font-bold py-[28px]">
            Build your business with Whatsapp
          </h1>
          <p className="text-center text-[14px] w-[60%]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab dolorem
            dolorum voluptate asperiores corrupti rem error suscipit sunt quos?
            Adipisci aperiam animi voluptatibus{" "}
          </p>
          <button className="flex items-center gap-2 text-app-black-color font-medium px-4 py-2 my-6 border border-gray rounded-sm hover:bg-primary-color hover:text-white shadow-lg">
            <span className="material-symbols-outlined">featured_video</span>
            Watch Demo
          </button>
        </div>
      </div>

      <div className="w-full sm:w-full md:w-[35%] md:flex md:justify-center overflow-x-scroll no-scrollbar">
        <div className="mx-[20px] my-[50px] md:w-[70%] flex flex-col">
          <div className="flex justify-between items-center">
            <AppLogo className="h-[48px] w-[124px]" />
            <AccountButton />
          </div>
          <div className="item-center mt-10  md:mt-40">
            <h3 className="font-semibold text-[22px] my-2">
              Connect a WhatsApp API Account
            </h3>
            <p className=" font-thin text-[14px] text-gray">
              Connect a new account or migrate from another API provider
            </p>
            <div className="my-10">
              <Button
                className={
                  "bg-primary-color text-white rounded-sm py-2 w-[100%]"
                }
                label="Continue"
                onClick={handleFacebookLogin}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectWhatsApp;
