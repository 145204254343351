import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-pen.svg";
import TeamOptionSelector from "../team-option-selection";
import Button from "../../components/button";
import CircleAvatar from "../circle_avatar";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CreateUpdateAgent = ({ close, data }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    role: "",
    status: null,
  });
  const [loading, setLoading] = useState(false);
  const { teams } = useSelector((state) => state.agents);
  const [selectedTeams, setSelectedTeams] = useState();

  const handleChangeInputData = (event) => {
    const currentLoginData = { ...inputData };
    currentLoginData[event.target.name] = event.target.value;
    setInputData(currentLoginData);
  };

  const handleChangeStatus = (event) => {
    const currentLoginData = { ...inputData };
    currentLoginData[event.target.name] =
      event.target.value === "true" ? true : false;
    setInputData(currentLoginData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedFile(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!inputData.name) {
      toast.error("Enter valid name");
      return;
    }
    if (!inputData.email) {
      toast.error("Enter valid email address");
      return;
    }
    if (!inputData.role) {
      toast.error("Select account role");
      return;
    }
    if (inputData.status === null) {
      toast.error("Select account status initial");
      return;
    }
    if (data) {
      updateAgent();
      return;
    }
    createAgent();
  };

  const createAgent = async () => {
    setLoading(true);
    const body = JSON.stringify({
      name: inputData.name,
      email: inputData.email,
      role: inputData.role,
      status: inputData.status,
      team: selectedTeams,
      profile_image: null,
    });
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}agent/create`,
        method: "POST",
        body: body,
      });
      if (response.status) {
        setLoading(false);
        toast.success("Created new agent");
        close();
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (err) {
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else if (err.status === 400) {
        toast.error("Validation error");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const updateAgent = async () => {
    setLoading(true);
    const body = JSON.stringify({
      id: data._id,
      name: inputData.name,
      email: inputData.email,
      role: inputData.role,
      status: inputData.status,
      team: selectedTeams,
      profile_image: null,
    });
    console.log(body);
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}agent/update`,
        method: "POST",
        body: body,
      });
      if (response.status) {
        setLoading(false);
        toast.success("Updated agent");
        close();
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (err) {
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else if (err.status === 400) {
        toast.error("Validation error");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleSelectedTeams = (options) => {
    const current = options.map((item) => item.value);
    setSelectedTeams(current);
  };

  useEffect(() => {
    if (data) {
      const teams = data?.teams.map((item) => item._id);
      setSelectedTeams(teams);

      const inputData = {
        name: data?.name,
        email: data?.email,
        role: data?.role,
        status: data?.status,
      };
      setInputData(inputData);
    }
  }, []);

  return (
    <div className="flex flex-col justify-between h-[94vh]">
      {/* header  */}
      <div>
        <div className="px-4 py-3 flex justify-between items-center">
          <h3 className="font-medium">
            {data != null ? "Update agent" : "Create new agent"}
          </h3>
          <button
            onClick={close}
            className=" bg-gray-50 rounded-full h-6 w-6 flex items-center justify-center hover:bg-primary-color shadow-md"
          >
            <CloseIcon className="w-4" />
          </button>
        </div>
        <div>
          <hr className="border-gray-100"></hr>
        </div>
      </div>
      {/* content */}
      <div className="h-[100vh] overflow-x-scroll no-scrollbar">
        <div className="my-9 flex items-center justify-center">
          <div className="relative">
            {selectedFile ? (
              <div className="bg-primary-light h-32 w-32 rounded-full shadow-md overflow-hidden">
                <img src={selectedFile} alt={selectedFile.name}></img>
              </div>
            ) : data != null ? (
              <CircleAvatar
                name={data?.name}
                profileImage={data?.profile_image}
                size={"w-[128px] h-[128px] shadow-md"}
              />
            ) : (
              <CircleAvatar
                name={inputData.name}
                profileImage={null}
                size={"w-[128px] h-[128px] shadow-md"}
              />
            )}
            <div className=" absolute right-1 bottom-1">
              <input
                id="pic-button"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
              ></input>
              <label htmlFor="pic-button">
                <EditIcon className=" h-8 w-8 bg-primary-color rounded-full p-2 shadow-md" />
              </label>
            </div>
          </div>
        </div>
        <div className="px-4">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Name
              </label>
              <input
                className=" text-sm placeholder:text-[12px] px-3 py-[6px] outline outline-[1px] rounded-md outline-gray-50 focus:outline-primary-color placeholder:font-light"
                type="name"
                name="name"
                placeholder="Enter agent name"
                required
                defaultValue={data?.name}
                onChange={handleChangeInputData}
              ></input>
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Email
              </label>
              <input
                className=" text-sm placeholder:text-[12px] px-3 py-[6px] outline outline-[1px] rounded-md outline-gray-50 focus:outline-primary-color placeholder:font-light"
                type="email"
                name="email"
                placeholder="Enter agent email"
                required
                defaultValue={data?.email}
                onChange={handleChangeInputData}
              ></input>
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Role
              </label>
              <select
                name="role"
                className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] px-2 py-1"
                required
                defaultValue={data?.rol}
                onChange={handleChangeInputData}
              >
                <option className="disabled:text-gray-50" disabled selected>Select Role</option>
                <option>Owner</option>
                <option>Admin</option>
                <option>Salesman</option>
                <option>Sales Limited</option>
              </select>
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Availability
              </label>
              <select
                name="status"
                className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] px-2 py-1"
                required
                onChange={handleChangeStatus}
                value={inputData?.status}
              >
                <option className=" disabled:text-gray-50" value="" selected disabled>Select availability</option>
                <option value="true">Available</option>
                <option value="false">Unavailable</option>
              </select>
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Teams
              </label>
              <div className="mb-[20px]">
                {teams && (
                  <TeamOptionSelector
                    values={data?.teams}
                    teams={teams}
                    onChange={handleSelectedTeams}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* footer  */}
      <div className="flex justify-between px-4 py-2 bg-slate-50">
        <div>
          <button
            onClick={close}
            className=" text-sm min-w-[120px] border px-4 py-1 rounded-md border-gray-100"
          >
            Cancel
          </button>
        </div>
        <div className=" min-w-[120px] ">
          <Button
            state={loading}
            onClick={handleSubmit}
            label={data != null ? "Update" : "Save"}
            className=" text-sm min-w-[120px] border px-4 py-1 rounded-md border-primary-color bg-primary-color text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateAgent;
