import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-pen.svg";
import TagOptionSelector from '../../components/tag-option-selector'
import Button from "../../components/button";
import CircleAvatar from "../circle_avatar";
import PhoneNumberField from "../phone-number-field";

const CreateUpdateContact = ({ close, data }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedFile(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col justify-between h-[94vh] ">
      {/* header  */}
      <div>
        <div className="px-4 py-3 flex justify-between items-center">
          <h3 className="font-medium">
            {data != null ? "Update Contact" : "Create Contact"}
          </h3>
          <button
            onClick={close}
            className=" bg-gray-50 rounded-full h-6 w-6 flex items-center justify-center hover:bg-primary-color shadow-md"
          >
            <CloseIcon className="w-4" />
          </button>
        </div>
        <div>
          <hr className="border-gray-100"></hr>
        </div>
      </div>
      {/* content */}
      <div className="h-[100vh] overflow-x-scroll no-scrollbar">
        <div className="my-9 flex items-center justify-center">
          <div className="relative">
            {selectedFile ? (
              <div className="bg-primary-light h-32 w-32 rounded-full shadow-md overflow-hidden">
                <img src={selectedFile} alt={selectedFile.name} />
              </div>
            ) : (
              <CircleAvatar
                name={data?.name}
                profileImage={data?.image}
                size={"w-[128px] h-[128px] shadow-md"}
              />
            )}
            <div className=" absolute right-1 bottom-1">
              <input
                id="pic-button"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
              ></input>
              <label htmlFor="pic-button">
                <EditIcon className=" h-8 w-8 bg-primary-color rounded-full p-2 shadow-md" />
              </label>
            </div>
          </div>
        </div>
        <div className="px-4">
          <form>
            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Customer Name
              </label>
              <input
                className=" text-sm placeholder:text-[12px] px-3 py-[8px] outline outline-[1px] rounded-md outline-gray-50 focus:outline-primary-color placeholder:font-light"
                type="name"
                placeholder="Enter customer name"
                required
                defaultValue={data?.name}
              ></input>
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Channel
              </label>
              <select
                className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] px-2 py-2"
                required
                defaultValue={data?.channel && 'WhatsApp'}
              >
                <option>WhatsApp</option>
                <option>Instagram</option>
                <option>Facebook</option>
              </select>
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Phone number
              </label>
              <PhoneNumberField />
            </div>

            <div className="flex flex-col py-1">
              <label className=" font-light text-sm text-gray-100 my-2">
                Tags
              </label>
              <div className="mb-[20px]">
                <TagOptionSelector values={data?.members} />
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* footer  */}
      <div className="flex justify-between px-4 py-2 bg-slate-50">
        <div>
          <button
            onClick={close}
            className=" text-sm min-w-[120px] border px-4 py-1 rounded-md border-gray-100"
          >
            Cancel
          </button>
        </div>
        <div className=" min-w-[120px] ">
          <Button
            onClick={() => {}}
            label={data != null ? "Update" : "Save"}
            className=" text-sm min-w-[120px] border px-4 py-1 rounded-md border-primary-color bg-primary-color text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateContact;
