import React, { useRef , useEffect } from "react";

function EmojiKeypad({element, onDissmis, height}) {
  const emjRef = useRef(null);

  useEffect(()=>{
    const handleClickOutSide = (event) => {
      if (emjRef.current && !emjRef.current.contains(event.target)) {
         onDissmis();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onDissmis]);

  return (
    <div ref={emjRef} className={`absolute bottom-[48px] right-[5%] ${height}`}>
      {element}
    </div>
  );
}

export default EmojiKeypad;
