import React, { useState, useRef, useEffect } from "react";
import ToggleSwitch from "../../toggle-switch";
import { languages } from "../../../assets/json/languages";
import Config from "../../../config/config";
import { fetcher } from "../../../hooks/fetcher/useFetch";
import { toast } from "react-toastify";
import "./style.css";

function TableRow({ item }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const getLanguage = (code) => {
    return languages?.find((language) => language.code === code);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateTemplatesStatus = async (state) => {
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}quick_replies/template/state`,
        method: "POST",
        body: JSON.stringify({show_in_chat: state, template_id: item.id}),
      });
      if (response.status) {
        console.log(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
        console.log(err);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  };


  return (
    <>
      <tr className="text-sm">
        <td className="py-1">{item?.name}</td>
        <td className="max-w-xs">
          <p className="single-line">
            {item?.components?.map((cmp) => `${cmp?.type}  ${cmp?.text}  `)}
          </p>
        </td>
        <td className="">{getLanguage(item?.language).language}</td>
        <td className="flex items-center justify-center mt-2">
          <ToggleSwitch active={item.show_in_chat} onChange={updateTemplatesStatus} />
        </td>
        <td className="text-center">
          <button onClick={toggleMenu}>
            <span className="material-symbols-outlined">more_vert</span>
          </button>
        </td>

        {isMenuOpen && (
          <div
            ref={menuRef}
            className="absolute top-10 right-12 bg-white border-gray-50 border rounded-md shadow-md min-w-[140px] z-10"
          >
            <ul className="my-2 text-sm">
              <li className="hover:bg-gray-50 px-4 py-2">Edit</li>
              <li className="hover:bg-gray-50 px-4 py-2">Delete</li>
            </ul>
          </div>
        )}
      </tr>
    </>
  );
}

export default TableRow;
