import React, { useState, useEffect, useRef } from "react";
import MediaItem from "./media-item";
import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import EmojiKeypad from "../emoji-keypad";
import {
  validImageTypes,
  validVideoTypes,
  allMediaSupport,
  validDocTypes,
  validAudioTypes,
} from "../../utils/media-support-types";
import { fileExtension } from "../../utils/formater";

function FooterMediaPreview({
  files,
  onRemove,
  onAdded,
  onCaption,
  captions,
  onClose,
  onSend,
}) {
  const [media, setMedia] = useState();
  const [index, setIndex] = useState(0);
  const [showEmoji, setShowEmoji] = useState(false);
  const ref = useRef();

  const readFile = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setMedia(reader.result);
    };
    if (files[index]) {
      reader.readAsDataURL(files[index]);
    }
  };

  useEffect(readFile, [files, index]);

  const handleInput = () => {
    let textContent = ref.current.innerText;
    textContent = textContent.replace(/<br\s*\/?>/gi, "\n");
    onCaption(index, textContent);
  };

  const handlePreviewClick = (i) => {
    setIndex(i);
    //clear input text
    ref.current.innerText = "";
    // if caption exist add caption
    const caption = captions[i];
    if (caption) ref.current.innerText = caption;
  };

  const handleRemove = (file) => {
    onRemove(captions[index], file);
  };

  const handleEmojiText = (emj) => {
    const element = ref.current;
    if (!element) return;
    // Get the current selection and range
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      if (range) {
        // Check if the selected node is within the contentEditable div
        const commonAncestor = range.commonAncestorContainer;
        if (!element.contains(commonAncestor)) return;
        // Create a new emoji span element
        const emojiSpan = document.createElement("span");
        emojiSpan.textContent = emj.native; // Emoji Unicode representation
        // Insert the emoji span at the start of the selection range
        range.insertNode(emojiSpan);
        // Move the selection to the end of the inserted emoji
        const newRange = document.createRange();
        newRange.setStartAfter(emojiSpan);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    } else {
      const emojiSpan = document.createElement("span");
      ref.current.appendChild(emojiSpan);
    }
  };

  return (
    <div className="absolute bottom-0 w-full h-full bg-white flex items-center gap-10 justify-end flex-col overflow-hidden">
      <button
        onClick={onClose}
        className="absolute right-6 top-2 bg-gray-100 bg-opacity-30 hover:bg-primary-color hover:text-white text-primary-color  rounded-full p-1 flex items-center"
      >
        <span className="material-symbols-outlined">close</span>
      </button>

      <div className="w-[40%] h-[50%] flex items-center justify-center overflow-hidden">
        {validImageTypes.includes(files[index]?.type) && (
          <img className="object-fill" src={media} alt="preview" />
        )}
        {validVideoTypes.includes(files[index]?.type) && (
          <video src={media} controls />
        )}
        {validAudioTypes.includes(files[index]?.type) && (
          <audio src={media} controls />
        )}
        {validDocTypes.includes(files[index]?.type) && (
          <div className="flex flex-col items-center">
            <span className="material-symbols-outlined text-[124px] text-gray-100">
              topic
            </span>
            <div className=" text-gray-100 text-lg">No preview available</div>
            <div className="flex gap-2 text-sm text-gray-100">
              {(files[index].size / 1024).toFixed(2)} KB -{" "}
              {fileExtension(files[index].name).toString().toUpperCase()}
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center justify-center flex-wrap gap-2 w-[86%]">
        {files.map((file, i) => (
          <MediaItem
            key={i}
            file={file}
            onSelected={() => handlePreviewClick(i)}
            isSelected={index === i}
            onRemove={handleRemove}
          />
        ))}
        <input
          id="add-more"
          className="hidden"
          type="file"
          accept={allMediaSupport}
          onChange={onAdded}
        />
        <label
          htmlFor="add-more"
          className=" border-2 border-gray-100 text-gray-100 flex items-center justify-center h-[56px] w-[54px] hover:border-primary-color hover:text-primary-color"
        >
          <span className="material-symbols-outlined">add</span>
        </label>
      </div>

      <div className="min-w-[50%] max-w-[70%] mb-10 flex items-end justify-center gap-2">
        <div className="flex-1 bg-white flex items-end gap-2 rounded-md overflow-x-hidden">
          <div className="flex-1 ml-[4px] overflow-x-hidden max-h-[130px]">
            <div
              ref={ref}
              contentEditable={true}
              title="Type your caption"
              className="text-md text-app-black-color bg-gray-50  py-[6px] px-[12px] focus:border-[1px] focus:border-none rounded-sm focus:outline-none empty:before:content-[attr(title)] empty:text-gray-100 empty:font-light"
              onInput={handleInput}
            />
          </div>
          <button
            onClick={() => setShowEmoji(!showEmoji)}
            className="flex items-center w-[32px] h-[36px] text-gray-100 rounded-full hover:text-primary-color"
          >
            <span className="material-symbols-outlined">mood</span>
          </button>
        </div>
        <button
          onClick={onSend}
          className={`flex items-center bg-primary-color text-white rounded-md p-[7px]`}
        >
          <span className="material-symbols-outlined  text-[22px]">send</span>
        </button>

        {showEmoji && (
          <EmojiKeypad
            onDissmis={() => setShowEmoji(false)}
            element={
              <EmojiPicker
                previewPosition="none"
                searchPosition="sticky"
                data={data}
                onEmojiSelect={handleEmojiText}
              />
            }
            height="bottom-[90px]"
          />
        )}
      </div>
    </div>
  );
}

export default FooterMediaPreview;
