import React, { useRef, useEffect } from "react";
import { chatTimeFormat } from "../../../utils/formater";
import "./style.css";

function TextBubble({ msg, onReplyPressed, onClickedReply, markAsSeen }) {
  const isUser = msg?.message?.to ? true : false;
  const status = msg?.status;
  const content = msg?.message?.text?.body;
  const createdAt = msg?.createdAt;
  const context = msg?.message?.context;

  let reply = null;
  if (Array.isArray(msg?.reply)) {
    reply = msg?.reply[0]?.message;
  }

  let currentStatus = "unknown";
  if (status.length !== -1) {
    const lastStatus = status[status?.length - 1];
    currentStatus = lastStatus?.status;
  }

  const ref = useRef(null);

  const renderReply = () => {
    return (
      <div className={`cursor-pointer`} onClick={() => onClickedReply(ref)}>
        <div className="bg-[#eeeeee] text-gray-100 rounded-md py-2 px-4 border-l-2 border-l-primary-color">
          {/* text  */}
          {reply?.type === "text" && (
            <p className="bg-[#eeeeee] text-gray-100 w-fit rounded-md">
              {reply?.text?.body}
            </p>
          )}
          {/* voice */}
          {reply?.type === "audio" && (
            <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">mic</span>
              <p className="">Audio message</p>
            </div>
          )}
          {/* document */}
          {reply?.type === "document" && (
            <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">
                note
              </span>
              <p className="">Document message</p>
            </div>
          )}
          {/* image */}
          {reply?.type === "image" && (
            <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">
                photo
              </span>
              <p className="">Image message</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?._id;
    if (msg?.is_read) {
      if (!msg?.is_read?.includes(userId)) {
        markAsSeen(msg?._id);
      }
    }
  }, []);

  return (
    <div
      ref={ref}
      id="root-document"
      className={`${isUser && "flex justify-end"}`}
    >
      <div id={msg?._id} className="mx-4">
        <div
          className={`text-sm relative flex flex-col ${isUser && "items-end"}`}
        >
          <div className={`flex flex-col ${isUser && "items-end"}`}>
            <div
              className={`flex flex-row items-center gap-4 ${
                isUser && "flex-row-reverse"
              }`}
            >
              <div
                className={`flex gap-1 flex-col max-w-md ${
                  isUser
                    ? " flex-row-reverse bg-primary-color text-white"
                    : "bg-gray-50"
                } w-fit rounded-md`}
              >
                {context && renderReply()}
                <p className="px-4 py-2">{content}</p>
              </div>
              {/* actions */}
              <div id="btn-container" className="hidden">
                <button
                  onClick={onReplyPressed}
                  className="hover:text-primary-color text-gray-100 text-sm h-[34px] flex items-center justify-center"
                >
                  Reply
                </button>
              </div>
            </div>
            {/* date time */}
            <div className={`flex items-center gap-1`}>
              {createdAt && (
                <p className={`text-[12px] text-gray-100`}>
                  {chatTimeFormat(createdAt)}
                </p>
              )}
              {isUser && status && (
                <div className="flex items-center">
                  {currentStatus === "error" && (
                    <p className="text-[12px] font-light text-red-600">
                      Error sending...
                    </p>
                  )}
                  {currentStatus === "delivered" && (
                    <span className="material-symbols-outlined text-[16px] text-primary-color">
                      done
                    </span>
                  )}
                  {currentStatus === "seen" && (
                    <span className="material-symbols-outlined text-[16px] text-primary-color">
                      done_all
                    </span>
                  )}
                  {currentStatus === "pending" && (
                    <span className="material-symbols-outlined text-[16px] text-primary-color">
                      hourglass_top
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextBubble;
