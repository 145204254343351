import React, { useState } from "react";

function Pagination({ onChange, items }) {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const totalPages = Math.ceil(items / perPage);

  // Function to handle page changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      onChange && onChange(page);
    }
  };

  const handlePrevChange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onChange && onChange(currentPage - 1);
    }
  };

  const handleNextChange = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onChange && onChange(currentPage + 1);
    }
  };

  // Helper function to generate the pagination buttons
  const renderPaginationButtons = () => {
    const buttons = [];

    if (totalPages === 0) {
      buttons.push(
        <button
          className={`px-3 py-1 bg-gray-50 text-gray-400 rounded-full min-w-[34px] min-h-[34px] text-sm`}
          key={1}
          onClick={() => handlePageChange(1)}
        >
          {1}
        </button>
      );
    } else if (totalPages <= 9) {
      // If there are 9 or fewer pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            className={`${
              currentPage === i
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={i}
            onClick={() => handlePageChange(i)}
            disabled={i === currentPage}
          >
            {i}
          </button>
        );
      }
    } else {
      // If there are more than 9 pages, create the "1, 2, 3, ..., 8, 9, 8" layout
      if (currentPage <= 6) {
        for (let i = 1; i <= 7; i++) {
          buttons.push(
            <button
              className={`${
                currentPage === i
                  ? "bg-primary-color text-white"
                  : "text-app-black-color"
              }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={i === currentPage}
            >
              {i}
            </button>
          );
        }
        buttons.push(<span key="dots">...</span>);
        buttons.push(
          <button
            className={`${
              currentPage === totalPages - 1
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={totalPages - 1}
            onClick={() => handlePageChange(totalPages - 1)}
          >
            {totalPages - 1}
          </button>
        );
        buttons.push(
          <button
            className={`px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
      } else if (currentPage > 6 && currentPage <= totalPages - 6) {
        buttons.push(
          <button
            className={`${
              currentPage === 1
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={1}
            onClick={() => handlePageChange(1)}
          >
            {1}
          </button>
        );
        buttons.push(
          <button
            className={`${
              currentPage === 2
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={2}
            onClick={() => handlePageChange(2)}
          >
            {2}
          </button>
        );
        buttons.push(<span key="dots">...</span>);
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          buttons.push(
            <button
              className={`${
                currentPage === i
                  ? "bg-primary-color text-white"
                  : "text-app-black-color"
              }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={i === currentPage}
            >
              {i}
            </button>
          );
        }
        buttons.push(<span key="dots-end">...</span>);
        buttons.push(
          <button
            className={`${
              currentPage === totalPages - 1
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={totalPages - 1}
            onClick={() => handlePageChange(totalPages - 1)}
            disabled={currentPage === totalPages - 1}
          >
            {totalPages - 1}
          </button>
        );
        buttons.push(
          <button
            className={`px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            {totalPages}
          </button>
        );
      } else {
        buttons.push(
          <button
            className={`${
              currentPage === 1
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={1}
            onClick={() => handlePageChange(1)}
          >
            {1}
          </button>
        );
        buttons.push(
          <button
            className={`${
              currentPage === 2
                ? "bg-primary-color text-white"
                : "text-app-black-color"
            }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
            key={2}
            onClick={() => handlePageChange(2)}
          >
            {2}
          </button>
        );
        buttons.push(<span key="dots">...</span>);
        for (let i = totalPages - 6; i <= totalPages; i++) {
          buttons.push(
            <button
              className={`${
                currentPage === i
                  ? "bg-primary-color text-white"
                  : "text-app-black-color"
              }  px-3 py-1 text-app-black-color rounded-full min-w-[34px] min-h-[34px] text-sm`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={i === currentPage}
            >
              {i}
            </button>
          );
        }
      }
    }

    return buttons;
  };

  return (
    <div className="flex items-center justify-center my-10 gap-4">
      <button
        onClick={handlePrevChange}
        className="text-sm text-primary-color font-medium disabled:text-gray-100 cursor-pointer"
        disabled={currentPage === 1}
      >
        Prev
      </button>
      {renderPaginationButtons()}
      <button
        onClick={handleNextChange}
        className="text-sm text-primary-color font-medium disabled:text-gray-100 cursor-pointer"
        disabled={currentPage === totalPages || totalPages === 0}
      >
        Next
      </button>
    </div>
  );
}

export default Pagination;
