import React, {useEffect} from "react";
import { chatTimeFormat } from "../../../utils/formater";

function ImageBubble({ msg, onReplyPressed, markAsSeen }) {
  const isUser = msg?.message?.to ? true : false;
  const link = msg?.message?.image?.link;
  const caption = msg?.message?.image?.caption;
  const status = msg?.status;
  const createdAt = msg?.createdAt;
  const context = msg?.message?.context;
  let reply = null;
  let currentStatus = "unknown";
  if (status?.length !== -1) {
    const lastStatus = status[status?.length - 1];
    currentStatus = lastStatus?.status;
  }
  if(Array.isArray(msg?.reply)){
    reply = msg?.reply[0]?.message;
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?._id;
    if (msg?.is_read) {
      if (!msg?.is_read?.includes(userId)) {
        markAsSeen(msg?._id);
      }
    }
  }, []);

  const renderReply = () => {
    return (
      <div className={`cursor-pointer`}>
        <div className="bg-[#eeeeee] text-gray-100 rounded-md py-2 px-4 border-l-2 border-l-primary-color">
          {/* text  */}
          {
            reply?.type === 'text' && 
            <p className="bg-[#eeeeee] text-gray-100 w-fit rounded-md">
            {
              reply?.text?.body
            }
          </p>
          }
          {/* voice */}
          {
            reply?.type === 'audio' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">mic</span>
              <p className="">Audio message</p>
            </div>
          }
          {/* document */}
          {
            reply?.type === 'document' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">note</span>
              <p className="">Document message</p>
            </div>
          }
          {/* image */}
          {
            reply?.type === 'image' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">photo</span>
              <p className="">Image message</p>
            </div>
          }
          {
            reply?.type
          }
        </div>
      </div>
    );
  };


  return (
    <div id="root-document" className={`flex ${isUser ? "justify-end" : ""} relative`}>
      <div className="relative max-w-md mx-4 my-4 flex flex-col ">
        <div className={`flex gap-4  items-center ${isUser && 'flex-row-reverse'}`}>
          <div className={`flex flex-col gap-1 w-fit rounded-md bg-gray-50`}>
            { context && renderReply() }
            <img
              src={link}
              alt=""
              className={`w-[360px] max-h-[260px] rounded-md object-cover shadow-sm ${
                currentStatus === "pending" ? "blur-sm" : ""
              }`}
            />
            {caption && <p className=" px-1 py-2 text-sm">{caption}</p>}
          </div>

          {/* actions */}
          <div id="btn-container" className="hidden">
              <button onClick={onReplyPressed} className="hover:text-primary-color text-gray-100 text-sm h-[34px] flex items-center justify-center">
                Reply
              </button>
          </div>
        </div>
        
        {currentStatus === "pending" && (
          <div className=" absolute top-3 left-3 flex items-center gap-3">
            <div className="loading-circle" />
            <p className=" text-app-black-color text-[12px]">Uploading...</p>
          </div>
        )}

        <div className={`flex gap-2 ${isUser ? "flex-row-reverse " : ""} my-1`}>
          {currentStatus === "error" && (
            <p className="text-[12px] font-light text-red-600">
              Error sending...
            </p>
          )}
         
          {isUser && status && (
            <div className="flex items-center">
              {currentStatus === "error" && (
                <p className="text-[12px] font-light text-red-600">
                  Error sending...
                </p>
              )}
              {currentStatus === "delivered" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  done
                </span>
              )}
              {currentStatus === "seen" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  done_all
                </span>
              )}
              {currentStatus === "pending" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  hourglass_top
                </span>
              )}
            </div>
          )}
           {createdAt && (
            <p className={`text-[12px] text-gray-100`}>
              {chatTimeFormat(createdAt)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageBubble;
