import React, { useState, useEffect, useRef } from "react";
import CircleAvatar from "../circle_avatar";
import { toast } from "react-toastify";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { ShimmerCircularImage, ShimmerBadge } from "react-shimmer-effects";

function ChatViewAsPopup({ onDissmis, onSelected }) {
  const popRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({ search: "" });
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchAgents();
    const handleClickOutSide = (event) => {
      if (popRef.current && !popRef.current.contains(event.target)) {
        onDissmis && onDissmis();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onDissmis, query]);

  const handleSearchInput = (event) => {
    const currentLoginData = { ...query };
    currentLoginData[event.target.name] = event.target.value;
    setQuery(currentLoginData);
  };

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}/agent?limit=10&offset=0&query=${query.search}&status=&team=`,
        method: "GET",
      });
      if (response.status) {
        if (response.data) {
          setData(response.data[0].data);
        } else {
          setData(null);
        }
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured while fetch");
      }
    }
  };

  return (
    <div
      ref={popRef}
      className="absolute z-20 left-2 w-full bg-gray-50 shadow-md rounded-md mx-2 min-h-[320px]"
    >
      <div className=" relative px-3 pt-4">
        <input
          type="text"
          className="py-1 w-full pl-8 rounded-sm placeholder:text-sm outline-none bg-gray-50 focus:border-primary-color border-[1px]"
          placeholder="Search"
          name="search"
          onChange={handleSearchInput}
        />
        <span className="absolute z-20 top-[22px] left-[16px] text-gray-300 text-[22px] material-symbols-outlined">
          search
        </span>
      </div>
      {!loading &&
        (data ? (
          <div className="mt-2 cursor-pointer overflow-auto max-h-[280px]">
            <ul className="">
              {data.map((item, index) => {
                return (
                  <li onClick={()=> onSelected(item)} key={index} className=" hover:bg-white">
                    <div className="flex items-center gap-2 px-3 py-1">
                      <CircleAvatar
                        name={item?.name}
                        profileImage={item?.profileImage}
                        size="w-[32px] h-[32px]"
                      />
                      <p className="text-sm">{item?.name}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <p className="py-4 text-sm">No data found !</p>
          </div>
        ))}
      {loading && (
        <div className="">
          <div className="flex gap-3 items-center px-3 py-1">
            <ShimmerCircularImage size={40} />
            <ShimmerBadge width={260} />
          </div>
          <div className="flex gap-3 items-center px-3 py-1">
            <ShimmerCircularImage size={40} />
            <ShimmerBadge width={260} />
          </div>
          <div className="flex gap-3 items-center px-3 py-1">
            <ShimmerCircularImage size={40} />
            <ShimmerBadge width={260} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatViewAsPopup;
