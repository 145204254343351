import React from 'react'
import CircleAvatar from "../../../components/circle_avatar";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-pen.svg";
import { DateFormat } from '../../../utils/formater';
import Tag from '../../../components/tag';
import './style.css'


function ContactTableRow({data, onEditClick}) {
  return (
    <>
      <tr className="row text-app-black-color text-sm hover:bg-gray-50">
        <td>
          <div className="flex items-center gap-2">
            <CircleAvatar
              name={data?.name}
              profileImage={data.image}
              size="w-[48px] h-[48px]"
            />
            <div>{data.name}</div>
          </div>
        </td>
        <td>
          <div className='flex items-center ml-[16px]'>
            {
              data?.tags.map((tag) => <Tag content={tag} props={'bg-blue-100 text-blue-500'} />)
            }
          </div>
        </td>
        <td>
          <div className="container mx-auto p-4">
            {DateFormat(data.createdAt)}
          </div>
        </td>
        <td>
          <div className="flex items-center justify-between">
              <button
                onClick={() => onEditClick(data)}
                className="col-action hover:bg-primary-light rounded-full p-2 flex"
              >
                <span class="material-symbols-outlined">chat_paste_go</span>
              </button>
          
              <button
                onClick={() => onEditClick(data)}
                className=" hover:bg-primary-light rounded-full p-2"
              >
                <EditIcon className="w-[18px] h-[18px]" />
              </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default ContactTableRow
