import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

const MessageProvider = ({ children }) => {
    const [messageQueue, setMessageQueue] = useState([]);
  
    const addMessageToQueue = (message) => {
      setMessageQueue((prevQueue) => [...prevQueue, message]);
    };
  
    const removeMessageFromQueue = () => {
      setMessageQueue((prevQueue) => prevQueue.slice(1));
    };
  
    const getMessageFromQueue = () => messageQueue[0];
  
    return (
      <MessageContext.Provider
        value={{
          addMessageToQueue,
          removeMessageFromQueue,
          getMessageFromQueue,
        }}
      >
        {children}
      </MessageContext.Provider>
    );
  };
  
  const useMessageContext = () => useContext(MessageContext);
  
  export { MessageProvider, useMessageContext };