import React, { useEffect, useState } from "react";
import Select from "react-select";


function TagOptionSelector({values}) {
  const [selected, setSelected] = useState([]);
  const options = [
    { value: 1, label: "Return" },
    { value: 2, label: "Premium" },
    { value: 3, label: "Close" },
  ];


  const handleChange = (option) => {
    setSelected(option);
  };

  useEffect(() => {
    if (values != null) {
      values.forEach((e) => {
        const opt = options.find((option) => option.value === e.id);
        setSelected((old) => [...old, opt]);
      });
    }
  }, []);

  const Option = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <article
        ref={innerRef}
        {...innerProps}
        className="px-4 py-2 flex items-center gap-2 hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex items-center justify-center bg-gray-50 rounded-full w-[32px] h-[32px]">
            <span class="material-symbols-outlined text-[22px]">label</span>
        </div>
        <h4 className=" text-sm">{props.data.label}</h4>
      </article>
    );
  };

  return (
            <Select
            defaultValue=""
            options={options}
            isMulti
            isClearable={false}
            value={selected}
            placeholder="Select Tag"
            onChange={handleChange}
            components={{ Option, IndicatorSeparator: () => null}}
            styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#f5f5f5",
                borderRadius: "6px",
                paddingLeft: "2px",
                ":hover": { borderColor: "#f5f5f5", outline: "none" },
            }),
            placeholder: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "12px",
                fontWeight: "lighter",
                color: "#c2c2c2",
            }),
            multiValue: (styles, { data }) => ({
                display: "flex",
                fontFamily: "Poppins",
                color: "#398f50",
                backgroundColor: "#baffcc",
                borderRadius: "2px",
                paddingLeft: "10px",
                paddingRight: "6px",
                paddingTop: "3px",
                paddingBottom: "3px",
                alignItems: "center",
                justifyContent: "center",
                margin: "3px",
            }),
            multiValueLabel: (styles, { data }) => ({
                fontSize: "14px",
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: data.color,
                borderRadius: "40px",
                marginLeft: "8px",
                height: "20px",
                ":hover": {
                backgroundColor: "#5C42F9",
                color: "white",
                },
            }),
            }}
        />
  )
}

export default TagOptionSelector
