import React from "react";

export default function ChatHeaderTag({tag}) {
  const {color, tag: tagName } = tag;

  const tagStyle = {
    backgroundColor: `${color}30`, // Adding 30% opacity to the background color
    color: color, // Using the specified text color
    padding: "0px 6px",
    borderRadius: "2px",
    fontSize: "12px"
  };
  return <div style={tagStyle}>{tagName}</div>;
}
