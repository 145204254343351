import React from 'react'
import ConversationChart from './chart-view'

function Home() {
  return (
    <section className='cursor-pointer'>
      <div className='mt-[14px]'>
        <div>
          <h3 className=' font-semibold text-[20px]'>Overview</h3>
          <div className=' text-gray-100 font-light text-[14px]'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eaque, magnam totam sequi dolor quisquam doloremque? Magnam repudiandae molestiae suscipit sit, dignissimos, cumque nemo nostrum rem ipsam nihil cum tempore.
          </div>
        </div>

        <div className='card container shadow-md rounded-md shadow-gray-100 my-[20px] p-4'>
          <div className='text-[20px] mb-5'>Conversations</div>
          <div className='flex md:flex-row flex-col'>
            <div className='left-section'>
              <ConversationChart/>
            </div>
            <div className='right-section hidden md:flex md:flex-col flex-row md:pl-10 items-start gap-6 mt-6'>
                  <div className=''>
                    <div className='bg-gray-50 rounded-full px-2 py-1 w-fit h-fit mb-4'>
                      <select className='bg-gray-50 outline-none' name="Year" id="chart-options">
                        <option>This year</option>
                        <option>Month</option>
                        <option>Week</option>
                        <option>Today</option>
                      </select>
                    </div>
                  </div>
                  <div className=''>
                    <label className='text-gray-100 font-light text-[14px]'>COUNT</label>
                    <div className='text-primary-color font-semibold text-[24px] my-1'>18,3932</div>
                  </div>
                  <div className=''>
                    <label className='text-gray-100 font-light text-[14px]'>CONVERSATION CHARGE</label>
                    <div className='font-semibold text-[24px] my-1'><snap>$</snap> 122.00</div>
                  </div>
                  <div className=''>
                    <label className='text-gray-100 font-light text-[14px]'>CURRENT PLAN</label>
                    <div className='my-1 flex gap-6'>
                      <label className=' bg-primary-light text-primary-color px-2 py-1 rounded-md shadow-md'>BASE PLAN</label>
                      <button className='text-primary-color underline px-2 hover:bg-primary-color hover:text-white'>Upgrade Now</button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home
