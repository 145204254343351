import React from 'react'

function StackCounter({count}) {
  return (
    <div className=' relative flex items-center'>
      <div className=' absolute bg-primary-light w-6 h-6 rounded-full'></div>
      <div className=' absolute left-2 bg-primary-color w-6 h-6 rounded-full'></div>
      <div className=' absolute left-4 bg-app-black-color text-white w-6 h-6 rounded-full flex items-center justify-center text-[11px]'>
        <div>{count}</div>
      </div>
    </div>
  )
}

export default StackCounter