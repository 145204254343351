import React from 'react'
import AppTile from '../../components/app-tile'

function Apps() {
  return (
    <section>
        <div className="py-4 flex flex-col">
            <h2 className='font-semibold text-[22px] text-app-black-color'>Apps</h2>
            <p className="text-[14px] text-gray-100 mt-[2px]">Install more apps from qued app store</p>
        </div>
        <hr className='border-gray-50'/>
        <div className="relative flex items-center my-6">
            <span className="material-symbols-outlined text-gray-200 absolute left-1 text-[22px]">search</span>
            <input type="text" className="bg-gray-50 placeholder:text-sm font-light px-7 py-1 outline-primary-color outline-px rounded-sm" placeholder='Search' />
        </div>
        <div className="my-2">
            <h3 className="font-semibold text-[18px] text-app-black-color">By qued</h3>
            <div className="grid grid-cols-4 grid-flow-row-dense gap-2 mt-4">
                <AppTile title='Broadcast' label='MARKETING' price='FREE' desc='Send a Whatsapp template to all or part of your contacts list at once. Broadcasts can be transactional or marketing messages' isActive={true} path={'/broadcast'}/>         
                <AppTile title='Quick Replies' label='SUPPORT' price='FREE' desc='Send a Whatsapp template to all or part of your contacts list at once. Broadcasts can be transactional or marketing messages' isActive={true} path={'/quick_replies'}/>         
                <AppTile title='Templates' label='UTILITIES' price='FREE' desc='Send a Whatsapp template to all or part of your contacts list at once. Broadcasts can be transactional or marketing messages' isActive={true} path={'/templates'}/>         
            </div>
        </div>
        {/* <div className="mt-10">
            <h3 className="font-semibold text-[18px] text-app-black-color">Installed Apps</h3>
            <div className="grid grid-cols-4 grid-flow-row-dense gap-2 mt-4">
                <AppTile/>
                <AppTile/>
                <AppTile/>
            </div>
        </div> */}
    </section>
  )
}

export default Apps
