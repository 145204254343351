import React from "react";
import CircleAvatar from "../../../components/circle_avatar";

function AgentRow({ data , onClicked }) {
  const handleOnClick = () => {
    onClicked && onClicked(data);
  }
  return (
    <li onClick={handleOnClick}>
      <div className="my-1 px-4 flex items-center gap-2 text-sm hover:bg-gray-50 hover:text-primary-color">
        <CircleAvatar
          profileImage={data.profile_image}
          name={data.name}
          size="w-[34px] h-[34px]"
        />
        <div>{data.name}</div>
      </div>
    </li>
  );
}

export default AgentRow;
