import React, { useRef, useEffect, useState } from "react";
import ToggleSwitch from "../../components/toggle-switch";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

function Automation() {
  const emojiPicker = useRef(null);
  const [showEmoji, setShowEmoji] = useState(false);

  const greetingsRef = useRef(null);
  const [greetingsCount, setGreetingCount] = useState(0);
  const awayMessageRef = useRef(null);
  const [awayMessageCount, setAwayMessageCount] = useState(0);
  const [selectedRef, setSelectedRef] = useState(null);

  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (emojiPicker.current && !emojiPicker.current.contains(event.target)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [setShowEmoji]);


  const handleBoldText = (ref) => {
    const start = ref.current.selectionStart;
    const end =  ref.current.selectionEnd;

    if(ref.current.value.length > 0){
        const newValue = ref.current.value.substring(0, start) + '*' + ref.current.value.substring(start, end) + '*' + ref.current.value.substring(end, ref.current.value.length);
        ref.current.value = newValue;
        ref.current.focus();
    }else{
        ref.current.value = ref.current.value + '**';
        ref.current.focus();
        ref.current.setSelectionRange(ref.current.value.length - 1, ref.current.value.length - 1);
    }
  }

  const handleItalicText = (ref) => {
    const start = ref.current.selectionStart;
    const end =  ref.current.selectionEnd;

    if(ref.current.value.length > 0){
        const newValue = ref.current.value.substring(0, start) + '_' + ref.current.value.substring(start,end) + '_' + ref.current.value.substring(end, ref.current.value.length);
        ref.current.value = newValue;
        ref.current.focus();
    }else{
        ref.current.value = ref.current.value + '__';
        ref.current.focus();
        ref.current.setSelectionRange(ref.current.value.length - 1, ref.current.value.length - 1);
    }
  }

  const handleThroughLineText = (ref) => {
    const start = ref.current.selectionStart;
    const end =  ref.current.selectionEnd;

    if(ref.current.value.length > 0){
        const newValue = ref.current.value.substring(0, start) + '~' + ref.current.value.substring(start,end) + '~' + ref.current.value.substring(end, ref.current.value.length);
        ref.current.value = newValue;
        ref.current.focus();
    }else{
        ref.current.value = ref.current.value + '~~';
        ref.current.focus();
        ref.current.setSelectionRange(ref.current.value.length - 1, ref.current.value.length - 1);

    }
  }

  const handleMonoText = (ref) => {
    const start = ref.current.selectionStart;
    const end =  ref.current.selectionEnd;

    if(ref.current.value.length > 0){
        const newValue = ref.current.value.substring(0, start) + '```' + ref.current.value.substring(start,end) + '```' + ref.current.value.substring(end, ref.current.value.length);
        ref.current.value = newValue;
        ref.current.focus();
    }else{
        ref.current.value = ref.current.value + '``````';
        ref.current.focus();
        ref.current.setSelectionRange(ref.current.value.length - 3, ref.current.value.length - 3);
    }
  }

  const handleEmojiText = (emj) =>{
        const currentValue = selectedRef.current.value;
        // Get the cursor position
        const cursorPosition = selectedRef.current.selectionStart;
        // Insert the emoji at the cursor position
        const newValue =
        currentValue.substring(0, cursorPosition) + emj + currentValue.substring(cursorPosition);
        selectedRef.current.value = newValue;
        selectedRef.current.focus();
        selectedRef.current.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
        setGreetingCount(selectedRef.current.value.length); 
  }

 
  return (
    <section>
      <div className="cursor-pointer max-h-[90vh] h-fit overflow-x-scroll no-scrollbar">
        <div className="mt-[14px]">
          <h2 className="font-semibold text-[20px]">
            Greetings and Away messages
          </h2>
          <div className=" text-[14px] text-gray-100">
            Manage your greetings and away messages
          </div>
        </div>
        <hr className="border-gray-50 my-2" />

        <div className=" bg-gray-50 rounded-md p-4 my-8 md:w-[60vw]">
          <div className="flex justify-between mb-4">
            <h3 className=" font-semibold">Greetings messages</h3>
            <ToggleSwitch />
          </div>
          <p className=" text-sm text-gray-100">
            Welcome new customers automatically when they message you during
            business hours this message is triggered when you receive from a
            number for the first time or after 14 days of no activity
          </p>
          <div className="mt-2">
            <textarea
              ref={greetingsRef}
              className="w-full text-sm bg-gray-50 outline outline-gray-100 rounded-md p-2 focus:outline-primary-color"
              rows="7"
              maxlength='1024'
              placeholder="Welcome to qued.io"
              onChange={(event) => {setGreetingCount(greetingsRef.current.value.length);}}
              onClick={()=>setSelectedRef(greetingsRef)}
            ></textarea>
          </div>
          <div className="flex gap-4 items-center justify-end mt-2">
            <div>
              <p className=" text-sm text-gray-100">{greetingsCount}/1024</p>
            </div>
            <div className="flex gap-2">
              <button onClick={() => handleBoldText(greetingsRef)} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">
                  format_bold
                </span>
              </button>
              <button onClick={() => handleItalicText(greetingsRef)} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">
                  format_italic
                </span>
              </button>
              <button onClick={() => handleMonoText(greetingsRef)} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined">
                custom_typography
                </span>
              </button>
              <button onClick={() => handleThroughLineText(greetingsRef)} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">
                  format_strikethrough
                </span>
              </button>
              <button onClick={() => {setShowEmoji(true); greetingsRef.current.focus();}} className="bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">mood</span>
              </button>
            </div>
          </div>
          {showEmoji && (
            <div ref={emojiPicker} className=" absolute bottom-4 right-10 z-10">
              <Picker
                previewPosition="none"
                searchPosition="sticky"
                data={data}
                onEmojiSelect={(e) => {handleEmojiText(e.native)}}
              />
            </div>
          )}
        </div>

        <div className=" bg-gray-50 rounded-md p-4 my-8 md:w-[60vw]">
          <div className="flex justify-between mb-4">
            <h3 className=" font-semibold">Away messages</h3>
            <ToggleSwitch active={true} />
          </div>
          <p className=" text-sm text-gray-100">
            Let customers know that they have reached you outside business
            hours. This message is triggered when your received messages from a
            number outside the business hours you have set above. This message
            will be triggered only once in 4 hours
          </p>
          <div className="mt-2">
            <textarea ref={awayMessageRef}
              className="w-full text-sm bg-gray-50 outline outline-gray-100 rounded-md p-2 focus:outline-primary-color"
              rows="7"
              placeholder="Qued sales away. We will touch back"
              maxLength='1024'
              onChange={(event) => {setAwayMessageCount(awayMessageRef.current.value.length);}}
              onClick={()=> setSelectedRef(awayMessageRef)}
            ></textarea>
          </div>
          <div className="flex gap-4 items-center justify-end mt-2">
            <div>
              <p className=" text-sm text-gray-100">{awayMessageCount}/1024</p>
            </div>
            <div className="flex gap-2">
            <button onClick={handleBoldText} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">
                  format_bold
                </span>
              </button>
              <button onClick={handleItalicText} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">
                  format_italic
                </span>
              </button>
              <button onClick={handleMonoText} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined">
                custom_typography
                </span>
              </button>
              <button onClick={handleThroughLineText} className=" bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">
                  format_strikethrough
                </span>
              </button>
              <button onClick={() => {setShowEmoji(true); awayMessageRef.current.focus();}} className="bg-gray-100 bg-opacity-40 min-w-[28px] min-h-[28px] flex items-center justify-center rounded-md hover:bg-primary-color hover:text-white">
                <span class="material-symbols-outlined text-[18px]">mood</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Automation;
