import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AppLogo } from "../../assets/logos/logo.svg";
import GoogleLogo from "../../assets/icons/ic_google.png";
import VideoPlayer from "../../components/video-player";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/auth/authSlice";
import Button from "../../components/button";
import { toast } from 'react-toastify';

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const { loading, isAuthenticated, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
      toast.success("success login");
    }
    if (error) {
      console.log(error);
      if (error?.status === 401) {
        toast.error(error?.info?.message);
      } else {
        toast.error("Some error occured while loggin in!");
      }
    }
  }, [isAuthenticated, error]);

  const handleChangeLoginData = (event) => {
    const currentLoginData = { ...loginData };
    currentLoginData[event.target.name] = event.target.value;
    setLoginData(currentLoginData);
  };

  const handleLoginSubmit = event => {
    event.preventDefault();
    if (!loginData.email) {
      toast.error('Please enter email ID!');
      return;
    }
    if (!loginData.password) {
      toast.error('Please enter password!');
      return;
    }

    dispatch(login(loginData));
  }

  return (
    <div className="flex h-[100vh]">
      <div className="container content-center  hidden sm:hidden md:block  w-[65%]">
        <div className="flex flex-col items-center justify-center min-h-screen">
          <VideoPlayer/>
          <h1 className="text-[34px] text-center font-bold py-[28px]">
            Build your business with Whatsapp
          </h1>
          <p className="text-center text-[14px] w-[60%]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab dolorem
            dolorum voluptate asperiores corrupti rem error suscipit sunt quos?
            Adipisci aperiam animi voluptatibus{" "}
          </p>
          <button className="flex items-center gap-2 text-app-black-color font-medium px-4 py-2 my-6 border border-gray rounded-sm hover:bg-primary-color hover:text-white shadow-lg">
            <span className="material-symbols-outlined">featured_video</span>
            Watch Demo
          </button>
        </div>
      </div>

      <div className="w-full sm:w-full md:w-[35%] bg-white md:flex md:justify-center">
        <div className="mx-[20px] my-[50px] md:w-[70%]">
          <div className="my-[24px]">
            <AppLogo className="h-[48px] w-[164px]" />
          </div>
          <div className="mt-24">
            <div className="text-[22px] my-[26px]">Welcome to back</div>
            <form onSubmit={handleLoginSubmit}>
              <div>
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-primary-color"
                  type="email"
                  placeholder="Email Address"
                  required
                  name="email"
                  autoComplete="on"
                  onChange={handleChangeLoginData}
                />
              </div>
              <div className=" text-right my-4 text-gray font-light text-sm">
                <button className="hover:text-primary-color hover:font-normal">
                  Forgot Password ?
                </button>
              </div>
              <div>
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-primary-color"
                  type="password"
                  placeholder="Password"
                  required
                  name="password"
                  onChange={handleChangeLoginData}
                />
              </div>
              <div className="my-8">
                <Button state={loading} onClick={handleLoginSubmit} className={"bg-primary-color text-white rounded-sm py-2 w-[100%]"} label='Sign In' />
              </div>
            </form>
          </div>
          <div className="text-center">
            <p className="text-[14px] text-app-black-color">
              Don't have an account?{" "}
              <span
                onClick={() => navigate("/signup")}
                className="text-primary-color hover:font-medium cursor-pointer"
              >
                Create new account
              </span>
            </p>
          </div>
          <div className="relative flex py-8 items-center">
            <div className="flex-grow border-t border-gray-light"></div>
            <span className="flex-shrink mx-4 font-light text-gray">OR</span>
            <div className="flex-grow border-t border-gray-light"></div>
          </div>
          <div>
            <button className="border flex rounded-sm w-full py-2 border-gray justify-center gap-4">
              <img
                className="w-[24px] h-[24px]"
                src={GoogleLogo}
                alt="google-icon"
              />
              Sign In with google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
