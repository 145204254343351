import React, { useState } from "react";
import { ReactComponent as AppLogo } from "../../assets/logos/logo.svg";
import { NavLink } from "react-router-dom";
import AccountProfilePopup from "../account-profile-popup";
import CircleAvatar from "../circle_avatar";

function Header() {
  const [showProfile, setShowProfile] = useState(false);

  const handleProfilePopup = () => {
    setShowProfile(true);
    console.log(">> show");
  };

  return (
    <header>
      <div className="fixed top-0 z-30 bg-white w-full flex items-center justify-between border-b border-gray-50 py-2 px-4">
        <div className="flex gap-4 cursor-pointer">
          <button className="hidden items-center">
            <span className="material-symbols-outlined">menu</span>
          </button>
          <NavLink to="/">
            <AppLogo className="h-[32px] w-[124px]" />
          </NavLink>
        </div>
        <div className="hidden md:block flex-flex justify-center cursor-pointer">
          <ul className="flex gap-8 items-center">
            <li className="flex gap-2">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "flex gap-2 rounded-md px-2 py-2 hover:text-primary-color text-primary-color bg-gray-50 hover:rounded-md"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/queue"
              >
                <span className="material-symbols-outlined">menu_open</span>
                Queue
              </NavLink>
            </li>
            <li className="flex gap-2">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "flex gap-2 rounded-md px-2 py-2 hover:text-primary-color text-primary-color bg-gray-50 hover:rounded-md"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/chat"
              >
                <span className="material-symbols-outlined">forum</span>
                Chat
              </NavLink>
            </li>
            <li className="flex gap-2">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "flex gap-2 rounded-md px-2 py-2 hover:text-primary-color text-primary-color bg-gray-50 hover:rounded-md"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/settings"
              >
                <span className="material-symbols-outlined">settings</span>
                Settings
              </NavLink>
            </li>
            <li className="flex gap-2">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "flex gap-2 rounded-md px-2 py-2 hover:text-primary-color text-primary-color bg-gray-50 hover:rounded-md"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/apps"
              >
                <span className="material-symbols-outlined">apps</span>
                Apps
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          onMouseEnter={handleProfilePopup}
          onMouseDown={handleProfilePopup}
          className="cursor-pointer"
        >
          <CircleAvatar
            name="Ijas Aslam"
            profileImage="https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cq_auto:eco%2Cw_1200/MTk4MDQzMTI5NzY3NTM1ODA2/short-captions-for-profile-pictures.png"
            size="rounded-full w-11 h-11 shadow-md border"
          />
        </div>
        {showProfile && (
          <AccountProfilePopup onDissmis={() => setShowProfile(false)} />
        )}
      </div>
    </header>
  );
}

export default Header;
