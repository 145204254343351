import React, { useState } from "react";
import AgentRow from "../agent-row";
import { toast } from "react-toastify";
import Config from "../../../config/config";
import { fetcher } from "../../../hooks/fetcher/useFetch";

function AgentsTabs({ data , selectedRows, onAssigned}) {
  const [active, setIndex] = useState(0);

  const handleActionAssign = (data) => {
    if (active > 1) {
      const rooms = selectedRows.map((item) => (item._id));
      assignChat("teams", data._id, rooms);
    }else{
      const rooms = selectedRows.map((item) => (item._id));
      assignChat("user", data.id, rooms);
    }
  };

  const assignChat = async (type, userId, room) => {
    try {
      const body = JSON.stringify({room: room, id: userId, type: type});
      console.log(body);
      const response = await fetcher({
        url: `${Config.baseUrl}message/assign-chat`,
        method: "POST",
        body: body,
      });
      if (response.status) {
        toast.success(response.message);
        onAssigned && onAssigned();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else if (err.status === 404) {
        toast.error(err.info.message);
      } else {
        toast.error("Some error occured");
      }
    }
  };

  return (
    <div className="h-full overflow-hidden">
      <div>
        <div className="flex gap-2 text-sm text-app-black-color overflow-y-scroll no-scrollbar">
          <button
            onClick={() => setIndex(0)}
            className={`flex items-center gap-1 min-w-[64px] hover:bg-gray-50 hover:text-primary-color px-4 py-1 ${
              active === 0 ? "border-b-[2px]" : ""
            } border-primary-color`}
          >
            All
            <span className=" flex items-center justify-center bg-primary-color rounded-full h-5 w-5 text-white text-center text-[12px]">
              {data?.all.length.toString()}
            </span>
          </button>
          <button
            onClick={() => setIndex(1)}
            className={`flex items-center gap-1 min-w-[64px] hover:bg-gray-50 hover:text-primary-color px-4 py-1 ${
              active === 1 ? "border-b-[2px]" : ""
            } border-primary-color`}
          >
            Agents
            <span className=" flex items-center justify-center bg-primary-color rounded-full h-5 w-5 text-white text-center text-[12px]">
              {data?.agents.length.toString()}
            </span>
          </button>
          <button
            onClick={() => setIndex(2)}
            className={`flex items-center gap-1 min-w-[64px] hover:bg-gray-50 hover:text-primary-color px-4 py-1 ${
              active === 2 ? "border-b-[2px]" : ""
            } border-primary-color`}
          >
            Teams
            <span className=" flex items-center justify-center bg-primary-color rounded-full h-5 w-5 text-white text-center text-[12px]">
              {data?.teams.length.toString()}
            </span>
          </button>
        </div>
        <hr className="text-gray-50"></hr>
      </div>
      <div className="overflow-y-auto h-full pb-[164px]">
        <ul>
          {(active === 0 &&
            data?.all.map((agent, index) => (
              <AgentRow
                key={index}
                data={agent}
                onClicked={handleActionAssign}
              />
            ))) ||
            (active === 1 &&
              data?.agents.map((agent, index) => (
                <AgentRow
                  key={index}
                  data={agent}
                  onClicked={handleActionAssign}
                />
              ))) ||
            (active === 2 &&
              data?.teams.map((team, index) => (
                <AgentRow
                  key={index}
                  data={team}
                  onClicked={handleActionAssign}
                />
              )))}
        </ul>
      </div>
    </div>
  );
}

export default AgentsTabs;
