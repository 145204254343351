import React, { useRef , useEffect, useState } from "react";
import CircleAvatar from "../circle_avatar";
import CheckBox from "../check-box";
import { contacts } from "../../mocks/contacts";

function SendContantModel({onSend, onDissmis}) {
  const [seleted, setSelected] = useState([]);
  const ref = useRef(null);


  useEffect(()=>{
    const handleClickOutSide = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
         onDissmis();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onDissmis]);


  const handleSelection = (contact, state) => {
    if(state.target.checked){
        setSelected([...seleted, contact]);
    }else{
       const filter = seleted.filter((ct) => ct !== contact);
       setSelected(filter);
    }
  };
  return (
    <div ref={ref} className="absolute left-[30%] top-[20%] min-w-[420px] bg-white shadow-lg rounded-md overflow-hidden">
      <div className=" flex items-center px-4 py-3 bg-primary-color text-white">
        <div className="flex-1">Contacts</div>
        <button onClick={onDissmis} className="flex items-center">
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>
      <div className="">
        <div className="px-2 py-2 flex rounded-md">
          <input
            className="relative w-full px-8 text-app-black-color py-1 placeholder:text-sm placeholder:font-light outline-2 outline-gray-50 focus:outline focus:outline-1 focus:outline-primary-color"
            placeholder="Search"
          />
          <span className=" absolute top-[60px] left-3 material-symbols-outlined">
            search
          </span>
        </div>

        <div className="my-2 max-h-[240px] overflow-y-scroll">
          <ul>
            {contacts.map((contact, index) => (
              <li key={index}>
                <div className="flex items-center gap-1 text-app-black-color hover:bg-gray-50">
                  <CheckBox onChange={(state) =>  handleSelection(contact, state)} />
                  <CircleAvatar
                    profileImage={null}
                    name={contact.name}
                    size="w-[44px] h-[44px]"
                  />
                  <div className="ml-4">{contact.name}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <hr className=" border-gray-50"/>
      <div className="px-3 py-2 text-sm text-app-black-color flex items-center bg-white">
        <div className="flex-1 flex text-gray-100">
             
             {seleted.length > 0 && <p>{seleted[0].name}</p>}
             {seleted.length > 1 && <p>, {seleted[1].name}</p>}
             {seleted.length > 2 && <p>, and +{seleted.length - 2} </p>}
          
        </div>
        <button onClick={onSend} className=" bg-primary-color rounded-full w-[44px] h-[44px] flex items-center justify-center shadow-md">
          <span className="material-symbols-outlined text-white">send</span>
        </button>
      </div>
    </div>
  );
}

export default SendContantModel;
