import React, { useState, useRef, useEffect } from "react";
import ProfileDetails from "./profile-details";
import ChatHeader from "../../components/chat-header";
import ChatUsers from "../../components/chat-users";
import ChatFooter from "../../components/chat-footer";
import TextBubble from "../../components/chat-bubbles/text-bubble";
import ImageBubble from "../../components/chat-bubbles/image-bubble";
import VoiceBubble from "../../components/chat-bubbles/voice-bubble";
import DocumentBubble from "../../components/chat-bubbles/document-bubble";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { toast } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChats,
  concatMessages,
  replaceMessage,
  clearMessages,
  pushMessage,
  updateStatus,
} from "../../redux/chat/chatSlice";
import MessageSenderComponent from "../../components/message-sender/message-sender-component";
import PrivateMessageBubble from "../../components/chat-bubbles/private-bubble";

function Chat() {
  const dispatch = useDispatch();
  const { pinned, unpinned, messages } = useSelector((state) => state.chats);
  const conversationRef = useRef(null);
  const [toolIndex, setToolIndex] = useState(null);
  const [selectedChatUser, setChatUser] = useState();
  const toolBarRef = useRef(null);
  const pageCount = useRef(1);
  const [reply, setReplyMessage] = useState(null);
  const chatRef = useRef(null);

  const handleToolClick = (index) => {
    if (index === null) {
      toolBarRef.current.style.width = "64px";
      setToolIndex(index);
    } else if (toolIndex === index) {
      toolBarRef.current.style.width = "64px";
      setToolIndex(null);
    } else {
      toolBarRef.current.style.width = "920px";
      setToolIndex(index);
    }
  };

  //connect websocket
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = user?.access_token;
  const WS_URL = `${Config.clientSocketUrl}${accessToken}`;
  const { readyState } = useWebSocket(WS_URL, {
    onMessage: (msg) => {
      const obj = JSON.parse(msg.data);
      if (obj.type === "message") {
        handleIncomingMessage(obj);
      } else if (obj.type === "status") {
        handleStutusChange(obj);
      }
    },
    onError: (event) => {
      console.error('WebSocket error:', event);
      // Additional error handling logic can go here
    },
    shouldReconnect: (closeEvent) => true,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const scrollToBottom = () => {
    if (conversationRef.current) {
      try {
        setTimeout(() => {
          conversationRef.current.scrollTop =
            conversationRef.current.scrollHeight;
        }, 100);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const scrollToPosition = (page) => {
    const container = conversationRef.current;
    try {
      setTimeout(() => {
        container.scrollTop = (940 / 10) * page;
      }, 60);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleChatSelected = (data) => {
    pageCount.current = 1;
    dispatch(clearMessages());
    setChatUser(data);
    fetchMessage(data._id);
    scrollToBottom();
  };

  const handlePagignationScroll = () => {
    const container = conversationRef.current;
    if (container.scrollTop < 1) {
      pageCount.current = pageCount.current += 1;
      fetchMessage(selectedChatUser?._id);
    }
  };

  useEffect(() => {
    //fetch all chats
    dispatch(fetchChats({ userId: "", query: "" }));
    scrollToBottom();

    // scroll events
    const container = conversationRef.current;
    container.addEventListener("scroll", handlePagignationScroll);
    return () => container.removeEventListener("scroll", handlePagignationScroll);
  }, [pageCount, messages]);

  //fetch messages
  const fetchMessage = async (room) => {
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}message/messages?page=${pageCount.current}&room=${room}`,
        method: "GET",
      });

      if (response.status) {
        if (response.data.length > 0) {
          dispatch(concatMessages(response.data));
          scrollToPosition(response.data.length);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        console.log(err);
        toast.error("Some error occured while fetch");
      }
    }
  };

  const handleIncomingMessage = (message) => {
    dispatch(fetchChats({ userId: "", query: "" }));
    if (message.data.room_id === selectedChatUser?._id) {
      dispatch(pushMessage(message.data));
      scrollToBottom();
    }
  };

  const handleStutusChange = (status) => {
    dispatch(updateStatus({ status: status.data }));
  };

  const formatDate = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date);

    // Check if the input date is today
    if (
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    ) {
      return "Today";
    }

    // Check if the input date is yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);
    if (
      inputDate.getDate() === yesterday.getDate() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getFullYear() === yesterday.getFullYear()
    ) {
      return "Yesterday";
    }

    // Format the input date as "Mon Day Year"
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return inputDate.toLocaleDateString(undefined, options);
  };

  const handleMarkAsSeen = async (id) => {
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}message/read_message_status`,
        body: JSON.stringify({ id: id}),
        method: "POST",
      });
      if (!response.status) {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured while fetch");
      }
    }
  }

  const renderMessagesByDate = () => {
    const groupedMessages = {};
    messages.forEach((message) => {
      const date = new Date(message?.createdAt).toDateString();
      const today = new Date().toDateString();
      if (!groupedMessages[date === "Invalid Date" ? today : date]) {
        groupedMessages[date === "Invalid Date" ? today : date] = [];
      }
      groupedMessages[date === "Invalid Date" ? today : date].push(message);
    });

    return Object.keys(groupedMessages).map((date) => (
      <div ref={chatRef} className="" key={date}>
        <div className="sticky top-0 bg-white bg-opacity-80 flex items-center justify-center mt-12">
          <div className=" bg-gray-50 h-px flex-1 mr-2"/>
          <h2 className="text-center text-[12px] font-thin px-2 py-px text-gray-100 w-fit bg-gray-50 rounded-full">
            {formatDate(date)}
          </h2>
          <div className=" bg-gray-50 h-px flex-1 ml-2"/>
        </div>
        {groupedMessages[date].map((message, index) => {
          if(message.is_private) return <PrivateMessageBubble msg={message} key={index} />
          switch (message?.message?.type) {
            case "text":
              return <TextBubble 
                      msg={message} 
                      key={index} 
                      onReplyPressed={()=> setReplyMessage(message)} 
                      onClickedReply={() => scrollToMessage(index)}
                      markAsSeen={handleMarkAsSeen}
                      />;
            case "image":
              return <ImageBubble msg={message} key={index} onReplyPressed={()=> setReplyMessage(message)} markAsSeen={handleMarkAsSeen} />;
            case "document":
              return <DocumentBubble msg={message} key={index} onReplyPressed={()=> setReplyMessage(message)} markAsSeen={handleMarkAsSeen} />;
            case "audio":
              return <VoiceBubble msg={message} key={index} onReplyPressed={()=> setReplyMessage(message)} markAsSeen={handleMarkAsSeen} />;
            default:
              return <TextBubble 
                      msg={message} 
                      key={index} 
                      onReplyPressed={()=> setReplyMessage(message)} 
                      onClickedReply={() => scrollToMessage(index)}
                      markAsSeen={handleMarkAsSeen}
                      />;
          }
        })}
      </div>
    ));
  };

  const handleReplaceMessage = (id, message) => {
    dispatch(replaceMessage({id: id, message: message}));
  }

  const scrollToMessage = (index) => {
    if (chatRef.current && index >= 0) {
      const conversationItem = chatRef.current.children[11];
      if (conversationItem) {
        conversationItem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }


  return (
    <section>
      <MessageSenderComponent
        onSuccess={handleReplaceMessage}
      />
      <div className="h-screen flex pt-2">
        {
          <p
            onClick={() => scrollToPosition(2)}
            className=" absolute bg-orange-600 z-40 top-0 px-3 rounded-full text-white"
          >
            {connectionStatus}
          </p>
        }
        <div className="w-[380px] border-r-[1px] border-gray-50">
          <ChatUsers
            onSelectedChat={handleChatSelected}
            selectedChat={selectedChatUser}
            pinned={pinned}
            unpinned={unpinned}
            changedViewAs={() => setChatUser()}
          />
        </div>
        <div className="flex flex-col mb-[65px] w-full overflow-hidden">
          {selectedChatUser && (
            <div className="sticky top-0 w-full h-[52px] border-b-[1px] border-gray-50">
              <ChatHeader chat={selectedChatUser} />
            </div>
          )}
          <div className="relative w-full h-full flex flex-col overflow-hidden">
            <div
              ref={conversationRef}
              className="overflow-auto h-full relative"
            >
              {selectedChatUser && <>{messages && renderMessagesByDate()}</>}
              {!selectedChatUser && (
                <div className=" h-full flex flex-col gap-1 items-center justify-center">
                  <h2 className=" font-bold">Chat not selected</h2>
                  <p className="text-sm text-gray-100">
                    Please select any chat your left section
                  </p>
                </div>
              )}
            </div>
            {selectedChatUser && (
              <div className="px-4 mb-3">
                <ChatFooter
                  user={selectedChatUser}
                  onSend={(msg) => {
                    dispatch(concatMessages(msg));
                    scrollToBottom();
                  }}
                  reply={reply}
                  onCancelReply={()=> setReplyMessage(null)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          ref={toolBarRef}
          className="transition-all duration-500 flex bg-white w-[64px] border-l-[1px] border-gray-50"
        >
          <div className="flex flex-col gap-4 items-center min-w-[64px] py-4">
              <button
                onClick={() => handleToolClick(0)}
                className={`hover:bg-primary-color hover:text-white rounded-md px-1 py-1 flex items-center ${
                  toolIndex === 0 ? " bg-gray-50" : ""
                }`}
              >
                <span className="material-symbols-outlined">person</span>
              </button>

              <button
                onClick={() => handleToolClick(1)}
                className={`hover:bg-primary-color hover:text-white rounded-md px-1 py-1 flex items-center ${
                  toolIndex === 1 ? " bg-gray-50" : ""
                }`}
              >
                <span className="material-symbols-outlined">local_mall</span>
              </button>

              <button
                onClick={() => handleToolClick(2)}
                className={`hover:bg-primary-color hover:text-white rounded-md px-1 py-1 flex items-center ${
                  toolIndex === 2 ? " bg-gray-50" : ""
                }`}
              >
                <span className="material-symbols-outlined">label</span>
              </button>

              <button
                onClick={() => handleToolClick(null)}
                className={`hover:bg-primary-color hover:text-white rounded-md px-1 py-1 flex items-center ${
                  toolIndex != null ? "block" : "hidden"
                }`}
              >
                <span className="material-symbols-outlined">
                  left_panel_close
                </span>{" "}
              </button>
          </div>
          <div
            className={`${
              toolIndex != null
                ? "block border-l-[1px] border-gray-50"
                : "hidden"
            } bg-white w-full h-full`}
          >
            {toolIndex === 0 ? <ProfileDetails /> : <></>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chat;
