import React, { useRef, useState, useEffect, useCallback } from "react";
import { useWavesurfer } from "../../hooks/wavesurfer/wavesurfer";
import { durationFormat } from "../../utils/formater";

function RecordPlayer(props) {
  const containerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const wavesurfer = useWavesurfer(containerRef, props);

  // On play button click
  const onPlayClick = useCallback(() => {
    wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
  }, [wavesurfer]);

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) return;

    setCurrentTime(0);
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("timeupdate", (currentTime) => setCurrentTime(currentTime)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);


  return (
    <div className="flex items-center gap-4 h-[29px]">
      <button
        className="flex items-center hover:text-primary-color text-app-black-color"
        onClick={onPlayClick}
      >
        {!isPlaying ? (
          <span className="material-symbols-outlined">play_circle</span>
        ) : (
          <span className="material-symbols-outlined">stop_circle</span>
        )}
      </button>
      <div ref={containerRef} className="w-[120px]"></div>
      <div role="timer">{durationFormat(currentTime)}</div>
      <button onClick={props.close} className="flex">
        <span className="material-symbols-outlined text-md text-red-500">close</span>
      </button>
    </div>
  );
}

export default RecordPlayer;
