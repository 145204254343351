import React from "react";

function CustomerTag({ label, color, dividerColor, remove }) {
  return (
    <div
      className={`flex text-[12px] ${color} items-center pl-[6px] h-[18px] rounded-sm`}
    >
      <p className="pr-[6px]">{label}</p>
      {
        remove && <div className={`w-[1px] ${dividerColor} h-[10px] rounded-full`}></div>
      }
      {remove && (
          <button
            className={`px-[2px] py-[2px] flex items-center justify-items-center hover:${dividerColor} mt-2 mb-2 ml-[2px] mr-[2px] rounded-md`}
          >
            <span class="material-symbols-outlined text-[14px] p-0 mt-[1px]">
              close
            </span>
          </button>
      )}
    </div>
  );
}

export default CustomerTag;
