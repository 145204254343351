import React from 'react'

function Tag({content, props}) {
  return (
    <div className='m-1'>
      <div className={`px-2 py-px rounded-sm ${props}`} >{content}</div>
    </div>
  )
}

export default Tag
