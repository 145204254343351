import React, { useEffect } from 'react'
import { useTemplateContext } from "../../../providers/template-provider/template_provider";

function NoButton() {
  const {  removeAllButtons }  = useTemplateContext();
  useEffect(() => {removeAllButtons()}, []);
  return (<></>)
}

export default NoButton
