import { useEffect } from 'react';
import { useMessageContext } from '../../hooks/message-queue/message-context';
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { toast } from "react-toastify";

const MessageSenderComponent = ({onSuccess}) => {
  const { getMessageFromQueue, removeMessageFromQueue } = useMessageContext();
  const message = getMessageFromQueue();

  useEffect(() => {
    if (message) {
      send(message.content);
    }
  }, [message]);


  const send = async (msg) => {
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}message/send`,
        method: "POST",
        body: JSON.stringify(msg),
      });
      if (response.status) {
        removeMessageFromQueue();
        onSuccess(msg._id, response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured while fetch");
      }
    }
  };
};

export default MessageSenderComponent;