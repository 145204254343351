import React, { useState, useRef } from "react";
import Button from "../button";
import Select from "react-select";
import HeaderButton from "./header-button";
import { languages } from "../../assets/json/languages";
import { toast } from "react-toastify";
import TabBar from "../tab-bar";
import ActionButtons from "./action-buttons";
import { TemplateProvider } from "../../providers/template-provider/template_provider";
import NoButton from "./no-button";
import ButtonPreview from "./button-preview";
import { useForm, FormProvider, Controller } from "react-hook-form";

function CreateTemplates() {
  const [header, setHeaderSelected] = useState("None");
  const [input, setInput] = useState({
    templateId: null,
    headerType: "None",
    headerText: "",
    body: "",
    footer: "",
    category: null,
    language: null,
    step: 1,
  });
  const headInputRef = useRef();
  const bodyInputRef = useRef();
  const titlePreviewRef = useRef();
  const methods = useForm({ mode: "onBlur" });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = methods;
  const watchAllFields = watch();

  const tabs = [
    {
      title: "No buttons",
    },
    {
      title: "Action Buttons",
    },
    {
      title: "Quick Replies",
    },
  ];

  // const handleInputChange = (event) => {
  //   const current = { ...input };
  //   const inputValue = event.target.value;
  //   const segments = inputValue.split(/(\{\{[0-9]+\}\})/);
  //   const variableNumber = segments.filter((segment) =>
  //     /\{\{[0-9]+\}\}/.test(segment)
  //   ).length;
  //   const newValue = inputValue.replace(
  //     /\{\{\}\}/g,
  //     `{{${variableNumber + 1}}}`
  //   );
  //   event.target.value = newValue;
  //   current[event.target.name] = newValue;
  //   setInput(current);
  //   console.log("change");
  // };

  const handleHeader = (value) => {
    setHeaderSelected(value);
    setInput({ ...input, ...{ headerText: "" } });
  };

  const handleSelection = (value, action) => {
    const current = { ...input };
    current[action.name] = value;
    setInput(current);
  };

  const handleTemplateIdChange = (event) => {
    const current = { ...input };
    const { value } = event.target;
    const formattedValue = value.toLowerCase().replace(/\s+/g, "_");
    event.target.value = formattedValue;
    current[event.target.name] = formattedValue;
    setInput(current);
  };

  const categoryOption = [
    { value: "Utility", label: "Utility" },
    { value: "Marketing", label: "Marketing" },
    { value: "Authentication", label: "Authentication" },
  ];

  const outputArray = languages.map((item) => ({
    value: item.code,
    label: item.language,
  }));

  const ColoredBody = ({ message }) => {
    // Use regular expression to find segments enclosed in {{#}}
    const segments = message?.split(/(\{\{[0-9]+\}\})/);
    return (
      <p>
        {segments?.map((segment, index) => {
          if (/\{\{[0-9]+\}\}/.test(segment)) {
            // Apply a different style to segments enclosed in {{#}}
            return (
              <span
                key={index}
                className="text-sm font-light mb-1 text-primary-color"
              >
                {segment}
              </span>
            );
          } else {
            return (
              <span className="text-sm font-light mb-1" key={index}>
                {segment}
              </span>
            );
          }
        })}
      </p>
    );
  };

  const ColoredHeader = ({ message }) => {
    // Use regular expression to find segments enclosed in {{#}}
    const segments = message?.split(/(\{\{[0-9]+\}\})/);
    return (
      <p ref={titlePreviewRef}>
        {segments?.map((segment, index) => {
          if (/\{\{[0-9]+\}\}/.test(segment)) {
            // Apply a different style to segments enclosed in {{#}}
            return (
              <span
                key={index}
                name={index}
                className="text-sm font-light mb-1 text-primary-color"
              >
                {segment}
              </span>
            );
          } else {
            return (
              <span className="text-sm font-medium mb-1" key={index}>
                {segment}
              </span>
            );
          }
        })}
      </p>
    );
  };

  const addVariable = (name, event) => {
    event.preventDefault();
    const inputValue = getValues(name);
    const segments = inputValue.split(/(\{\{[0-9]+\}\})/);
    const variableNumber = segments.filter((segment) =>
      /\{\{[0-9]+\}\}/.test(segment)
    ).length;
    const newInputValue = inputValue + `{{${variableNumber + 1}}}`;
    setValue(name, newInputValue);
  };

  const handleInputChange = (e) => {
    let newText = e.target.value;
    const segments = newText.split(/(\{\{[0-9]+\}\})/);
    let count = 0;
    segments.forEach((match) => {
      if (/\{\{[0-9]+\}\}/.test(match)) {
        count ++;
        console.log(count);
        newText =  newText.replace(match, `{{${count}}}`)
      }
    });

    setValue(e.target.name, newText);
  };

  //   const BuildHeaderExamples = () => {
  //     const value = headInputRef?.current?.value;
  //     const segments = value?.split(/(\{\{[0-9]+\}\})/);
  //     {
  //       return segments?.map((segment, index) => {
  //         if (/\{\{[0-9]+\}\}/.test(segment)) {
  //           // Apply a different style to segments enclosed in {{#}}
  //           return (
  //             <div key={index} className="my-2">
  //               <input
  //                 name={index}
  //                 type="text"
  //                 onChange={headerVariableChange}
  //                 className=" text-sm border border-gray-100 min-w-[330px] px-3 py-1 rounded-md focus:outline-none placeholder:text-sm"
  //                 placeholder="Enter something (h)"
  //               />
  //             </div>
  //           );
  //         }
  //       });
  //     }
  //   };

  //   const BuildBodyExamples = () => {
  //     const segments = input.body.split(/(\{\{[0-9]+\}\})/);
  //     {
  //       return segments.map((segment, index) => {
  //         if (/\{\{[0-9]+\}\}/.test(segment)) {
  //           // Apply a different style to segments enclosed in {{#}}
  //           return (
  //             <div className="my-2">
  //               <input
  //                 name={segment}
  //                 type="text"
  //                 className=" text-sm border border-gray-100 min-w-[330px] px-3 py-1 rounded-md focus:outline-none placeholder:text-sm"
  //                 placeholder="Enter something"
  //               />
  //             </div>
  //           );
  //         }
  //       });
  //     }
  //   };

  const handleFormate = () => {
    window.open("https://faq.whatsapp.com/539178204879377", "_blank");
  };

  const handleProceed = () => {
    console.log(input.templateId);
    if (input.templateId === null || input.templateId.length < 1) {
      toast.warning("Template ID is required");
      return;
    }

    if (input.category === null) {
      toast.warning("Category is required");
      return;
    }

    if (input.language === null) {
      toast.warning("Language is required");
      return;
    }

    if (input.body.length < 1) {
      toast.warning("Body is required");
      return;
    }
  };

  return (
    <div className="flex flex-col">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((data) => console.log(data))}
          className="text-sm text-app-black-color"
        >
          {/* header steps */}
          <div className="my-4 mx-5 flex gap-1 items-center text-md font-medium cursor-pointer">
            <h2 className="text-gray-200">Message Template</h2>
            <span className="material-symbols-outlined text-sm text-gray-200">
              arrow_forward_ios
            </span>
            <h2>Create New Message Template</h2>
            <span className="material-symbols-outlined text-sm text-gray-200">
              arrow_forward_ios
            </span>
            <h2 className="text-gray-200">Fill & Submit</h2>
          </div>
          {/* main section */}
          <TemplateProvider>
            <div className="flex-1 flex">
              {/* left section */}
              <div className="flex-1 h-full w-full px-5">
                <div className="h-[80vh] overflow-y-auto">
                  <div className="mb-2">
                    <label>Template ID</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Template ID (required)"
                      onChange={handleTemplateIdChange}
                      name="templateId"
                      className="border px-3 py-2 my-1 rounded-md min-w-[384px] placeholder:text-sm focus:outline-none"
                      {...register("templateId", {
                        required: true,
                        pattern: /^[a-zA-Z0-9_]+$/, // Enforce the pattern here
                      })}
                    />
                    {errors.templateId && (
                      <p className=" text-red-600 text-[11px]">
                        Please enter a valid ID in the 'example_template'
                        format.
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label>Category</label>
                    <br />
                    <Controller
                      name="category"
                      control={control}
                      rules={{ required: "This field is required" }} // Add validation rules
                      render={({ field }) => (
                        <Select
                          className="py-1 rounded-md max-w-[384px] focus:outline-none"
                          options={categoryOption}
                          {...field}
                        />
                      )}
                    />
                    {errors.category && (
                      <p className="text-red-600 text-[11px]">
                        Category is required.
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label>Language</label>
                    <br />
                    <Controller
                      name="language"
                      control={control}
                      rules={{ required: "Language is required" }} // Add validation rules
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Select Language"
                          className="text-sm py-1 rounded-md max-w-[384px] focus:outline-none"
                          options={outputArray}
                        />
                      )}
                    />
                    {errors.language && (
                      <p className="text-red-600 text-[11px]">
                        Language is required.
                      </p>
                    )}
                  </div>

                  <div className="my-4">
                    <h3 className="text-md font-medium">
                      Header
                      <span className="text-sm font-normal">(Optional)</span>
                    </h3>
                    <label className="text-[12px] text-gray-100">
                      Add a title or choose which type media you’ll use for this
                      header
                    </label>
                    <div className="mt-4 flex gap-4">
                      <HeaderButton
                        title="None"
                        selected={header}
                        onClick={handleHeader}
                      />
                      <HeaderButton
                        title="Text"
                        icon="title"
                        selected={header}
                        onClick={handleHeader}
                      />
                      <HeaderButton
                        title="Image"
                        icon="imagesmode"
                        selected={header}
                        onClick={handleHeader}
                      />
                      <HeaderButton
                        title="Video"
                        icon="play_circle"
                        selected={header}
                        onClick={handleHeader}
                      />
                      <HeaderButton
                        title="Doc"
                        icon="description"
                        selected={header}
                        onClick={handleHeader}
                      />
                    </div>
                    {header === "Text" && (
                      <div className="mt-4">
                        <label>Title</label>
                        <br />
                        <input
                          ref={headInputRef}
                          type="text"
                          name="headerText"
                          placeholder="Enter title text"
                          onChange={handleInputChange}
                          className="border px-3 py-2 my-1 rounded-md min-w-[384px] placeholder:text-sm focus:outline-none"
                        />
                        <button
                          onClick={(event) => addVariable("headerText", event)}
                          className="flex gap-1 items-center justify-center my-1 text-app-black-color"
                        >
                          <span className="material-symbols-outlined text-sm">
                            add
                          </span>
                          <p>ADD VARIABLE</p>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="my-4">
                    <h3 className="text-md font-medium">Body</h3>
                    <label className="text-[12px] text-gray-100">
                      Enter the text for your message in the language you’ve
                      selected.
                    </label>
                    <div className="mt-1 w-fit">
                      <textarea
                        ref={bodyInputRef}
                        name="body"
                        cols="51"
                        rows="4"
                        placeholder={`Enter text in ${input?.language?.label}`}
                        className="border px-3 py-2 my-1 rounded-md min-w-[340px] placeholder:text-sm focus:outline-none"
                        {...register("body", {
                          required: true,
                          onChange: (e) => handleInputChange(e),
                        })}
                      ></textarea>
                      {errors.body && (
                        <p className="text-red-600 text-[11px]">
                          Body is required.
                        </p>
                      )}
                      <div className="flex items-center justify-between">
                        <div className="text-[10px] text-gray-100">
                          <p className="">TAB TO INSERT {"{{#}}"}</p>
                        </div>
                        <div className="text-gray-100 text-sm flex gap-3 items-center">
                          <button
                            onClick={(e) => addVariable("body", e)}
                            className="flex gap-1 items-center justify-center text-app-black-color"
                          >
                            <span className="material-symbols-outlined text-sm">
                              add
                            </span>
                            <p className="">ADD VARIABLE</p>
                          </button>
                          <button className="flex items-center">
                            <span className="material-symbols-outlined">
                              mood
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className=" text-sm my-1">
                        <p className=" text-app-black-color">
                          Want to format text?
                        </p>
                        <button
                          onClick={handleFormate}
                          className=" text-[12px] text-primary-color underline"
                        >
                          See WhatsApp message formatting examples
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="my-4">
                    <h3 className="text-md font-medium">
                      Footer{" "}
                      <span className="text-sm font-normal">(Optional)</span>
                    </h3>
                    <input
                      type="text"
                      name="footer"
                      placeholder="Enter footer text..."
                      onChange={handleInputChange}
                      className="border px-3 py-2 my-1 rounded-md min-w-[384px] placeholder:text-sm focus:outline-none"
                    />
                  </div>
                  <div className="my-4">
                    <h3 className="text-md font-medium">Buttons</h3>
                    <label className="text-[12px] text-gray-100">
                      Create buttons that let customers respond to your message
                      or
                      <br />
                      take action.
                    </label>
                    <div className="my-2 w-[380px]">
                      <TabBar tabs={tabs}>
                        <NoButton />
                        <ActionButtons
                          control={control}
                          watch={watchAllFields}
                        />
                        <div className=""></div>
                      </TabBar>
                    </div>
                  </div>
                </div>
              </div>
              {/* right section */}
              <div className="w-[380px]">
                <div className="px-6">
                  <h2 className="text-sm text-gray-100">MESSAGE PREVIEW</h2>
                  <div className="bg-gray-50 p-2 my-2 rounded-md overflow-hidden">
                    {header === "Text" && (
                      <ColoredHeader message={input.headerText} />
                    )}
                    {(header === "Image" ||
                      header === "Video" ||
                      header === "Doc") && (
                      <div className="mb-3 rounded-md bg-[#e1e1e2] w-full min-h-[120px] flex items-center justify-center overflow-hidden">
                        {header === "Image" && (
                          <span className="material-symbols-outlined">
                            imagesmode
                          </span>
                        )}
                        {header === "Video" && (
                          <span className="material-symbols-outlined">
                            play_circle
                          </span>
                        )}
                        {header === "Doc" && (
                          <span className="material-symbols-outlined">
                            description
                          </span>
                        )}
                      </div>
                    )}
                    <ColoredBody message={watchAllFields["body"]} />
                    <p className="text-[12px] text-gray-200 font-light my-2">
                      {input.footer}
                    </p>
                    <div className="mt-4">
                      <ButtonPreview />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TemplateProvider>
          {/* footer buttons */}
          <div className=" fixed bottom-0 py-2 w-full flex items-center justify-between border-t border-gray-50 px-6">
            <Button
              className="bg-gray-50 text-app-black-color text-sm py-1 px-4 rounded-md shadow-sm"
              // onClick={() => {}}
              label="Cancel"
            />
            <Button
              className="bg-primary-color text-white text-sm py-1 px-4 rounded-md shadow-sm"
              // onClick={handleProceed}
              label="Next"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default CreateTemplates;
