export const contacts = [
  {
    id:1,
    name: "Ijas Aslam",
    image:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
    channel: "WhatsApp",
    channelId: "+91 9645469986",
    tags: ["Premium", "Return"],
    createdAt: "2023-07-23T03:00:20.596Z"
  },
  {
    id:2,
    name: "Aysa Aalam",
    image: null,
    channel: "Instagram",
    channelId: "+91 9645469981",
    tags: ["Premium"],
    createdAt: "2023-07-23T03:01:20.596Z"
  },
  {
    id:3,
    name: "John Doe",
    image: null,
    channel: "Email",
    channelId: "john.doe@example.com",
    tags: ["Standard"],
    createdAt: "2023-07-24T03:01:40.596Z"
  },
  {
    id:4,
    name: "Sarah Smith",
    image: null,
    channel: "Facebook",
    channelId: "sarah.smith",
    tags: ["Premium", "Return"],
    createdAt: "2023-07-24T03:01:40.596Z"
  },
  {
    id:4,
    name: "Sarah Smith",
    image: null,
    channel: "Facebook",
    channelId: "sarah.smith",
    tags: ["Premium", "Return"],
    createdAt: "2023-07-24T03:01:40.596Z"
  },
];
