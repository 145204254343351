import React from 'react'
import { Line } from "react-chartjs-2";
import { 
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
    );
  

const data = {
    labels: [
      'Jan 2021',
      'Feb 2021',
      'Mar 2021',
      'May 2021',
      'Jun 2021',
      'July 2021',
      'Aug 2021',
      'Sept 2021',
      'Oct 2021',
      'Nav 2021',
      'Dec 2021',
    ],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
        {
          label: 'Initiated',
          data: [1000, 19223, 96323, 3400, 32423, 122],
          borderWidth: 4,
          borderColor:'#5C42F9',
          backgroundColor:'#5C42F9',
          lineTension: .3,  
          fill: true,
          pointRadius:0,
        }
    ]
}

function ChatView() {
  return (
    <div className='w-[90vw] md:w-[90vh] h-[50vh]'>
        <Line data={data} options={{
          maintainAspectRatio:false,
          plugins:{
            legend:{
              display:false
            }
          }
         }}/>
    </div>
  )
}

export default ChatView
