import React from "react";
import CircleAvatar from "../circle_avatar";
import { chatTimeFormat } from "../../utils/formater";
import './style.css';

function ChatUserTile({ data, onSelectedChat, active, onClickPin }) {
  let content = "";
  const messageType = data?.messages?.message?.type;
  if (messageType === "text") {
    content = data?.messages?.message[messageType]?.body;
  } else if (messageType === "image") {
    content = "Image message";
  } else if (messageType === "audio") {
    content = "Audio message";
  }
  return (
    <>
      <li className="mb-2 cursor-pointer" onClick={()=> onSelectedChat(data)}>
        <div id="chat-user" className={`chat-user flex gap-2 items-center hover:bg-gray-50 px-4 ${active ? 'border-l-2 border-primary-color':''}`}>
          <CircleAvatar
            profileImage={null}
            name={data?.name}
            size="w-[44px] h-[44px]"
          />
          <div className="flex-1 flex flex-col overflow-hidden whitespace-nowrap">
            <h5 className=" text-app-black-color font-medium">{data?.name}</h5>
            <p className="text-gray-500">{content}</p>
          </div>
          <div className="flex flex-col items-end ">
            <p className=" text-app-black-color">
              {chatTimeFormat(data?.messages?.createdAt)}
            </p>
            <div className="flex gap-1">
              <button onClick={(e) =>{
                e.stopPropagation();
                onClickPin(data);
              }} className="">
                <label className={`pin-btn`}>
                  <span className="material-symbols-outlined text-[14px] rotate-[-40deg] flex items-center justify-center bg-gray-50 text-gray-600 hover:bg-primary-color hover:text-white rounded-full min-w-[18px] h-[18px] pointer">push_pin</span>
                </label>
              </button>
              {data.unreadCount > 0 && (
                <span className="bg-primary-color rounded-full min-w-[18px] h-[18px] px-[7px] py-[1px] text-white font-medium text-sm flex items-center justify-center">
                  {data?.unreadCount}
                </span>
              )}
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default ChatUserTile;
