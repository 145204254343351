import React from 'react'
import Config from '../../config/config'

function CircleAvatar({profileImage, name, size}) {


  const buildAvatar = () =>{
    if(profileImage != null){
        return profileImage;
    }else{
        var url = new URL(Config.avatarBaseUrl);
        url.searchParams.append('name', name);
        url.searchParams.set('color', '#5C42F9');
        url.searchParams.set('background', '#E2DDFC');
        url.searchParams.set('rounded', true);
        url.searchParams.set('size', 512);
        return url;
    }
  }  
  

  return (
    <div className={`${size} mb-1 mt-1 rounded-full`}>
        <img className='rounded-full' src={buildAvatar()} alt={name}></img>
    </div>
  )
}

export default CircleAvatar
