import React from "react";
import CircleAvatar from "../../../components/circle_avatar";
import ExpandTile from "../../../components/expand-tile";
import CustomerTagContainer from "../../../components/customer-tag-container";
import CustomerAddressTab from "../../../components/customer-address-tab";

function ProductDetails() {
  return (
    <div className="px-4 py-2 cursor-pointer overflow-y-scroll no-scrollbar h-full">
      <h3>Customer Profile</h3>
      <div className="flex items-center flex-col gap-2 my-10">
        <CircleAvatar
          profileImage={null}
          name="Ijas Aslam"
          size={"w-24 h-24"}
        />

        <div className="flex flex-col items-center my-2">
          <h2 className=" text-lg text-app-black-color">Ijas Aslam</h2>
          <p className=" text-sm text-gray-100 my-1">+91 9645459986</p>
        </div>

        <div className=" flex gap-6 text-2xl">
            <button className=" bg-gray-50 flex p-1 rounded-md hover:bg-primary-color hover:text-white">
              <span className="material-symbols-outlined">add_home</span>
            </button>

          <button className=" bg-gray-50 flex p-1 rounded-md hover:bg-primary-color hover:text-white">
              <span className="material-symbols-outlined">new_label</span>
            </button>

            <button className=" bg-gray-50 flex p-1 rounded-md hover:bg-primary-color hover:text-white">
              <span className="material-symbols-outlined">edit</span>
            </button>
        </div>
      </div>
      <hr className=" border-gray-50"></hr>
      <div className="py-4 relative mb-10">
        <ExpandTile label='MEDIA' element={<p>media</p>}/>
        <ExpandTile label='TAG' element={<CustomerTagContainer/>}/>
        <ExpandTile label='ADDRESS' element={<CustomerAddressTab/>}/>
      </div>
    </div>
  );
}

export default ProductDetails;
