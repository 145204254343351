import React from 'react'
import { Outlet, Navigate } from "react-router-dom";
import Header from '../header';
import { useSelector } from "react-redux";

function SubLayout() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
  return (
    <div className='max-h-screen w-full overflow-hidden'>
      <Header />
      <div className='flex flex-col items-center mt-[61px]'>
        <section className='flex max-w-[1440px] w-[100%]'>
            <main className='w-[100%] border-l-[1px] border-r-[1px] border-gray-50'>
                <Outlet/>
            </main>
        </section>
      </div>
    </div>
  );
  }
}

export default SubLayout
