import React, {useEffect} from "react";
import { chatTimeFormat } from "../../../utils/formater";
import "./style.css";

function DocumentBubble({ msg, onReplyPressed, markAsSeen }) {
  const isUser = msg?.message?.to ? true : false;
  const link = msg?.message?.document?.link;
  const status = msg?.status;
  const caption = msg?.message?.document?.caption;
  const fileName = msg?.file_name;
  const fileSize = msg?.file_size;
  const createdAt = msg?.createdAt;
  const context = msg?.message?.context;
  let reply = null;
  if(Array.isArray(msg?.reply)){
   reply = msg?.reply[0]?.message;
  }

  let currentStatus = "unknown";
  if (status.length !== -1) {
    const lastStatus = status[status?.length - 1];
    currentStatus = lastStatus?.status;
  }

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  };


  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?._id;
    if (msg?.is_read) {
      if (!msg?.is_read?.includes(userId)) {
        markAsSeen(msg?._id);
      }
    }
  }, []);

  const renderReply = () => {
    return (
      <div className={`cursor-pointer`}>
        <div className="bg-[#eeeeee] text-gray-100 rounded-md py-2 px-4 border-l-2 border-l-primary-color">
          {/* text  */}
          {
            reply?.type === 'text' && 
            <p className="bg-[#eeeeee] text-gray-100 w-fit rounded-md">
            {
              reply?.text?.body
            }
          </p>
          }
          {/* voice */}
          {
            reply?.type === 'audio' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">mic</span>
              <p className="">Audio message</p>
            </div>
          }
          {/* document */}
          {
            reply?.type === 'document' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">note</span>
              <p className="">Document message</p>
            </div>
          }
          {/* image */}
          {
            reply?.type === 'image' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">photo</span>
              <p className="">Image message</p>
            </div>
          }
        </div>
      </div>
    );
  };

  return (
    <div
      id="root-document"
      className={`flex ${isUser ? "flex-row-reverse items-center " : ""}`}
    >
      <div className="max-w-md mx-4 my-4">
        <div
          className={`flex ${isUser ? "justify-end" : "justify-start"} flex-col rounded-md bg-gray-50`}
        >
          
          { context && renderReply() }
          <div
            className={`flex items-center gap-2 w-fit rounded-md py-2 px-3 ${
              isUser
                ? "bg-primary-color text-white"
                : " bg-gray-50 text-app-black-color"
            }`}
          >
            {currentStatus === "pending" && <div className="loading-circle" />}
            <div className="flex items-center">
              <span className="material-symbols-outlined text-[34px]">
                description
              </span>
            </div>
            <div className=" text-sm min-w-[184px]">
              <p className="">{fileName}</p>
              <p className="font-light text-gray-100">
                {/* {extension.toUpperCase()} */}
              </p>
            </div>
            <div className="text-sm text-gray-100">
              <p className="">{formatBytes(fileSize)}</p>
            </div>
          </div>
          {caption && <div className=" text-sm px-2 py-1">{caption}</div>}
        </div>
        <div
          className={`flex gap-2 items-center ${
            isUser ? "flex-row-reverse " : ""
          }my-1`}
        >
          {isUser && status && (
            <div className="flex items-center">
              {currentStatus === "error" && (
                <p className="text-[12px] font-light text-red-600">
                  Error sending...
                </p>
              )}
              {currentStatus === "delivered" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  done
                </span>
              )}
              {currentStatus === "seen" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  done_all
                </span>
              )}
              {currentStatus === "pending" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  hourglass_top
                </span>
              )}
            </div>
          )}
          {createdAt && (
            <p className={`text-[12px] text-gray-100`}>
              {chatTimeFormat(createdAt)}
            </p>
          )}
        </div>
      </div>
      <div id="btn-container" className="hidden">
        <div className="flex gap-4 mb-6">
          <button
            onClick={onReplyPressed}
            className="hover:text-primary-color text-gray-100 text-sm h-[34px] flex items-center justify-center"
          >
            Reply
          </button>
        </div>
      </div>
    </div>
  );
}

export default DocumentBubble;
