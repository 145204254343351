import React, { useEffect, useRef, useState } from "react";

function VideoPlayer() {
  const videoEl = useRef(null);
  const [muted, setMute] = useState(true);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="relative aspect-video">
      <video
        className="relative peer"
        style={{ maxHeight:"320px", margin: "0 auto" }}
        playsInline
        loop
        muted={muted}
        alt="All the devices"
        src="https://rr5---sn-q4fl6ndl.googlevideo.com/videoplayback?expire=1689681240&ei=-Ci2ZObZAdCX2_gPoqGioAY&ip=181.215.182.177&id=o-AG6-HexyVNMoNDGBYusvT7mYbE_NKu21M9_ZqnRTPL1i&itag=22&source=youtube&requiressl=yes&mh=lg&mm=31%2C29&mn=sn-q4fl6ndl%2Csn-q4flrnek&ms=au%2Crdu&mv=m&mvi=5&pl=24&initcwndbps=578750&spc=Ul2Sq7kseG9GR1Q4sDml89U460ke8qdZe2M8EEIBpg&vprv=1&svpuc=1&mime=video%2Fmp4&ns=xtXcN-H8aLU3fe_cPXEpA40O&cnr=14&ratebypass=yes&dur=75.952&lmt=1680253629253614&mt=1689659116&fvip=1&fexp=24007246%2C24362685&beids=24350018&c=WEB&txp=5311224&n=RlhUCZasxEZadQ&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cspc%2Cvprv%2Csvpuc%2Cmime%2Cns%2Ccnr%2Cratebypass%2Cdur%2Clmt&sig=AOq0QJ8wRQIhAOIQ3OtVLQU514UEEAxqlX-9wwhenRWnvqc11GW4F7jDAiAhBy9ENIvQmx03VDkuM_5B5u-j6SOFIaLCorNXl0Uaag%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=AG3C_xAwRQIhAM3c9c0lCsaC9OIf0G7njQySTCKYJGlNAYTSXaLEfDc1AiAa4bjU3KGWAgSIlPs2Rxo5PS1Hw8bSDSSl7yUklk1cig%3D%3D&title=Zoko%20-%20Connect%20your%20Shopify%20store%20to%20WhatsApp"
        ref={videoEl}
      />
      <button
        onClick={() => {
          setMute(!muted);
        }}
        className="absolute hidden peer-hover:flex items-center hover:flex right-2 top-2 gap-2 bg-black text-white px-4 py-2 rounded-md text-[12px]"
      >
        {muted ? (
          <span className="material-symbols-outlined">volume_off</span>
        ) : (
          <span className="material-symbols-outlined">volume_up</span>
        )}
      </button>
    </div>
  );
}

export default VideoPlayer;
