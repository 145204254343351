import React, { useEffect, useState, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder-2";
import RecordPlayer from "../record-player";
import { durationFormat } from "../../utils/formater";
import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import EmojiKeypad from "../emoji-keypad";
import AttachFileModal from "../file-attach-modal";
import FooterMediaPreview from "../footer-media-preview";
import SendContantModel from "../send-contact-modal";
import { toast } from "react-toastify";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { useMessageContext } from "../../hooks/message-queue/message-context";
import { fileExtension } from "../../utils/formater";
import QuickRepliesDialog from "../chat-quick-reply";

function ChatFooter({ user, onSend, reply, onCancelReply }) {
  const [duration, setDuration] = useState(0);
  const [showEmoji, setEmoji] = useState(false);
  const [showAttach, setAttach] = useState(false);
  const [showContact, setShowContacts] = useState(false);
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  //input area for chat
  const inputFieldRef = useRef(null);
  const [input, setInput] = useState("");
  const [files, setFile] = useState([]);
  const [captions, setCaption] = useState([]);
  const [isPrivate, setPrivate] = useState(false);

  //queue
  const { addMessageToQueue } = useMessageContext();

  const imageTypes = ["png", "jpg", "jpeg"];
  const videoTypes = ["mp4", "avi", "mov", "mpeg"];
  // const docTypes = ['zip', 'txt', 'pdf', 'doc', 'docx', 'xlsx', 'ppt', 'pptx'];
  const audioTypes = ["mp3", "wav", "ogg"];

  // text, voice, file,

  const handleStartRecording = () => {
    startRecording();
    setDuration(0);
  };

  const handleStopRecording = () => {
    stopRecording();
    setDuration(0);
  };

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
      echoCancellation: true,
    });

  useEffect(() => {
    let intervalId;
    if (status === "recording") {
      intervalId = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    } else {
      // Clear the interval when recording stops
      clearInterval(intervalId);
    }

    if(isPrivate){
      onCancelReply();
    }

    return () => {
      // Clean up the interval on component unmount
      clearInterval(intervalId);
    };
  }, [status, reply]);

  const handleSendButton = (textContent) => {
    if (isPrivate) {
      const privateNote = {
        room_id: user.messages.room_id,
        status: [],
        _id: new Date().getUTCMilliseconds(), // for reference
        is_private: true,
        message: {
          type: "text",
          text: {
            body: input,
          },
        },
      };
      addMessageToQueue({ content: privateNote });
      onSend([privateNote]);
      inputFieldRef.current.innerText = null;
      setInput("");
      return;
    } else if (mediaBlobUrl !== undefined) {
      //send audio message
      let voice = {
        room_id: user.messages.room_id,
        status: [{ status: "pending" }],
        _id: new Date().getUTCMilliseconds(),
         message: {
          messaging_product: user.contact_info.channel,
          recipient_type: "individual",
          to: user.country_code + user.phone_number,
          type: "audio",
          audio: {
            link: mediaBlobUrl,
          },
        },
      };
      if(reply){
        voice.message['context'] = { id : reply.message.id };
        voice['context_id'] = reply._id;
        voice['reply'] = [reply];
        onCancelReply();
      }
      onSend([voice]);
      handleVoiceUpload(voice);
      return;
    } else if (input.length >= 1) {
      let text = {
        room_id: user.messages.room_id,
        status: [{ status: "pending" }],
        _id: new Date().getUTCMilliseconds(), // for reference
        message: {
          messaging_product: user.contact_info.channel,
          recipient_type: "individual",
          to: user.country_code + user.phone_number,
          type: "text",
          text: {
            preview_url: false,
            body: input,
          },
        },
      };
      if(reply){
        text.message['context'] = { id : reply.message.id };
        text['context_id'] = reply._id;
        text['reply'] = [reply];
        onCancelReply();
      }
      console.log(text);
      addMessageToQueue({ content: text });
      onSend([text]);
      inputFieldRef.current.innerText = null;
      setInput("");
      return;
    } else if (status === "recording") {
      handleStopRecording();
    } else if (files.length >= 1) {
      handleMediaUpload();
    } else {
      const text = {
        room_id: user.messages.room_id,
        status: "pending",
        id: new Date().getUTCMilliseconds(),
        message: {
          messaging_product: user.contact_info.channel,
          recipient_type: "individual",
          to: user.country_code + user.phone_number,
          type: "text",
          text: {
            preview_url: false,
            body: textContent,
          },
        },
      };
      addMessageToQueue({ content: text });
      onSend([text]);
      inputFieldRef.current.innerText = null;
      setInput("");
      return;
    }
  };

  const handleEmojiText = (emj) => {
    const element = inputFieldRef.current;
    if (!element) return;
    // Get the current selection and range
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      if (range) {
        // Check if the selected node is within the contentEditable div
        const commonAncestor = range.commonAncestorContainer;
        if (!element.contains(commonAncestor)) return;
        // Create a new emoji span element
        const emojiSpan = document.createElement("span");
        emojiSpan.textContent = emj; // Emoji Unicode representation
        // Insert the emoji span at the start of the selection range
        range.insertNode(emojiSpan);
        // Move the selection to the end of the inserted emoji
        const newRange = document.createRange();
        newRange.setStartAfter(emojiSpan);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    } else {
      const emojiSpan = document.createElement("span");
      inputFieldRef.current.appendChild(emojiSpan);
    }

    let textContent = inputFieldRef.current.innerText;
    textContent = textContent.replace(/<br\s*\/?>/gi, "\n");
    setInput(textContent);
  };

  const handleInput = () => {
    let textContent = inputFieldRef.current.innerText;
    textContent = textContent.replace(/<br\s*\/?>/gi, "\n");
    setInput(textContent);
  };

  const handleFileSelect = (files) => {
    let _file = [];
    for (let index = 0; index < files.length; index++) {
      _file.push(files[index]);
    }
    setFile(_file);
  };

  const removeFileSelect = (caption, file) => {
    let _files = files;
    const nf = _files.filter((fl) => fl !== file);
    setFile(nf);
    const cp = captions.filter((cp) => cp !== caption);
    setCaption(cp);
  };

  const addMoreFileSelect = (event) => {
    const _files = event.target.files;
    let _file = [];
    for (let index = 0; index < _files.length; index++) {
      _file.push(_files[index]);
    }
    setFile([...files, ..._file]);
  };

  const handleCaptionChange = (index, caption) => {
    const updatedCaptions = [...captions];
    updatedCaptions[index] = caption;
    setCaption(updatedCaptions);
  };

  const handlePrivate = () => {
    setFile([]);
    clearBlobUrl();
    onCancelReply();    
    setPrivate((prevState) => !prevState);
  };

  const handleVoiceUpload = async (voice) => {
    try {
      const res = await fetch(mediaBlobUrl);
      clearBlobUrl();
      const blob = await res.blob();
      const formData = new FormData();
      formData.append("file", blob);
      const response = await fetcher({
        url: `${Config.baseUrl}file/upload/voice`,
        method: "POST",
        body: formData,
        multiPart: true,
      });

      if (response.status) {
        let message = {
          room_id: user?.messages?.room_id,
          status: voice?.status,
          _id: voice?._id,
          context_id: voice?.context_id,
          message: {
            context: voice?.message?.context,
            messaging_product: user?.contact_info?.channel,
            recipient_type: "individual",
            to: user?.country_code + user?.phone_number,
            type: "audio",
            audio: {
              link:  "http://localhost:1337/public/media/" + response?.data,
            },
          },
          reply: voice?.reply,
        };
        addMessageToQueue({ content: message });
      } else {
        toast.error(response.info.message);
      }
    } catch (error) {
      console.error("Error converting Blob URL to Blob:", error);
      toast.error("Error uploading...");
    }
  };

  const handleMediaUpload = async () => {
    const messages = await Promise.all(
      files.map(async (item, index) => {
        let type = "";
        const fileEx = fileExtension(item.name)[0];
        if (imageTypes.includes(fileEx)) {
          type = "image";
        } else if (videoTypes.includes(fileEx)) {
          type = "video";
        } else if (audioTypes.includes(fileEx)) {
          type = "audio";
        } else {
          type = "document";
        }

        let message = {
          room_id: user.messages.room_id,
          status: [{ status: "pending" }],
          _id: 99 + Math.random() * (99999 - 99),
          file_name: item.name,
          file_size: item.size,
          message: {
            messaging_product: user.contact_info.channel,
            recipient_type: "individual",
            to: user.country_code + user.phone_number,
            type: type,
            [type]: {
              link: await getFileDataUrl(item),
              caption: captions[index],
            },
          },
        };
        if(reply){
          message.message['context'] = { id : reply.message?.id };
          message['context_id'] = reply?._id;
          message['reply'] = [reply];
        }
        return { message: message, file: item };
      })
    );
    
    messages.map((msg) => {
      onSend([msg.message]);
    });
    setFile([]);
    onCancelReply();

    const uploadedMediaUrls = await Promise.all(
      messages.map(async (msg) => {
        // Perform media upload logic here
        const formData = new FormData();
        formData.append("file", msg.file);
        const response = await fetcher({
          url: `${Config.baseUrl}file/upload`,
          method: "POST",
          body: formData,
          multiPart: true,
        });
        if (response.status) {
          return { message: msg.message, url: response.data };
        } else {
          toast.error(response.info.message);
        }
        return "";
      })
    );

    // Add media messages to the message queue
    if (uploadedMediaUrls.length < 1) return;
    uploadedMediaUrls.forEach((item) => {
      const messageType = item?.message?.message?.type;
      const message = {
        room_id: item?.message?.room_id,
        status: [{ status: "pending" }],
        _id: item?.message._id,
        file_name: item?.message?.file_name,
        file_size: item?.message?.file_size,
        context_id: item?.message?.context_id,
        reply: item?.message?.reply,
        message: {
          context: item?.message?.message?.context,
          messaging_product: item?.message?.message?.messaging_product,
          recipient_type: "individual",
          to: item?.message?.message?.to,
          type: item?.message?.message?.type,
          [messageType]: {
            link: "http://localhost:1337/public/media/" + item.url,
            caption: item?.message?.message[messageType]?.caption,
          },
        },
      };
      addMessageToQueue({ content: message });
    });
  };

  const getFileDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      if (input.length >= 1) {
        handleSendButton(input);
      } else {
        toast.warning("Enter your message");
      }
      event.preventDefault();
    }
    const content = inputFieldRef.current.innerText;
    if(content.startsWith('/')){
      if(!showQuickReplies)
        setShowQuickReplies(true);
    }else{
      if(showQuickReplies)
        setShowQuickReplies(false);
    }
  };
  
  return (
    <div className={`flex flex-col gap-px  ${
      isPrivate ? " bg-red-200" : "bg-gray-50"
    } bg-opacity-90 shadow-sm rounded-md overflow-hidden`}>
      {files.length >= 1 && !isPrivate && (
        <FooterMediaPreview
          onClose={() => setFile([])}
          files={files}
          onCaption={handleCaptionChange}
          onAdded={addMoreFileSelect}
          onRemove={removeFileSelect}
          captions={captions}
          onSend={handleSendButton}
        />
      )}

      {
        reply && 
        <div className=" flex items-center border-l-2 border-primary-color bg-[#ebebeb]">
          <div className=" flex-1 ml-3 my-1">
            <h4 className="text-sm text-app-black-color">Reply to {user.name}</h4>
            {
              reply?.message?.type === 'text' &&
              <p className="text-[12px] font-thin text-gray-600">{reply?.message?.text?.body}</p>
            }
            {
              reply?.message?.type === 'audio' &&
              <div className="flex gap-1 mt-1">
                <span className="material-symbols-outlined text-[18px] text-primary-color">mic</span>
                <p className="text-[12px] font-thin text-gray-600">Audio message</p>
              </div>
            }
            {
              reply?.message?.type === 'image' &&
              <div className="flex gap-1 mt-1">
                <span className="material-symbols-outlined text-[18px] text-primary-color">photo</span>
                <p className="text-[12px] font-thin text-gray-600">Image message</p>
              </div>
            }
            {
                 reply?.message?.type === 'document' &&
                 <div className="flex gap-1 mt-1 items-center">
                    <span className="material-symbols-outlined text-[18px] text-primary-color">draft</span>                   
                    <p className="text-[12px] font-thin text-gray-600">Document message</p>
                 </div>
            }
          </div>
          
          <div className="mr-2">
            <button onClick={onCancelReply} className="">
              <span className="material-symbols-outlined text-gray-400">
              close
              </span>
            </button>
          </div>
        </div>
      }

      {files.length === 0 && (
        <div className="flex gap-2 items-end px-1 py-[4px] text-gray-100">
          {!isPrivate && (
            <div className="flex items-end">
              <button onClick={() => setShowQuickReplies(!showQuickReplies)} className="flex items-center hover:bg-primary-color hover:text-white rounded-full p-[4px] mr-1">
                <span className="material-symbols-outlined text-[22px]">
                  bolt
                </span>
              </button>
              <div className="w-[1px] h-[18px] mb-[5px] bg-gray-100 rounded-full" />
            </div>
          )}

          <div className="flex-1 ml-[4px] overflow-x-hidden max-h-[150px]">
            <div
              contentEditable={true}
              ref={inputFieldRef}
              title={
                isPrivate
                  ? "Type your private message for teams or agents"
                  : "Your message"
              }
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              className={`text-md text-app-black-color py-[2px] px-[4px] focus:border-[1px] focus:border-none rounded-sm focus:outline-none empty:before:content-[attr(title)] empty:font-light ${
                isPrivate ? "empty:text-app-black-color" : "empty:text-gray-100"
              }`}
            />
          </div>

          {status === "idle" && (
            <div className="flex items-center gap-2">
              <button
                onClick={handlePrivate}
                className="flex items-center hover:bg-primary-color hover:text-white rounded-full p-[4px]"
              >
                <span
                  className={`material-symbols-outlined text-[22px] ${
                    isPrivate && " text-red-600"
                  }`}
                >
                  {isPrivate ? "lock_open_right" : "lock"}
                </span>
              </button>
              <button
                onClick={() => setEmoji(!showEmoji)}
                className="flex items-center hover:bg-primary-color hover:text-white rounded-full p-[4px]"
              >
                <span
                  className={`material-symbols-outlined text-[22px] ${
                    isPrivate && "text-red-600"
                  }`}
                >
                  mood
                </span>
              </button>

              {!isPrivate && (
                <button
                  onClick={() => setAttach(!showEmoji)}
                  className="flex items-center hover:bg-primary-color hover:text-white rounded-full p-[4px]"
                >
                  <span className="material-symbols-outlined text-[21px]">
                    attach_file
                  </span>
                </button>
              )}
            </div>
          )}

          <div className="flex items-center gap-3 h-[29px]">
            {status === "recording" && (
              <div className="flex items-center gap-3">
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
                <div className=" text-app-black-color" role="timer">
                  {durationFormat(duration)}
                </div>
              </div>
            )}
            {status === "recording" && (
                <button
                  onClick={() => handleStopRecording()}
                  className=" flex items-center justify-center hover:text-red-500"
                >
                  <span className="material-symbols-outlined  text-[22px]">
                    pause_circle
                  </span>
                </button>
            )}
            {status === "stopped" && (
              <RecordPlayer
                url={mediaBlobUrl}
                height={22}
                waveColor="#5C42F9"
                progressColor="#C9C5E2"
                close={() => clearBlobUrl()}
              />
            )}
          </div>

          {input.length >= 1 ||
          status === "recording" ||
          status === "stopped" ||
          files.length >= 1 ||
          isPrivate ? (
            <button
              onClick={handleSendButton}
              className={`flex items-center ${
                isPrivate && " bg-red-700"
              } bg-primary-color text-white rounded-md p-[4px]`}
            >
              <span className="material-symbols-outlined text-[22px]">
                send
              </span>
            </button>
          ) : (
            <button
              onClick={handleStartRecording}
              className={`flex items-center bg-primary-color text-white rounded-md p-[4px]`}
            >
              <span className="material-symbols-outlined text-[22px]">mic</span>
            </button>
          )}
          {/* popups */}
          {showEmoji && (
            <EmojiKeypad
              onDissmis={() => setEmoji(false)}
              element={
                <EmojiPicker
                  previewPosition="none"
                  searchPosition="sticky"
                  data={data}
                  onEmojiSelect={(e) => handleEmojiText(e.native)}
                />
              }
            />
          )}

          {showAttach && (
            <AttachFileModal
              onDissmis={() => setAttach(false)}
              onResult={handleFileSelect}
              onSelected={(index) => {
                index === 2 ? setShowContacts(true) : setShowContacts(false);
              }}
            />
          )}

          {showContact && (
            <SendContantModel
              onDissmis={() => setShowContacts(false)}
              onSend={() => setShowContacts(false)}
            />
          )}

          {/* quick replies */}
          {
            showQuickReplies && 
            <QuickRepliesDialog onSelectedReplie={(quick) =>{
              setInput(quick.message);
              inputFieldRef.current.innerText=quick.message;
              setShowQuickReplies(false);
              const range = document.createRange();
              const selection = window.getSelection();
              range.selectNodeContents(inputFieldRef.current);
              range.collapse(false); // Set to the end of the content
              selection.removeAllRanges();
              selection.addRange(range);
            }}/>
          }
        </div>
      )}
    </div>
  );
}

export default ChatFooter;
