import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ConnectWhatsApp from "./pages/connect-whatsapp";
import Layout from "./components/layout";
import Home from "./pages/home";
import BillWallets from "./pages/bill-wallets";
import Agents from "./pages/agent";
import Teams from "./pages/teams";
import Contacts from "./pages/contacts";
import PersonalProfile from "./pages/personal-profile";
import BusinessProfile from "./pages/business-profile";
import Automation from "./pages/automation";
import SubLayout from "./components/sub-layout";
import Queue from "./pages/queue";
import Chat from "./pages/chat";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MessageProvider } from "../src/hooks/message-queue/message-context";
import Apps from "./pages/apps";
import Templates from "./pages/templates";

function App() {
  return (
    <>
      <MessageProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Routes>
          <Route exact path="/login" element={<Login />}></Route>
        </Routes>
        <Routes>
          <Route exact path="/signup" element={<Signup />}></Route>
        </Routes>
        <Routes>
          <Route
            exact
            path="/connect-whatsapp"
            element={<ConnectWhatsApp />}
          ></Route>
        </Routes>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/bill-wallets" element={<BillWallets />} />
            <Route exact path="/agents" element={<Agents />} />
            <Route exact path="/teams" element={<Teams />} />
            <Route exact path="/contacts" element={<Contacts />} />
            <Route exact path="/templates" element={<Templates />} />
            <Route
              exact
              path="/personal-profile"
              element={<PersonalProfile />}
            />
            <Route
              exact
              path="/business-profile"
              element={<BusinessProfile />}
            />
            <Route exact path="/automation" element={<Automation />} />
            <Route exact path="/apps" element={<Apps />} />

          </Route>

          <Route exact path="/" element={<SubLayout />}>
            <Route exact path="/queue" element={<Queue />} />
            <Route exact path="/chat" element={<Chat />} />
          </Route>
        </Routes>
      </MessageProvider>
    </>
  );
}

export default App;
