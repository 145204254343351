import React from "react";

function HeaderButton({title, icon, selected, onClick}) {
  return (
    <>
      <div onClick={() => onClick(title)} className={`flex flex-col items-center justify-center cursor-pointer ${ selected === title && 'text-primary-color border-primary-color'} text-gray-200 border border-gray-100 rounded-md h-[64px] w-[64px]`}>
        <span className="material-symbols-outlined">{icon}</span>
        <p className="text-sm">{title}</p>
      </div>
    </>
  );
}

export default HeaderButton;
