export const languages = [
    { code: 'af', language: 'Afrikaans' },
    { code: 'sq', language: 'Albanian' },
    { code: 'ar', language: 'Arabic' },
    { code: 'az', language: 'Azerbaijani' },
    { code: 'bn', language: 'Bengali' },
    { code: 'bg', language: 'Bulgarian' },
    { code: 'ca', language: 'Catalan' },
    { code: 'zh_CN', language: 'Chinese (CHN)' },
    { code: 'zh_HK', language: 'Chinese (HKG)' },
    { code: 'zh_TW', language: 'Chinese (TAI)' },
    { code: 'hr', language: 'Croatian' },
    { code: 'cs', language: 'Czech' },
    { code: 'da', language: 'Danish' },
    { code: 'nl', language: 'Dutch' },
    { code: 'en', language: 'English' },
    { code: 'en_GB', language: 'English (UK)' },
    { code: 'en_US', language: 'English (US)' },
    { code: 'et', language: 'Estonian' },
    { code: 'fil', language: 'Filipino' },
    { code: 'fi', language: 'Finnish' },
    { code: 'fr', language: 'French' },
    { code: 'de', language: 'German' },
    { code: 'el', language: 'Greek' },
    { code: 'gu', language: 'Gujarati' },
    { code: 'ha', language: 'Hausa' },
    { code: 'he', language: 'Hebrew' },
    { code: 'hi', language: 'Hindi' },
    { code: 'hu', language: 'Hungarian' },
    { code: 'id', language: 'Indonesian' },
    { code: 'ga', language: 'Irish' },
    { code: 'it', language: 'Italian' },
    { code: 'ja', language: 'Japanese' },
    { code: 'kn', language: 'Kannada' },
    { code: 'kk', language: 'Kazakh' },
    { code: 'ko', language: 'Korean' },
    { code: 'lo', language: 'Lao' },
    { code: 'lv', language: 'Latvian' },
    { code: 'lt', language: 'Lithuanian' },
    { code: 'mk', language: 'Macedonian' },
    { code: 'ms', language: 'Malay' },
    { code: 'ml', language: 'Malayalam' },
    { code: 'mr', language: 'Marathi' },
    { code: 'nb', language: 'Norwegian' },
    { code: 'fa', language: 'Persian' },
    { code: 'pl', language: 'Polish' },
    { code: 'pt_BR', language: 'Portuguese (BR)' },
    { code: 'pt_PT', language: 'Portuguese (POR)' },
    { code: 'pa', language: 'Punjabi' },
    { code: 'ro', language: 'Romanian' },
    { code: 'ru', language: 'Russian' },
    { code: 'sr', language: 'Serbian' },
    { code: 'sk', language: 'Slovak' },
    { code: 'sl', language: 'Slovenian' },
    { code: 'es', language: 'Spanish' },
    { code: 'es_AR', language: 'Spanish (ARG)' },
    { code: 'es_ES', language: 'Spanish (SPA)' },
    { code: 'es_MX', language: 'Spanish (MEX)' },
    { code: 'sw', language: 'Swahili' },
    { code: 'sv', language: 'Swedish' },
    { code: 'ta', language: 'Tamil' },
    { code: 'te', language: 'Telugu' },
    { code: 'th', language: 'Thai' },
    { code: 'tr', language: 'Turkish' },
    { code: 'uk', language: 'Ukrainian' },
    { code: 'ur', language: 'Urdu' },
    { code: 'uz', language: 'Uzbek' },
    { code: 'vi', language: 'Vietnamese' },
    { code: 'zu', language: 'Zulu' }
  ];