import React, { createContext, useContext, useState } from "react";

const TemplateContext = createContext();

const TemplateProvider = ({ children }) => {
  const [buttons, setButton] = useState([]);

  const actionTypes = [
    "call_phone_number",
    "copy_offer_code",
    "dynamic_link",
    "static_link"
  ];

  const addButton = (button) => {
    const res = buttons.some((item) => item.type === button.type);
    if(res) return;
    setButton((prevButtons) => [...prevButtons, button]);
  }

  const replaceButton = (value, button) => {
    const updatedArray = buttons.map((obj) => {
      if (obj.type === button) {
        obj = value;
      }
      return obj;
    });
    setButton(updatedArray);

  }

  const updateButtonText = (value, button) => {
    const updatedArray = buttons.map((obj) => {
      if (obj.type === button) {
        obj.display_text = value;
      }
      return obj;
    });
    setButton(updatedArray)    
  }

  const removeButton = (value) => {
     const updatedButtons = buttons.filter((item) => item.type !== value);
     setButton(updatedButtons);
  }

  const removeAllButtons = () => {
    setButton([]);
  }

  return (
    <TemplateContext.Provider value={{ addButton, replaceButton, updateButtonText, removeButton, removeAllButtons, actionTypes, buttons }}>
       {children}
    </TemplateContext.Provider>
  );
};

const useTemplateContext = () => useContext(TemplateContext);

export { TemplateProvider, useTemplateContext };
