import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

function AccountButton(props) {
  const navigate = useNavigate();
  const [showOrganisation, setShowOrganisation] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showStatusMenu, setStatusMenu] = useState(false);
  const ref = useRef(null);
  const { onClickOutSide } = props;


  const dissmisSubMenus = () => {
    setShowOrganisation(false);
    setStatusMenu(false);
  }

  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
        dissmisSubMenus();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onClickOutSide]);

  return (
    <div ref={ref} className="hidden md:block sm:hidden lg:block cursor-pointer">
      <div onClick={()=>setShowMenu(true)} className="flex items-center gap-2 hover:bg-gray-light rounded-full h-[34px] pr-1 py-5 cursor-pointer">
        <div className="bg-gray-light rounded-full p-2 text-primary-color font-medium">
          QD
        </div>
        <div>qued.io</div>
        <span className="material-symbols-outlined">expand_more</span>
      </div>

      {
        showMenu && 
        <div role="dialog" className="flex gap-4 absolute items-end right-24 top-28 flex-row-reverse">
        <div className="card bg-gray-50 rounded-md shadow-lg shadow-slate-600">
          <div className="flex items-center gap-2 px-4">
            <div className=" bg-primary-light text-primary-color rounded-full font-medium p-1 h-[34px] w-[34px] flex items-center justify-center">
              <p>QD</p>
            </div>
            <div>
              <h3 className="pt-4">qued-dev</h3>
              <p className="text-gray-400 font-light text-[12px]">
                Organisation
              </p>
            </div>
          </div>
          <div className="my-2 flex gap-2 px-4 pt-2">
            <div className="relative">
              <div className="bg-white rounded-full font-medium p-2 h-[34px] w-[34px] flex items-center justify-center">
                <p>IA</p>
              </div>
              <div className="absolute bg-green-500 h-[8px] w-[8px] rounded-full top-0 right-1"></div>
            </div>
            <div className="mt-1">
              <div className=" font-normal text-[14px]">Ijas Alsma</div>
              <div className=" text-gray-400 font-light text-[12px]">
                zaroor.tech@gmail.com
              </div>
            </div>
          </div>
          <div className="h-px bg-gray-100 min-w-full"></div>
          <button onClick={()=>{dissmisSubMenus(); setShowOrganisation(true);}} className="flex justify-between min-w-full mt-4 px-4 text-sm hover:text-primary-color ">
            <span>Switch organisation</span>
            <span className="material-symbols-outlined">chevron_right</span>
          </button>
          <button onClick={()=>{dissmisSubMenus(); setStatusMenu(true)}} className="flex justify-between min-w-full my-4 px-4 text-sm hover:text-primary-color ">
            <span>Available</span>
            <span className="material-symbols-outlined">chevron_right</span>
          </button>
          <button onClick={()=> navigate('/login')} className="text-red-500 text-sm hover:text-primary-color px-4 pb-4">
            Sign out
          </button>
        </div>
        
        {
            showOrganisation &&  
            <div className="card bg-gray-50 rounded-md shadow-lg shadow-slate-600 h-fit">
            <div>
              <div onClick={()=>navigate('/account-name')} className="p-4 flex gap-2 items-center cursor-pointer hover:text-primary-color">
                <div className="bg-white p-px flex rounded-full">
                  <span className="material-symbols-outlined text-gray">add</span>
                </div>
                <div className="text-[14px]">Create new Organisation</div>
              </div>
              <hr></hr>
              <div className="p-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="rounded-full bg-primary-light">
                    <p className="text-primary-color font-medium p-2">QU</p>
                  </div>
                  <h3 className="text-[14px]">qued-dev</h3>              </div>
                <span className="material-symbols-outlined">done</span>
              </div>
              <hr></hr>
              <div className="p-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="rounded-full bg-primary-light">
                    <p className="text-primary-color font-medium p-2">QU</p>
                  </div>
                  <h3 className="text-[14px]">qued-team</h3>
                </div>
              </div>
            </div>
            </div>
        }
        {
            showStatusMenu &&
            <div className="card bg-gray-50 rounded-md shadow-lg shadow-slate-600">
                <ul className="p-4 text-[14px]">
                    <li onClick={()=> dissmisSubMenus()} className="py-1">
                        <div className="flex items-center gap-2">
                            <div className="h-3 w-3 bg-green-500 rounded-full"></div>
                            <div>Available</div>
                        </div>
                    </li>
                    <li onClick={()=> dissmisSubMenus()} className="py-1">
                        <div className="flex items-center gap-2">
                            <div className="h-3 w-3 bg-red-500 rounded-full"></div>
                            <div>Unvailable</div>
                        </div>
                    </li>
                </ul>
            </div>
        }
        </div>
      }
    </div>
  );
}

export default AccountButton;
