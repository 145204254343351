import React from 'react'
import { useNavigate } from "react-router-dom"
import './style.css'

function AppTile({title, label, desc, price, isActive, path}) {
  const navigate = useNavigate();
  return (
    <div onClick={()=> navigate(path)} className="flex flex-col gap-2 border border-gray-50 hover:bg-primary-color hover:text-white rounded-md py-2 px-2 cursor-pointer">
        <div className=" flex justify-between">
          <h2 className=" font-medium">{title}</h2>
          <p className="bg-gray-50 text-gray-100 px-2 rounded-md text-sm flex items-center">{label}</p>
        </div>
        <p className=" text-sm text-gray-200 font-light line-clamp-2">{desc}</p>
        <div className="flex justify-between text-sm py-2">
            <p className=" bg-[#E2DDFC] text-primary-color px-6 rounded-full">{price}</p>
            {
              !isActive && 
              <button className="text-[#FF0202]">Try now</button>
            }
        </div>
    </div>
  )
}

export default AppTile
