import React, { useEffect, useState } from "react";
import TabBar from "../../components/tab-bar";
import TemplateTable from "../../components/template-table";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { toast } from "react-toastify";
import Modal from "react-modal";
import CreateTemplates from "../../components/create-templates";

function Templates() {
  const [templates, setTemplates] = useState(null);
  const [loading, setLoading] = useState(false);
  const tabs = [
    {
      title: "Approved",
      attr: {
        count: templates?.approved.length,
      },
    },
    {
      title: "In review",
      attr: {
        count: templates?.in_review.length,
      },
    },
    {
      title: "Rejected",
      attr: {
        count: templates?.rejected.length,
      },
    },
  ];

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}quick_replies/templates`,
        method: "GET",
      });
      setLoading(false);
      if (response.status) {
        console.log(response.data);
        const approved = [];
        const inReview = [];
        const rejected = [];
        response.data.forEach((item) => {
          if (item.status === "APPROVED") {
            approved.push(item);
          } else if (item.status === "PENDING") {
            inReview.push(item);
          } else if (item.status === "REJECTED") {
            rejected.push(item);
          }
        });
        setTemplates({
          approved: approved,
          in_review: inReview,
          rejected: rejected,
        });
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  };

  return (
    <section>
      <div className="cursor-pointer max-h-[90vh] h-[90vh] overflow-x-scroll no-scrollbar">
        <div className="flex justify-between mt-[14px]">
          <div>
            <h2 className="font-semibold text-[22px]">Templates</h2>
            <div className="text-[14px] text-gray-100 mt-[2px]">
              Manage your WhatsApp templates here.
            </div>
          </div>
          <div className="mt-2">
            <button className=" bg-primary-color text-white px-2 py-1 rounded-md shadow-md text-sm">
              ADD NEW TEMPLATES
            </button>
          </div>
        </div>
        <hr className="border-gray-50 my-2" />
        <div className="py-2 flex justify-between gap-2 mx-[1px]">
          { loading &&
            <div className="flex items-center justify-center w-full">
                <div className="loading-circle"/>
            </div>
          }
          {!loading && (
            <TabBar tabs={tabs}>
              <TemplateTable data={templates?.approved} />
              <TemplateTable data={templates?.in_review} />
            </TabBar>
          )}
        </div>


        <Modal
        isOpen={true}
        onAfterClose={() => {
        }}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)', // Center the modal
            backgroundColor: "white",
            padding: "0",
            height: "95vh",
            width: "65%",
            overflowY: "hidden"
          },
          overlay: {
            background: "#00000055",
            zIndex: 30,
          },
        }}
      >
        <CreateTemplates/>
      </Modal>
      </div>
    </section>
  );
}

export default Templates;
