import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import CircleAvatar from "../circle_avatar";
import { toast } from "react-toastify";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";

function MembersOptionSelection({ values, onChange }) {
  const [selected, setSelected] = useState([]);

  const handleChange = (option) => {
    setSelected(option);
    onChange && onChange(option);
  };

  useEffect(() => {
    if(values){
      const inital = values.map((item) => ({value: item._id, label: item.name}));
      setSelected(inital);
      onChange && onChange(inital);
    }
  }, []);

  const Option = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <article
        ref={innerRef}
        {...innerProps}
        className="px-4 py-1 flex items-center gap-2 hover:bg-gray-50 cursor-pointer"
      >
        <CircleAvatar name={props.data.label} size="w-8 h-8" />
        <h4 className=" text-sm">{props.data.label}</h4>
      </article>
    );
  };

  const MultiValueLabel = props => {
    return (
        <components.MultiValueLabel {...props}>
         <div className="flex items-center gap-2">
            <CircleAvatar name={props.data.label} size={'w-6 h-6'} />
            <div>{props.data.label}</div>
          </div>
        </components.MultiValueLabel>
    );
  };

  const MultiValueRemove = props => {
    return (
        <components.MultiValueRemove {...props}/>
    );
  };

  const fetchAgents = async (inputValue, callback) => {
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}agent?limit=6&offset=0&query=${inputValue}&status=&team=`,
        method: "GET",
      });
      if (response.status) {
        if (response.data) {
          const options = response.data[0].data.map((item) => ({value: item._id, label: item.name}));
          if(inputValue === ''){
            setTimeout(() => {
              callback(options);
            }, 1000);
          }else{
            callback(options);
          }
       
        } else {
          callback([]);
        }
      } else {
        callback([]);
        toast.error(response.message);
      }
    } catch (err) {
      callback([]);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured while fetch");
      }
    }
  };
  
  return (
    <AsyncSelect
      cacheOptions 
      defaultOptions 
      loadOptions={fetchAgents}
      isMulti
      isClearable={false}
      value={selected}
      placeholder="Select members"
      opt
      onChange={handleChange}
      components={{ Option, MultiValueRemove, MultiValueLabel, IndicatorSeparator: () => null}}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: "#f5f5f5",
          borderRadius: "6px",
          paddingLeft: "2px",
          ":hover": { borderColor: "#f5f5f5", outline: "none" },
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          fontSize: "14px",
          fontWeight: "lighter",
          color: "#c2c2c2",
        }),
        multiValue: (styles, { data }) => ({
          display: "flex",
          fontFamily: "Poppins",
          color: "black",
          backgroundColor: "#f5f5f5",
          borderRadius: "20px",
          paddingLeft: "10px",
          paddingRight: "6px",
          paddingTop: "3px",
          paddingBottom: "3px",
          alignItems: "center",
          justifyContent: "center",
          margin: "3px",
        }),
        multiValueLabel: (styles, { data }) => ({
          color: "#1F1F1F",
          fontSize: "14px",
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          borderRadius: "40px",
          marginLeft: "8px",
          height: "20px",
          ":hover": {
            backgroundColor: "#5C42F9",
            color: "white",
          },
        }),
      }}
    />
  );
}
export default MembersOptionSelection;
