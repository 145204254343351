import React, { useEffect, useState } from "react";
import CircleAvatar from "../circle_avatar";
import ChatUserTile from "../chat-user-tile";
import {useDispatch, useSelector } from "react-redux";
import ChatViewAsPopup from "../chat-view-as-popup";
import ExpandTile from "../expand-tile";
import { fetchChats, pinnChat, unpinChat } from "../../redux/chat/chatSlice";

function ChatUsers({onSelectedChat, selectedChat, pinned, unpinned, changedViewAs}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showViewAs, setViewAs] = useState(false);
  const [selectedViewAsUser, setViewAsUser] = useState(null);

  useEffect(() => {
  }, [selectedViewAsUser]);

  const handleViewAsButton = () => {
    setViewAs(true);
  };

  const handleViewAsChange = (data) => {
    setViewAsUser(data);
    changedViewAs();
    setViewAs(false);
    dispatch(fetchChats({ userId: data._id, query: ''}));
  };

  const handleSearchInput = (event) => {
     dispatch(fetchChats({ userId: selectedViewAsUser._id, query: event.target.value}));
  };

  const handlePinChat = (data) =>{
    dispatch(pinnChat(data));
  }

  const handleUnPinChat = (data) =>{
    dispatch(unpinChat(data));
  }


  return (
    <div className="min-w-[360px] h-screen relative">
      {user.role === "Owner" && (
        <div className="flex flex-col px-2 py-1 gap-1">
          <label className="text-sm text-gray-100">VIEW AS</label>
          {
            <button
              onClick={handleViewAsButton}
              className={`flex items-center gap-2 rounded-full border-[1px] border-gray-50 w-fit px-1 hover:bg-gray-50 ${
                showViewAs && "bg-gray-50"
              }`}
            >
              <CircleAvatar
                profileImage={
                  selectedViewAsUser
                    ? selectedViewAsUser.profileImage
                    : user.profileImage
                }
                name={selectedViewAsUser ? selectedViewAsUser.name : user.name}
                size={"w-6 h-6"}
              />
              <div className="flex items-center gap-2">
                <p className="text-sm">
                  {selectedViewAsUser ? selectedViewAsUser.name : user.name}
                </p>
                <span className="material-symbols-outlined text-sm">
                  expand_more
                </span>
              </div>
            </button>
          }
        </div>
      )}

      {showViewAs && (
        <ChatViewAsPopup
          onDissmis={() => setViewAs(!showViewAs)}
          onSelected={handleViewAsChange}
        />
      )}

      <div className="flex items-center justify-center gap-4 px-2 py-[8.5px] border-b border-gray-50">
        <div className="flex-1 relative">
          <span className="absolute z-10 top-[6px] left-1 text-gray-300 text-[22px] material-symbols-outlined">
            search
          </span>
          <input
            className="px-8 py-[4px] w-full placeholder:text-[14px] placeholder:font-light rounded-sm  outline outline-[1px] outline-gray-50 focus:outline-primary-color "
            placeholder="Search customer name"
            name="search"
            onChange={handleSearchInput}
          ></input>
        </div>
        <div>
          <button className="flex items-center border-gray-50 border rounded-sm p-1 hover:bg-primary-color hover:text-white hover:border-primary-color">
            <span className="material-symbols-outlined">chat_add_on</span>{" "}
          </button>
        </div>
      </div>

      <div className="overflow-y-scroll h-[100%] pb-[200px] no-scrollbar">
        {
          pinned.length >= 1 && 
          <div className="text-gray-100 font-light text-sm pt-2">
          <ExpandTile
            defaultValue={true}
            label={
              <label className="flex gap-1 text-sm items-center px-2">
                <span className="material-symbols-outlined">
                  label_important
                </span>
                PINNED
              </label>
            }
            element={
              <div className="my-1">
                <ul>
                  {pinned && pinned.map((user, index) => (
                    <ChatUserTile key={index} active={user?._id === selectedChat?._id} data={user} onSelectedChat={onSelectedChat} onClickPin={handleUnPinChat}/>
                  ))}
                </ul>
              </div>
            }
          />
        </div>
        }

        {
          unpinned.length >= 1 && 
          <div className="text-gray-100 font-light text-sm  pt-2">
          <ExpandTile
            defaultValue={true}
            label={
              <label className="flex gap-1 text-sm items-center px-2">
                <span className="material-symbols-outlined">history</span>
                RECENT
              </label>
            }
            element={
              <div className="my-1">
                <ul>
                  { unpinned && unpinned.map((user, index) => (
                    <ChatUserTile key={index} active={user?._id === selectedChat?._id} data={user} onSelectedChat={onSelectedChat} onClickPin={handlePinChat}/>
                  ))}
                </ul>
              </div>
            }
          />
        </div>
        }
      </div>
    </div>
  );
}

export default ChatUsers;
