import { useState, useEffect } from 'react'
import WaveSurfer from 'https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js'

export const useWavesurfer = (containerRef, options) => {
    const [wavesurfer, setWavesurfer] = useState(null)
  
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    useEffect(() => {
      if (!containerRef.current) return
  
      const props = {
        ...options,
        barWidth: 3,
        barRadius: 25,
        barHeight: 3,
        cursorColor: '#57BAB6',
        cursorWidth: 0,
        container: containerRef.current,
      };
      const ws = WaveSurfer.create(props)
      setWavesurfer(ws)
  
      return () => {
        ws.destroy()
      }
    }, [options, containerRef])
  
    return wavesurfer
  }


