import React from 'react'

function TempleteMessageTile() {
  return (
    <div className="m-2 px-3 pt-3 bg-gray-50 rounded-md">
        <div className="h-[150px] rounded-md bg-[#eeeeee] flex items-center justify-center relative">
            <span className="material-symbols-outlined">image</span>
            <p className=" absolute bottom-1 left-1 text-primary-color text-sm">{'{{1}}'}</p>
        </div>
        <div className="my-3 text-app-black-color text-sm">
            <h3 className="">Hi<span className='text-primary-color'>{'{{2}}'}</span>,</h3>
            <p className="">
                Your order <span className='text-primary-color'>{'{{3}}'} </span>
                has been shipped.
            </p>
            <br></br>
            <p className="">
                Track your order progress at <span className="text-primary-color">{'{{4}}'}</span>
            </p>
            <p className="text-[12px] text-gray-200 pb-2">
                Powered by <span className="text-primary-color">qued.io</span>
            </p>
        </div>
    </div>
  )
}

export default TempleteMessageTile
