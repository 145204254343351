import React from 'react'

function BillWallets() {
  return (
    <div className='mt-[14px]'>
      <h1>Bill and wallets</h1>
    </div>
  )
}

export default BillWallets
