import React, { useEffect, useRef } from "react";
import CheckBox from "../check-box";

function TagPopup({ onSelected, onDismiss }) {
  const popRef = useRef(null);

  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (popRef.current && !popRef.current.contains(event.target)) {
        onDismiss && onDismiss();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onDismiss]);

  return (
    <div
      ref={popRef}
      className=" absolute top-[10px] bg-gray-50  rounded-md shadow-md w-[100%] z-10 overflow-hidden"
    >
      <div className="px-4 pt-4 pb-2">
        <span className="material-symbols-outlined absolute z-20 top-[22px] left-[20px] text-[18px] text-gray-100">
          search
        </span>
        <input
          className="relative placeholder:text-sm px-[26px] py-[2px] w-full bg-gray-50 outline outline-gray-100 outline-[1px] rounded-sm focus:outline-primary-color"
          placeholder="Search tag name"
        ></input>
      </div>
      <div className="px-[3px]  max-h-[180px] overflow-y-scroll">
        <ul>
          <li>
            <div className="flex items-center ">
              <CheckBox id="checkbox" size={"w-[14px] h-[14px]"} />
              <span className="material-symbols-outlined text-sm text-lime-600 mr-2">
                label
              </span>
              <label
                htmlFor="checkbox"
                className="select-none text-[14px] text-app-black-color"
              >
                Premium
              </label>
            </div>
          </li>
          <li>
            <div className="flex items-center ">
              <CheckBox id="checkbox" size={"w-[14px] h-[14px]"} />
              <span className="material-symbols-outlined text-sm text-red-600 mr-2">
                label
              </span>
              <label
                htmlFor="checkbox"
                className="select-none text-[14px] text-app-black-color"
              >
                Return
              </label>
            </div>
          </li>
          <li>
            <div className="flex items-center ">
              <CheckBox id="checkbox" size={"w-[14px] h-[14px]"} />
              <span className="material-symbols-outlined text-sm text-blue-600 mr-2">
                label
              </span>
              <label
                htmlFor="checkbox"
                className="select-none text-[14px] text-app-black-color"
              >
                Re-Order
              </label>
            </div>
          </li>

          <li>
            <div className="flex items-center ">
              <CheckBox id="checkbox" size={"w-[14px] h-[14px]"} />
              <span className="material-symbols-outlined text-sm text-blue-600 mr-2">
                label
              </span>
              <label
                htmlFor="checkbox"
                className="select-none text-[14px] text-app-black-color"
              >
                Re-Order
              </label>
            </div>
          </li>

          <li>
            <div className="flex items-center ">
              <CheckBox id="checkbox" size={"w-[14px] h-[14px]"} />
              <span className="material-symbols-outlined text-sm text-blue-600 mr-2">
                label
              </span>
              <label
                htmlFor="checkbox"
                className="select-none text-[14px] text-app-black-color"
              >
                Re-Order
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TagPopup;
