import React, { useEffect } from "react";
import ActionButtonCard from "../action-button-card";
import { useTemplateContext } from "../../../providers/template-provider/template_provider";

function ActionButtons({ control, watch }) {

  const { addButton, actionTypes, buttons }  = useTemplateContext();

  const handleAddMore = () => {
    for (const value of actionTypes) {
      let matchFound = false;
      for (const btn of buttons) {
        if (btn.type === value) {
          matchFound = true;
          break; 
        }
      }
      
      if (!matchFound) {
        addButton({ type: value }); 
        break;
      }
    }

  }

  useEffect(() => {
    addButton({type: 'call_phone_number'});
  }, [])

  return (
    <div className="">
      <ul>
       {
        buttons.map((item, index) => 
           <ActionButtonCard key={index} count={index} actionType={item.type} control={control} watch={watch} />
        )
       }
      </ul>
      {
        buttons.length < 3 &&
        <button onClick={handleAddMore} className="flex items-center gap-1 text-sm my-2">
            <span className="material-symbols-outlined text-primary-color">add_circle</span>
            Add button
        </button>
      }
    </div>
  );
}

export default ActionButtons;
