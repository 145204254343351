import React from "react";
import { NavLink } from "react-router-dom";

function SideNavBar() {
  return (
    <div className="py-5 mx-4">
      <div>
        <label className="text-gray-100 font-thin text-sm">DASHBOARD</label>
        <div className="mt-1">
          <ul className="flex flex-col gap-1 text-[16px]">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/"
              >
                <span className="material-symbols-outlined">overview</span>
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/bill-wallets"
              >
                <span className="material-symbols-outlined">receipt_long</span>
                Billing & Wallets
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/report"
              >
                <span className="material-symbols-outlined">analytics</span>
                Reports
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-2">
        <label className="text-gray-100 font-thin text-sm">TEAM SETTINGS</label>
        <div className="mt-1">
          <ul className="flex flex-col gap-1 text-[16px]">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/agents"
              >
                <span className="material-symbols-outlined">support_agent</span>
                Agent
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/teams"
              >
                <span className="material-symbols-outlined">groups_3</span>Teams
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/contacts"
              >
                <span className="material-symbols-outlined">contacts</span>
                Contacts
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-2">
        <label className="text-gray-100 font-thin text-sm">
          PERSONAL SETTINGS
        </label>
        <div className="mt-1">
          <ul className="flex flex-col gap-1 text-[16px]">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/personal-profile"
              >
                <span className="material-symbols-outlined">
                  manage_accounts
                </span>
                Personal Profile
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-2">
        <label className="text-gray-100 font-thin text-sm">
          BUSINESS SETTINGS
        </label>
        <div className="mt-1">
          <ul className="flex flex-col gap-1 text-[16px]">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/business-profile"
              >
                <span className="material-symbols-outlined">storefront</span>
                Business Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/automation"
              >
                <span className="material-symbols-outlined">schedule</span>{" "}
                Automation
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-2">
        <label className="text-gray-100 font-thin text-sm">DEV SETTINGS</label>
        <div className="mt-1">
          <ul className="flex flex-col gap-1 text-[16px]">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/"
              >
                <span className="material-symbols-outlined">
                  integration_instructions
                </span>
                Integration
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/"
              >
                <span className="material-symbols-outlined">key</span>
                Credential
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-50 flex gap-2 rounded-md px-2 py-2 hover:text-primary-color"
                    : "flex gap-2 px-2 py-2 hover:text-primary-color hover:bg-gray-50 hover:rounded-md"
                }
                to="/"
              >
                <span className="material-symbols-outlined">webhook</span>
                Webhook
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
