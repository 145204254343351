import React, { useState, useEffect } from "react";
import { validImageTypes, validVideoTypes } from '../../../utils/media-support-types';

function MediaItem({file, onRemove , isSelected, onSelected}) {
    const [media, setMedia] = useState();

    const readFile = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMedia(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    };
  
    useEffect(readFile, [file]);
  
    return (
      <div onClick={onSelected} className={`relative border-2 ${isSelected ? 'border-primary-color' : ''} rounded-sm`}>
        {validImageTypes.includes(file.type) && (
          <img
            className="w-[52px] h-[52px] object-cover"
            src={media}
            alt="file-"
          />
        )}
        {validVideoTypes.includes(file.type) && (
          <video src={media} className="w-[52px] h-[52px]" controls={false} />
        )}
  
        {!validImageTypes.includes(file.type) &&
          !validVideoTypes.includes(file.type) && (
            <div className="w-[52px] h-[52px] flex items-center justify-center">
              <span className="material-symbols-outlined text-[34px] text-primary-color">file_present</span>
            </div>
          )}
  
        <button
          onClick={()=>onRemove(file)}
          className="flex items-center absolute top-0 right-0 shadow-md bg-white bg-opacity-30 hover:bg-opacity-90 rounded-full"
        >
          <span className="material-symbols-outlined text-md">close</span>
        </button>
      </div>
    );
  }

export default MediaItem
