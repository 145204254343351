export const validVideoTypes = ["video/mp4", "video/3gp"];
export const validImageTypes = ["image/jpeg", "image/png"];
export const validAudioTypes = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr", "audio/ogg", "audio/mp3"]
export const validDocTypes = [
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const allMediaSupport = [
  ...validVideoTypes,
  ...validImageTypes,
  ...validDocTypes,
  ...validAudioTypes,
];
