import React, { useState } from "react";

function ExpandTile({ label, element , defaultValue }) {
  const [active, setActive] = useState(defaultValue ? defaultValue : false);
  return (
    <div className="mb-2">
      <button onClick={()=>setActive(!active)} className="flex justify-between w-full hover:text-primary-color text-gray-100">
        <div className=" text-[14px]">{label}</div>
        <div>
          <span className={`material-symbols-outlined transform duration-500 ${active ? 'rotate-180' : 'rotate-0'}`}>expand_more</span>
        </div>
      </button>
      <div className={`transition-all duration-500 ${active ? ' h-max' : 'h-0'}`}>
        {
            active && element
        }
      </div>
    </div>
  );
}

export default ExpandTile;
