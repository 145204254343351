import React, { useState } from "react";
import CustomerTag from "../customer-tag";
import TagPopup from "../tag-popup";

function CustomerTagContainer() {
  const [tagShow, setTagShow] = useState(false);
  return (
    <div className="flex flex-wrap mb-5 mt-1 gap-2">
      <CustomerTag
        label="Premium"
        color="text-[#65a30d] bg-[#ecfccb]"
        dividerColor={"bg-[#bef264]"}
        remove={true}
      />
      <CustomerTag
        label="Return"
        color="text-[#dc2626] bg-[#fee2e2]"
        dividerColor={"bg-[#fca5a5]"}
        remove={true}
      />
      <CustomerTag
        label="Re-Order"
        color="text-[#2563eb] bg-[#dbeafe]"
        dividerColor={"bg-[#93c5fd]"}
        remove={true}
      />
        <button
          onClick={() => setTagShow(!tagShow)}
          className=" bg-gray-50 text-sm text-gray-400 px-1 rounded-sm flex items-center gap-1"
        >
          <span class="material-symbols-outlined text-[14px]">add</span>
          ADD
        </button>
      {tagShow && <TagPopup onDismiss={() => setTagShow(false)} />}
    </div>
  );
}

export default CustomerTagContainer;
