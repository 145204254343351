import React, { useState } from 'react';

function TabBar({ tabs, children }) {
  const [activeTab, setActiveTab] = useState(0);
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="tabs w-full">
      <div className="border-b border-gray-50 flex flex-row">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`flex gap-2 px-4 py-2 text-sm ${index === activeTab ? 'text-primary-color border-b-2 border-primary-color' : 'text-gray-500'}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
            {
              tab.attr && <p className=" bg-gray-50 rounded-full text-gray-200 min-w-[20px] text-sm flex items-center justify-center">{tab.attr.count}</p>
            }
          </button>
        ))}
      </div>
      <div>
        {childrenArray[activeTab]}
      </div>
    </div>
  )
}

export default TabBar
