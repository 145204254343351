import React, { useRef, useEffect } from "react";
import {validImageTypes, validVideoTypes, allMediaSupport, validAudioTypes} from "../../utils/media-support-types";


function AttachFileModal({onDissmis, onResult, onSelected}) {
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onDissmis();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onDissmis]);

  const handleFileChange = event => {
    const files = event.target.files;
    onResult(files);
    onDissmis();
  }

  return (
    <div ref={ref} className="absolute right-[6%] bottom-11 bg-white py-3 min-w-[194px] text-app-black-color rounded-md shadow-lg text-md cursor-pointer">
      <ul>
        <li className="px-3 py-2 hover:bg-gray-50 hover:text-primary-color">
          <div className="">
            <input
              id="photo-pic"
              type="file"
              accept={[...validImageTypes, ...validVideoTypes, ...validAudioTypes]}
              className=" hidden"
              onChange={handleFileChange}
              multiple
            />
            <label
              htmlFor="photo-pic"
              className="flex items-center gap-3 cursor-pointer"
            >
              <span className="material-symbols-outlined">photo_library</span>
              <div>Photos & Video</div>
            </label>
          </div>
        </li>

        <li onClick={()=>onSelected(1)} className="px-3 py-2 hover:bg-gray-50 hover:text-primary-color">
          <div className="flex items-center gap-3">
            <span className="material-symbols-outlined">photo_camera</span>
            <div>Camera</div>
          </div>
        </li>

        <li onClick={()=>{onSelected(2); onDissmis();}} className="px-3 py-2 hover:bg-gray-50 hover:text-primary-color">
          <div className="flex items-center gap-3">
            <span className="material-symbols-outlined">contacts</span>
            <div>Contact</div>
          </div>
        </li>

        <li className="px-3 py-2 hover:bg-gray-50 hover:text-primary-color">
          <div className="">
            <input
              id="doc-pic"
              type="file"
              accept={allMediaSupport}
              className=" hidden"
              onChange={handleFileChange}
              multiple
            />
            <label
              htmlFor="doc-pic"
              className="flex items-center gap-3 cursor-pointer"
            >
              <span className="material-symbols-outlined">description</span>
              <div>Document</div>
            </label>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default AttachFileModal;
