import React, { useState, useEffect } from "react";
import CheckBox from "../../components/check-box";
import QueueTableRow from "./table-row";
import Pagination from "../../components/pagination";
import { ReactComponent as MessageBubbleImage } from "../../assets/icons/message-bubble.svg";
import AgentsTabs from "./agents";
import { toast } from "react-toastify";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { ShimmerTable } from "react-shimmer-effects";

function Queue() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setPage] = useState(0);
  const [agentData, setAgentData] = useState([]);

  const handleRowClick = (rowData, ctrlKey, shiftKey) => {
    if (ctrlKey) {
      // Ctrl key + click: Toggle the selection of the clicked row
      setSelectedRows((prevSelected) =>
        prevSelected.includes(rowData)
          ? prevSelected.filter((row) => row !== rowData)
          : [...prevSelected, rowData]
      );
    } else if (shiftKey && selectedRows.length > 0) {
      // Shift key + click: Select a range of rows from the last selected row
      const lastSelectedIndex = data.findIndex(
        (row) => row === selectedRows[selectedRows.length - 1]
      );
      const clickedIndex = data.findIndex((row) => row === rowData);
      const startIndex = Math.min(lastSelectedIndex, clickedIndex);
      const endIndex = Math.max(lastSelectedIndex, clickedIndex);
      const selectedRange = data.slice(startIndex, endIndex + 1);
      setSelectedRows(selectedRange);
    } else {
      // Single click: Select only the clicked row
      setSelectedRows([rowData]);
    }
  };

  const handleAllSelection = (event) => {
    if (event.target.checked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSearchChange = (event) => {
    fetchAgentTeam(event.target.value);
  };

  const handleAssigedSuccess = () => {
     fetchQueue();
     setSelectedRows([]);
  }

  useEffect(() => {
    fetchQueue();
    fetchAgentTeam('');
  }, [currentPage]);

  const fetchQueue = async () => {
    setLoading(true);
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}message/queue?limit=10&offset=${
          10 * currentPage
        }`,
        method: "GET",
      });
      if (response.status) {
        if (response.data) {
          setData(response.data[0].data);
          setDataCount(response.data[0].metadata[0].count);
        } else {
          setData(null);
          setDataCount(0);
        }
      } else {
        setData([]);
        setDataCount(0);
        toast.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else if(err.status === 404) {
        toast.warning("Data is empty");
      } else {
        toast.error(err.info.message);
      }
    }
  };

  const handlePagination = (page) => {
    setPage(page - 1);
  };

  const fetchAgentTeam = async (input) => {
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}business/teams_agents?query=${input}`,
        method: "GET",
      });
      if (response.status) {
        setAgentData(response.data);
      } else {
        setAgentData(null);
        toast.error(response.message);
      }
    } catch (err) {
      setAgentData(null);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  };

  return (
    <section className="cursor-pointer">
      <div className="flex h-screen mt-2">
        <div className="w-screen relative">
          {!loading && (
            <div>
              <table className="w-[100%]">
                <thead className=" text-sm font-light border-b border-gray-50">
                  <tr className="">
                    <th className="font-medium text-gray-500 h-10 text-left pl-3">
                      <CheckBox
                        active={selectedRows.length === data?.length}
                        onChange={handleAllSelection}
                      />
                    </th>
                    <th className=" font-medium text-gray-500 h-10 text-left">
                      <div>CUSTOMER</div>
                    </th>
                    <th className=" font-medium text-gray-500 h-10 text-left">
                      <div>LAST MESSAGE</div>
                    </th>
                    <th className=" font-medium text-gray-500 h-10 text-left">
                      <div>DATE TIME</div>
                    </th>
                    <th className=" font-medium text-gray-500 h-10 text-left">
                      <div></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((que) => (
                    <QueueTableRow
                      key={que._id}
                      data={que}
                      onClick={handleRowClick}
                      isSelected={selectedRows.includes(que)}
                      onAssigned={handleAssigedSuccess}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5">
                      <Pagination
                        items={dataCount}
                        onChange={handlePagination}
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
          {loading && (
            <div className="p-1">
              <ShimmerTable row={8} col={4} />
            </div>
          )}

          {!loading && (
            <div className="absolute flex justify-between left-2 right-2 bottom-20 w-[98%] text-sm text-gray-400 bg-gray-100 bg-opacity-20 p-4 rounded-md">
              <p className=" ">
                {selectedRows.length}/{dataCount} Messages selected
              </p>
              <div className="flex items-center gap-2">
                <p>Multiple select</p>
                  <span className=" bg-gray-100 bg-opacity-30 px-2 rounded-sm">
                    Shift
                  </span>
                
                <span>+</span>
                  <span className="bg-gray-100 bg-opacity-30 px-2 rounded-sm">
                    CLICK
                  </span>
                
              </div>
              <div className="flex items-center gap-2">
                <p>Single select</p>
                  <span className=" bg-gray-100 bg-opacity-30 px-2 rounded-sm">
                    Ctrl / command
                  </span>
                
                <span>+</span>
                  <span className="bg-gray-100 bg-opacity-30 px-2 rounded-sm">
                    CLICK
                  </span>
                
              </div>
            </div>
          )}
        </div>

        <div className=" hidden md:block min-w-[390px] border-l-[1px] border-gray-50">
          {selectedRows.length <= 0 ? (
            <div className="flex flex-col items-center justify-center justify-items-center h-full">
              <div className=" flex flex-col items-center gap-6 mb-40">
                <MessageBubbleImage className=" h-[128px] w-[128px]" />
                <div className="flex flex-col items-center gap-2">
                  <h3 className=" font-light text-lg text-app-black-color">
                    No chat selected
                  </h3>
                  <p className=" text-gray-100 font-thin text-sm">
                    Select a chat to assign the conversation
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-2 h-full">
              <h3 className="px-4 text-gray-100 text-sm font-medium">
                Assign to
              </h3>
              <div className="px-4 my-2 relative">
                <span className=" absolute top-[5px] px-1 material-symbols-outlined text-gray-100">
                  search
                </span>
                <input
                  onChange={handleSearchChange}
                  className="px-8 w-full py-[5px] placeholder:text-sm placeholder:font-light rounded-sm outline outline-gray-50 focus:outline-primary-color "
                  placeholder="Search agent name or team"
                ></input>
              </div>
              <AgentsTabs data={agentData} selectedRows={selectedRows} onAssigned={handleAssigedSuccess} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Queue;
