import React from "react";

function PreviewButton({ button }) {
  switch (button.type) {
    case "call_phone_number":
      return (
        <div className="border-t">
          <div className="flex items-center justify-center gap-2 text-sm text-primary-color my-2">
            <span className="material-symbols-outlined text-[18px] text-primary-color">
              call
            </span>
            { button?.display_text }
          </div>
        </div>
      );
    case "copy_offer_code":
      return (
        <div className="border-t">
          <div className="flex items-center justify-center gap-2 text-sm text-primary-color my-2">
            <span className="material-symbols-outlined text-[18px] text-primary-color">
              content_copy
            </span>
            Copy offer code
          </div>
        </div>
      );
    case "dynamic_link":
      return (
        <div className="border-t">
          <div className="flex items-center justify-center gap-2 text-sm text-primary-color my-2">
            <span className="material-symbols-outlined text-[18px] text-primary-color">
              open_in_new
            </span>
            { button?.display_text }
          </div>
        </div>
      );
    case "static_link":
      return (
        <div className="border-t">
          <div className="flex items-center justify-center gap-2 text-sm text-primary-color my-2">
            <span className="material-symbols-outlined text-[18px] text-primary-color">
              open_in_new
            </span>
            { button?.display_text }
          </div>
        </div>
      );
    default:
      return <div className="">`button` doesn't match</div>;
  }
}

export default PreviewButton;
