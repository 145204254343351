import React from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-pen.svg";
import ToggleSwitch from "../../../components/toggle-switch";
import CircleAvatar from "../../../components/circle_avatar";
import { toast } from "react-toastify";
import Config from "../../../config/config";
import { fetcher } from "../../../hooks/fetcher/useFetch";

function TableRow({ data, onEditClick }) {
  const updateAgentStatus = async (id, status) => {
    const body = JSON.stringify({
      id: id,
      status: status,
    });
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}business/agent/update`,
        method: "POST",
        body: body,
      });
      if (!response.status) {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else if (err.status === 400) {
        toast.error("Validation error");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleToggle = (toggle) => {
    updateAgentStatus(data._id, toggle);
  };

  return (
    <>
      <tr className="text-app-black-color text-sm hover:bg-gray-50">
        <td>
          <div className="flex items-center gap-2">
            <CircleAvatar
              name={data?.name}
              profileImage={data.profileImage}
              size="w-[48px] h-[48px]"
            />
            <div>{data.name}</div>
          </div>
        </td>
        <td className="px-4">
          <div>{data.email}</div>
        </td>
        <td className="px-4">
          <div className="flex gap-2">
            {data.teams.length > 2 ? (
              <div className="flex gap-2">
                {data.teams.slice(0, 2).map((team, key) => (
                  <p
                    key={key}
                    className="bg-purple-100 text-primary-color max-w-[80px] px-2 py-px rounded-md text-ellipsis overflow-hidden whitespace-nowrap"
                  >
                    {team.name}
                  </p>
                ))}
                <p className="bg-purple-100 text-primary-color max-w-[80px] px-2 py-px rounded-md text-ellipsis overflow-hidden whitespace-nowrap">
                  {`+${data.teams.length - 2}`}
                </p>
              </div>
            ) : (
              data.teams.map((team, key) => (
                <p
                  key={key}
                  className="bg-purple-100 text-primary-color max-w-[80px] px-2 py-px rounded-md text-ellipsis overflow-hidden whitespace-nowrap"
                >
                  {team.name}
                </p>
              ))
            )}
          </div>
        </td>
        <td className="px-4">
          <div>{data.role}</div>
        </td>
        <td className="px-4">
          <div className="container mx-auto p-4">
            <ToggleSwitch active={data.status} onChange={handleToggle} />
          </div>
        </td>
        <td>
          <div className=" text-center">
            <button
              onClick={() => onEditClick(data)}
              className=" hover:bg-primary-light rounded-full p-2"
            >
              <EditIcon className="w-[18px] h-[18px]" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}

export default TableRow;
