import React, { useRef, useState, useEffect } from "react";
import WaveSurfer from "https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js";
import IconPlay from "../../../assets/icons/ic_play.svg";
import IconPause from "../../../assets/icons/ic_pause.svg";
import IconPlayWhite from "../../../assets/icons/ic_play_white.svg";
import IconPauseWhite from "../../../assets/icons/ic_pause_white.svg";
import { chatTimeFormat } from "../../../utils/formater";

function VoiceBubble({ msg, onReplyPressed, markAsSeen }) {
  const isUser = msg?.message?.to ? true : false;
  const wavesurferRef = useRef(null);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [state, setState] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const createdAt = msg?.createdAt;
  const context = msg?.message?.context;
  let reply = null;
  if(Array.isArray(msg?.reply)){
   reply = msg?.reply[0]?.message;
  }
  const status = msg?.status;
  let currentStatus = "unknown";
  if (status.length !== -1) {
    const lastStatus = status[status?.length - 1];
    currentStatus = lastStatus?.status;
  }

  useEffect(() => {
    if (wavesurferRef.current) {
      const ws = WaveSurfer.create({
        container: wavesurferRef.current,
        barWidth: 3,
        barRadius: 25,
        barHeight: 0.6,
        cursorColor: "#57BAB6",
        waveColor: isUser ? "#FFFFFF" : "#1F1F1F",
        progressColor: isUser ? "#1F1F1F" : "#C2C2C2",
        cursorWidth: 0,
        height: 64,
        mediaControls: true,
      });
   
      ws.load(
        msg?.message?.audio?.link
        // "https://raw.githubusercontent.com/katspaugh/wavesurfer.js/main/examples/audio/audio.wav"
      );

      ws.on("load", (url) => {
        setState(false);
      });

      ws.on("ready", (duration) => {
        setWavesurfer(ws);
      });
      ws.on("play", () => setState(true));
      ws.on("pause", () => setState(false));
      ws.on("finish", () => setState(false));
      ws.on("audioprocess", (duration) => {
        setCurrentTime(duration);
      });
    }

  }, []);

  const handlePlayPause = () => {
    if (wavesurfer == null) return;
    if (wavesurfer.isPlaying()) {
      wavesurfer.pause();
      return;
    }
    wavesurfer.play();
  };

  function timeFormatter(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    if (formattedHours != "00") {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?._id;
    if (msg?.is_read) {
      if (!msg?.is_read?.includes(userId)) {
        markAsSeen(msg?._id);
      }
    }
  }, []);

  const renderReply = () => {
    return (
      <div className={`cursor-pointer max-w-[438px]`}>
        <div className="bg-[#eeeeee] text-gray-100 rounded-md py-2 px-4 border-l-2 border-l-primary-color">
          {/* text  */}
          {
            reply?.type === 'text' && 
            <p className="bg-[#eeeeee] text-gray-100 w-fit rounded-md">
            {
              reply?.text?.body
            }
          </p>
          }
          {/* voice */}
          {
            reply?.type === 'audio' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">mic</span>
              <p className="">Audio message</p>
            </div>
          }
          {/* document */}
          {
            reply?.type === 'document' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">note</span>
              <p className="">Document message</p>
            </div>
          }
          {/* image */}
          {
            reply?.type === 'image' && <div className=" flex gap-2 items-center">
              <span className="material-symbols-outlined text-[16px]">photo</span>
              <p className="">Image message</p>
            </div>
          }
        </div>
      </div>
    );
  };

  return (
    <div id="root-document" className={`flex ${isUser ? "justify-end" : ""}`}>
      <div className="min-w-md mx-4 my-4 flex flex-col ">
        <div className={`flex gap-4 ${ isUser ? ' flex-row-reverse items-center': ''}`}>
          <div className={`min-w-[440px] rounded-md py-px px-px bg-gray-50`}>
            { context && renderReply()}
            <div className={`flex gap-3 overflow-hidden items-center justify-center rounded-md ${
              isUser ? "bg-primary-color" : "bg-gray-50"
            }`}>
              <button onClick={handlePlayPause}>
                <img
                  src={ state ? isUser ? IconPauseWhite: IconPause : isUser ? IconPlayWhite : IconPlay}
                />
              </button>
              <div ref={wavesurferRef} className="min-w-[320px] h-16 z-0" />
              <div
                className={`flex justify-center items-center text-[14px] min-w-[46px] ${
                  isUser ? "text-white" : "text-app-black-color"
                }`}
              >
                {state
                  ? timeFormatter(currentTime)
                  : timeFormatter(wavesurfer?.getDuration())}
              </div>
            </div>

          </div>
          <div id="btn-container" className="hidden">
              <button onClick={onReplyPressed} className="hover:text-primary-color text-gray-100 text-sm h-[34px] flex items-center justify-center">
                Reply
              </button>
          </div>
        </div>
       
        <div
          className={`flex gap-2 items-center ${
            isUser ? "flex-row-reverse " : ""
          }my-1`}
        >
          {isUser && status && (
            <div className="flex items-center">
              {currentStatus === "error" && (
                <p className="text-[12px] font-light text-red-600">
                  Error sending...
                </p>
              )}
              {currentStatus === "delivered" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  done
                </span>
              )}
              {currentStatus === "seen" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  done_all
                </span>
              )}
              {currentStatus === "pending" && (
                <span className="material-symbols-outlined text-[16px] text-primary-color">
                  hourglass_top
                </span>
              )}
            </div>
          )}
          {createdAt && (
            <p className={`text-[12px] text-gray-100`}>
              {chatTimeFormat(createdAt)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default VoiceBubble;
