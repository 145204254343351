import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "agents",
  initialState: {agents: null, teams: null},
  reducers: {
    setAgents: (state, action) => {
      state.agents = action.payload;
    },
    setTeams: (state, action) => {
      state.teams = action.payload;
    }
  },
});

export const { setAgents, setTeams } =
  slice.actions;

// fetch agents
export const saveAgents = (payload) => async (dispatch) => {
    dispatch(setAgents(payload));
};

export const saveTeams = (payload) => async (dispatch) => {
  dispatch(setTeams(payload));
};

export default slice.reducer;
