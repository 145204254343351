import React, { useEffect, useRef } from "react";
import CircleAvatar from "../circle_avatar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Button from "../button";

function AccountProfilePopup({ onDissmis }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const popRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
        navigate("/login");
        toast.success("success sing out");
    }
    const handleClickOutSide = (event) => {
      if (popRef.current && !popRef.current.contains(event.target)) {
        onDissmis && onDissmis();
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [onDissmis, isAuthenticated]);

  return (
    <div
      ref={popRef}
      className="bg-gray-50 min-w-[310px] shadow-xl rounded-md absolute top-14 right-4 cursor-pointer"
    >
      <div className=" flex items-center gap-3 ml-3 mt-3 mb-1">
        <CircleAvatar
          name={"ijas"}
          profileImage={null}
          size="w-[34px] h-[34px]"
        />
        <div className=" font-medium">qued.io</div>
      </div>
      <div className="flex items-center justify-between mx-3 my-2 text-gray-400 hover:text-primary-color">
        <button className=" text-sm">Organization Settings</button>
        <span className="material-symbols-outlined">navigate_next</span>
      </div>
      <hr className=" border-gray-100" />
      <div className="ml-3 mt-3 mb-1 flex gap-3 items-center">
        <div className="">
          <CircleAvatar
            name={"Ijas Aslam"}
            profileImage={null}
            size="w-[34px] h-[34px]"
          />
        </div>
        <div className="">
          <h4 className=" text-sm font-medium">Ijas Aslam</h4>
          <p className=" text-[12px] text-gray-400">ijas.aslam@qued.io</p>
        </div>
      </div>
      <div className="ml-4 mb-3 mt-3">
        <button className=" text-gray-400 text-md hover:text-primary-color">
          Personal Settings
        </button>
      </div>
      <div className="ml-4 mb-3 mt-3">
        <Button onClick={()=> dispatch(logout())} state={loading} className="text-red-600 text-md" label="Sign out"/>
      </div>
    </div>
  );
}

export default AccountProfilePopup;
