import React from 'react'
import './style.css'

function TimePicker() {
  return (
    <div>
       <input className='time-picker bg-gray-100 bg-opacity-20 px-2 py-1 border-red-500 rounded-md outline-gray-100 outline-[1px] disabled:text-gray-200' type="time" defaultValue='07:00'></input>
    </div>
  )
}

export default TimePicker
