import React, { useEffect, useState } from "react";
import TabBar from "../tab-bar";
import TempleteMessageTile from "../templete-message-tile";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { saveQuickReplies } from "../../redux/chat/chatSlice";
import { ShimmerTitle } from "react-shimmer-effects";

function QuickRepliesDialog({onSelectedReplie}) {
  const dispatch = useDispatch();
  const { quickReplies } = useSelector((state) => state.chats);
  const { loading, setLoading} = useState(false);

  const tabs = [
    {
      title: "Quick Replies",
    },
    {
      title: "Templete",
    },
  ];

  useEffect(()=>{
    fetchQuickReplies();
    fetchTemplates();
  }, []);


  const fetchQuickReplies =  async () => {
    // setLoading(true);
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}quick_replies/?query=`,
        method: "GET",
      });
      // setLoading(false);
      if (response.status) {
        dispatch(saveQuickReplies(response.data));
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      // setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  }

  const fetchTemplates = async () => {
    // setLoading(true);
    try {
      const response = await fetcher({
        url: `${Config.metaBaseUrl}107086465810618/messages`,
        method: "GET",
      });
      // setLoading(false);
      if (response.status) {
        console.log(response.data);
        // dispatch(saveQuickReplies(response.data));
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      // setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  }

  return (
    <div className="absolute z-20 bottom-14 left-4 bg-white border border-gray-50 shadow-md rounded-md overflow-hidden">
      <div className="flex flex-col w-[380px]">
        <div className="px-4 pt-4 pb-1 relative text-app-black-color">
          <span className="material-symbols-outlined text-gray-100 absolute top-[22px] left-6 text-[22px]">search</span>
          <input
            type="text"
            className="w-full p-1 pl-10 bg-white rounded-sm outline outline-1 focus:outline-primary-color outline-gray-50 placeholder:text-sm"
            placeholder="Search"
          />
        </div>
        <div className="">
          <TabBar tabs={tabs}>
            <div className="h-[320px] overflow-y-scroll">
                {
                  loading &&  <div className="p-3 overflow-hidden">
                    <ShimmerTitle />
                    <ShimmerTitle />
                    <ShimmerTitle />
                    <ShimmerTitle />
                  </div>
                }
                {
                  !loading && quickReplies.map((item, index) => {
                    return  <div key={index} onClick={()=> onSelectedReplie(item)} className="m-2 p-2 bg-gray-50 rounded-md cursor-pointer">
                    <p className="text-sm text-app-black-color">
                        {item.message}
                    </p>
                   </div>
                  })
                }
               
            </div>
            <div className="h-[320px] overflow-y-scroll">
                <TempleteMessageTile/>
                <TempleteMessageTile/>
                <TempleteMessageTile/>
                <TempleteMessageTile/>
                <TempleteMessageTile/>
            </div>
          </TabBar>
        </div>
      </div>
    </div>
  );
}

export default QuickRepliesDialog;
