import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { contacts } from "../../mocks/contacts";
import ContactTableRow from "./contact-table-row";
import CreateUpdateContact from "../../components/create-update-contact";
import Pagination from "../../components/pagination";

function Contacts() {
  const [currentPage, setPage] = useState(1);
  const [isCreateUpdateOpen, setShowCreateUpdate] = useState(false);
  const [selectedContact, setSelectContact] = useState(null);

  const closeCreateUpdate = () => {
    setShowCreateUpdate(false);
  };

  useEffect(() => {
    if (isCreateUpdateOpen) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isCreateUpdateOpen]);

  return (
    <section>
      <div className="cursor-pointer max-h-[90vh] h-fit overflow-x-scroll no-scrollbar">
        <div className="flex justify-between mt-[14px]">
          <div>
            <h2 className="font-semibold text-[20px]">Contacts</h2>
            <div className=" text-[14px] text-gray-100 mt-[2px]">
              23 Contacts available
            </div>
          </div>
          <div className="mt-2">
            <button
              className=" bg-primary-color text-white px-2 py-1 rounded-md shadow-md text-sm"
              onClick={() => setShowCreateUpdate(true)}
            >
              ADD NEW CONTACT
            </button>
          </div>
        </div>
        <hr className="border-gray-50 my-2" />
        <div className="py-2 flex justify-between gap-2">
          <div className="relative">
            <input
              className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] pl-8 px-4 py-1"
              placeholder="Search Contact"
            ></input>
            <span className="absolute left-1 text-gray-100 top-1 material-symbols-outlined">
              search
            </span>
          </div>

          <div className="flex gap-2">
            <div>
              <select
                defaultValue={"DEFAULT"}
                onChange={(e) => {}}
                className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] px-2 py-1"
              >
                <option value={"DEFAULT"}>All Tag</option>
                <option value="sale-team">Premium</option>
                <option value="developer">Sale</option>
                <option value="test-team">Return</option>
              </select>
            </div>

            <div>
                <button className="flex gap-2 border-[1px] text-sm border-gray-50 px-4 py-[2.3px] rounded-md hover:bg-primary-color hover:text-white">
                  <span className="material-symbols-outlined text-[22px]">
                    export_notes
                  </span>
                  Export
                </button>
            </div>

            <div>
              <input
                id="import-button"
                type="file"
                accept=".xlsx"
                className="hidden"
                onChange={() => {}}
              ></input>
              <label
                htmlFor="import-button"
                className="flex gap-2 border-[1px] text-sm border-gray-50 px-4 py-[2.3px] rounded-md hover:bg-primary-color hover:text-white"
              >
                <span className="material-symbols-outlined text-[22px]">
                  import_contacts
                </span>
                Import
              </label>
            </div>
          </div>
        </div>
        <div className="my-2">
          <table className="w-[100%]">
            <thead>
              <tr className="text-gray-200 text-md text-left">
                <th className="font-medium">CUSTOMER NAME</th>
                <th className="font-medium px-4">TAG</th>
                <th className="font-medium px-4">CREATED AT</th>
                <th className="font-medium px-4 text-center">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <ContactTableRow
                  data={contact}
                  onEditClick={(contact) => {
                    setSelectContact(contact);
                    setShowCreateUpdate(true);
                  }}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="6">
                 <Pagination />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal
        isOpen={isCreateUpdateOpen}
        onRequestClose={closeCreateUpdate}
        onAfterClose={() => {
          setSelectContact(null);
        }}
        onAfterOpen={() => {}}
        style={{
          content: {
            right: "1px",
            top: "50%",
            bottom: "auto",
            left: "auto",
            padding: "0",
            backgroundColor: "white",
            margin: "0",
            transform: "translate(-2%, -50%)",
            height: "94vh",
            width: "380px",
            overflow: "hidden",
          },
          overlay: {
            background: "#00000055",
          },
        }}
      >
        <CreateUpdateContact
          data={selectedContact}
          close={() => {
            setShowCreateUpdate(false);
          }}
        />
      </Modal>
    </section>
  );
}

export default Contacts;
