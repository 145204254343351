import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Header from "../header";
import SideNavBar from "../side-navbar";
import { useSelector } from "react-redux";

function Layout() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className="max-h-screen overflow-hidden">
        <Header />
        <div className="flex flex-col items-center mt-[61px]">
          <section className="flex max-w-[1400px] w-[100%]">
            <nav className="min-w-[250px] w-[30%] hidden md:block cursor-pointer h-[93vh] overflow-auto">
              <SideNavBar />
            </nav>
            <main className="w-[100%]">
              <Outlet />
            </main>
          </section>
        </div>
      </div>
    );
  }
}

export default Layout;
