import React from "react";
import { phoneCode } from "../../assets/json/phone-codes.js";
import Select from "react-select";
import './style.css'

function PhoneNumberField() {
  return (
    <div className="parent flex border-gray-50 border-[1px] rounded-md">
        <Select
            options={phoneCode}
            placeholder="Select"
            defaultValue= {{value : 'IN', label: '+91'}}
            className="child min-w-[64px]"
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
            styles={{
                control: (baseStyles, state) => ({
                border: 'none',
                paddingLeft: "2px",
                outline: 'none',
                ":active" : { border: 'none', outline: 'none'}
                }),
                placeholder: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "12px",
                fontWeight: "lighter",
                color: "#c2c2c2",
                }),
            
            }}
            />
        <div className=" w-px bg-gray-50"></div>
        <input type="tel" className="child placeholder:text-[12px] placeholder:font-light outline-none px-4 text-sm" placeholder="Enter phone number"></input>
    </div>
  );
}

export default PhoneNumberField;
