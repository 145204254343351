import React from "react";
import TableRow from "./table-row";

function TemplateTable({ data }) {
  return (
    <div className="my-6 relative">
      <table className="w-[100%]">
        <thead>
          <tr className="text-gray-100 text-sm font-normal text-left ">
            <th className="font-medium pb-2">TEMPLATE ID</th>
            <th className="font-medium pb-2">PREVIEW</th>
            <th className="font-medium pb-2">LANGUAGE</th>
            <th className="font-medium pb-2 text-center">SHOW IN CHAT</th>
            <th className="font-medium pb-2 text-center">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return <TableRow key={index} item={item} />;
            })}
        </tbody>
      </table>
    </div>
  );
}

export default TemplateTable;
