export const DateFormat = (value) => {
  const dateObject = new Date(value);

  const formattedDate = dateObject.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });

  return `${formattedDate} - ${formattedTime}`;
};

export const durationFormat = (value) => {
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  return `${minutes.toString().padStart(2, "0").split(".")[0]}:${seconds
    .toString()
    .split(".")[0]
    .padStart(2, "0")}`;
};

export const fileExtension = (fileName) => {
  const regex = new RegExp("[^.]+$");
  const extension = fileName.match(regex);
  return extension;
};

export const fileSizeFormatter = ({ bytes }) => {
  const formatSize = (bytes) => {
    if (bytes === 0) return "0 B";

    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  return formatSize(bytes);
};

export const chatTimeFormat = (value) => {
  const date = new Date(value);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedTime = date.toLocaleString("en-US", options);
  
  return formattedTime;
};
