import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-pen.svg";
import CircleAvatar from "../../components/circle_avatar";
import Button from "../../components/button";
import TimePicker from "../../components/time-field";
import ToggleSwitch from "../../components/toggle-switch";

function BusinessProfile() {
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedFile(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <section>
      <div className="cursor-pointer max-h-[90vh] h-fit overflow-x-scroll no-scrollbar">
        <div className="mt-[14px]">
          <h2 className="font-semibold text-[20px]">Business Profile</h2>
          <div className=" text-[14px] text-gray-100">
            Manage your business account details
          </div>
        </div>
        <hr className="border-gray-50 my-2" />
        <div className="flex items-center gap-10">
          <div className="relative w-fit h-fit">
            {selectedFile ? (
              <div className="bg-primary-light h-32 w-32 rounded-full shadow-md overflow-hidden">
                <img src={selectedFile} alt={selectedFile.name} />
              </div>
            ) : (
              <CircleAvatar
                name="qued.io"
                profileImage={null}
                size={"w-[128px] h-[128px] shadow-md"}
              />
            )}
            <div className=" absolute right-1 bottom-1">
              <input
                id="pic-button"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
              ></input>
              <label htmlFor="pic-button">
                <EditIcon className=" h-8 w-8 bg-primary-color rounded-full p-2 shadow-md" />
              </label>
            </div>
          </div>

          <div className="my-10 px-2">
            <form>
              <div className="flex flex-col gap-2 w-fit min-w-[300px]">
                <label className="text-sm text-gray-100 font-light">
                  Organization Name
                </label>
                <input
                  className=" placeholder:text-sm placeholder:font-light outline outline-gray-50 outline-1 focus:outline-primary-color rounded-md py-1 px-2"
                  type="name"
                  placeholder="Enter your organization name"
                  defaultValue="qued.io"
                ></input>
              </div>
              <div className="w-[300px] my-6">
                <Button
                  onClick={() => {}}
                  className="bg-primary-color rounded-md text-white py-1 px-4 "
                  label="Update"
                />
              </div>
            </form>
          </div>
        </div>

        <div>
          <h2 className="font-semibold text-[20px]">Business Hours</h2>
          <div className=" text-[14px] text-gray-100">
            Manage your business hours
          </div>

          <div className="card bg-gray-50 md:w-[60vw] rounded-md my-8">
            <div className=" font-medium pl-4 pt-4 pb-2 text-[16px]">
              Set your working hours
            </div>
            <div className="text-[14px]">
              <table className=" w-full border-spacing-4 border-separate">
                <tbody>
                  <tr>
                    <td>Monday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Tuesday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Wednesday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Friday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Sunday</td>
                    <td>
                      <div className="flex gap-2 items-end justify-end">
                        <TimePicker />
                        <hr />
                        <TimePicker />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-end justify-end">
                        <ToggleSwitch />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BusinessProfile;
