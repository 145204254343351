import React, {useEffect, useState} from "react";
import Select from 'react-select';

function TeamOptionSelector({values, teams, onChange}) {
   const [options, setOptions] = useState([]);
   const [selected, setSelected] = useState([]);
   
   const handleChange = (option) => {
      setSelected(option);
      onChange && onChange(option);
    }  

    useEffect(()=>{
      if(teams){   
        const transformedArray = teams.map(item => ({
          value: item._id,
          label: item.name
        }));
        setOptions(transformedArray);
      }
      if(values){
        const defaultValues = values.map(item => ({
          value: item._id,
          label: item.name
        }));
        setSelected(defaultValues);
      }
    },[]);

  return (
    <div>
      <Select
            isMulti
            isClearable={false}
            placeholder='Select teams'
            onChange={handleChange}
            options={options}
            value={selected}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: '#f5f5f5',
                borderRadius: '6px',
                paddingLeft: '2px',
                ':hover' : {'borderColor': '#f5f5f5', 'outline':'none'},
              }),
              option: (baseStyles, state) => ({
                  fontSize: '14px',
                  paddingTop : '10px',
                  paddingRight: '10px',
                  paddingBottom: '10px',
                  paddingLeft: '12px',
                  backgroundColor: state.isFocused ?  '#f5f5f5' : '',
                  color: state.isFocused ?  '#5C42F9' : '',
              }),
              placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize:'14px',
                  fontWeight: 'lighter',
                  color: '#c2c2c2'
                  }),
              multiValue: (styles, { data }) => ({
                  display: 'flex',
                  fontFamily: 'Poppins',
                  color: '#5C42F9',
                  backgroundColor: '#e3defc',
                  borderRadius: '20px',
                  paddingLeft: '10px',
                  paddingRight: '6px',
                  paddingTop:'3px',
                  paddingBottom: '3px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '3px',
              }),
              multiValueLabel: (styles, { data }) => ({
                  color: '#5C42F9',
                  fontSize: '14px',
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: data.color,
                  borderRadius : '40px',
                  marginLeft:'8px',
                  height: '20px',
                  ':hover': {
                    backgroundColor: '#5C42F9',
                    color: 'white',
                  },
                }),
            }}
          />
    </div>    
  );
}

export default TeamOptionSelector;
