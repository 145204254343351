import { createSlice } from "@reduxjs/toolkit";
import { fetcher } from "../../hooks/fetcher/useFetch";
import Config from "../../config/config";

const slice = createSlice({
  name: "chats",
  initialState: {pinned: [], unpinned: [], messages: [], quickReplies: []},
  reducers: {
    setChats: (state, action) => {
      state.pinned = action.payload.pinned_chat;
      state.unpinned = action.payload.chat;
    },
    pinChat : (state, action) => {
      state.pinned.push(action.payload);
      state.unpinned = state.unpinned.filter(item => item._id !== action.payload._id);
    },
    unPinChat : (state, action) => {
      state.unpinned.push(action.payload);
      state.pinned = state.pinned.filter(item => item._id !== action.payload._id);
    },
    pushMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    concatMessages: (state, action) => {
      state.messages = [...state.messages, ...action.payload].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    },
    replaceMessage: (state, action) => {
      const { id, message } = action.payload;
      const index = state.messages.findIndex(message => message._id === id);
      if (index !== -1) {
        state.messages[index] = message;
      }
    },
    updateStatus: (state, action) => {
      const { status } = action.payload;
      const messageToUpdate = state.messages.find(message => message.message.id === status.id);
      if(messageToUpdate){
        messageToUpdate.status.push(status);
      }
    },
    clearMessages: state => {
      state.messages = [];
    },
    setQuickReplies: (state, action) => {
      state.quickReplies = action.payload;
    },
  },
});

export const { 
  setChats,
  concatMessages,
  replaceMessage,
  updateMessageStatus,
  clearMessages,
  pushMessage,
  pinChat,
  unPinChat,
  updateStatus,
  setQuickReplies,
} = slice.actions;

// fetch chats
export const fetchChats = (payload) => async (dispatch) => {
  try {
    const response = await fetcher({
      url: `${Config.baseUrl}message/chats?view_as=${payload.userId}&query=${payload.query}`,
      method: "GET",
    });
    if (response.status) {
      if (response.data) {
        dispatch(setChats(response.data));
      } else {  
      }
    } else {
      // toast.error(response.message);
    }
  } catch (err) {
    if (err.status === 403) {
      // toast.error("session time out!");
    } else {
      // toast.error("Some error occured while fetch");
    }
  }
};

// pin chat
export const pinnChat = (payload) => async (dispatch) => {
  try {
    const response = await fetcher({
      url: `${Config.baseUrl}message/pin-chat`,
      method: "POST",
      body: JSON.stringify({room: payload._id}),
    });
    if (response.status) {
      dispatch(pinChat(payload));
    } else {
     console.log(response.message);
    }
  } catch (err) {
    if (err.status === 403) {
      console.log("session time out!");
    } else {
      console.log("Some error occured while fetch");
    }
  }
};

// unpin chat
export const unpinChat = (payload) => async (dispatch) => {
  try {
    const response = await fetcher({
      url: `${Config.baseUrl}message/unpin-chat`,
      method: "POST",
      body: JSON.stringify({room: payload._id}),
    });
    if (response.status) {
      dispatch(unPinChat(payload));
    } else {
     console.log(response.message);
    }
  } catch (err) {
    if (err.status === 403) {
      console.log("session time out!");
    } else {
      console.log("Some error occured while fetch");
    }
  }
};

//set quick replies
export const saveQuickReplies = (payload) => async (dispatch) => {
  try{
    dispatch(setQuickReplies(payload));
  }catch(err){
    console.log(err);
  }
}

export default slice.reducer;
