import React, { useState, useEffect } from "react";
import TeamTableRow from "./table-row";
import CreateUpdateTeam from "../../components/create-update-team";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { ShimmerTable } from "react-shimmer-effects";
import Pagination from "../../components/pagination";

function Teams() {
  const [currentPage, setPage] = useState(0);
  const [isCreateUpdateOpen, setShowCreateUpdate] = useState(false);
  const [selectedTeam, setSelectTeam] = useState(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [query, setQuery] = useState({ search: "" });

  const handleSearchInput = (event) => {
    const currentLoginData = { ...query };
    currentLoginData[event.target.name] = event.target.value;
    setQuery(currentLoginData);
  };

  const closeCreateUpdate = () => {
    setShowCreateUpdate(false);
  };

  useEffect(() => {
    fetchTeams();
    if (isCreateUpdateOpen) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isCreateUpdateOpen, query, currentPage]);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await fetcher({
        url: `${
          Config.baseUrl
        }team?limit=${10}&offset=${10 * currentPage}&query=${query.search}`,
        method: "GET",
      });
      if (response.status) {
        if (response.data) {
          setData(response.data[0].data);
          setDataCount(response.data[0].metadata[0].count);
        } else {
          setData(null);
          setDataCount(0);
        }
      } else {
        setData([]);
        setDataCount(0);
        toast.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  };

  const handlePagination = (page) => {
    setPage(page - 1);
  };

  return (
    <section>
      <div className="cursor-pointer max-h-[90vh] h-fit overflow-x-scroll no-scrollbar">
        <div className="flex justify-between mt-4">
          <div>
            <h2 className="font-semibold text-[20px]">Teams</h2>
            <div className=" text-[14px] text-gray-100">
              {dataCount} Teams available
            </div>
          </div>
          <div className="mt-2">
            <button
              className=" bg-primary-color text-white px-2 py-1 rounded-md shadow-md text-sm"
              onClick={() => setShowCreateUpdate(true)}
            >
              ADD NEW TEAM
            </button>
          </div>
        </div>
        <hr className="border-gray-50 my-2" />
        <div className="py-2 flex justify-between gap-2 mx-[1px]">
          <div className="relative">
            <input
              className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] pl-8 px-4 py-1"
              placeholder="Search Team"
              name="search"
              onChange={handleSearchInput}
            ></input>
            <span className="absolute left-1 text-gray-100 top-1 material-symbols-outlined">
              search
            </span>
          </div>
        </div>
        {data && (
          <div className="my-2">
            <table className="w-[100%]">
              <thead>
                <tr className="text-gray-200 text-md text-left">
                  <th className="font-medium">TEAM</th>
                  <th className="font-medium px-4">DESCRIPTION</th>
                  <th className="font-medium px-4">MEMBERS</th>
                  <th className="font-medium px-4">STATUS</th>
                  <th className="font-medium px-4 text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data.map((team, index) => (
                  <TeamTableRow
                    key={index}
                    data={team}
                    onEditClick={(team) => {
                      setSelectTeam(team);
                      setShowCreateUpdate(true);
                    }}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="6">
                    <Pagination items={dataCount} onChange={handlePagination}/>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {loading && (
          <div className="p-1">
            <ShimmerTable row={6} col={5} />;
          </div>
        )}
      </div>

      <Modal
        isOpen={isCreateUpdateOpen}
        onRequestClose={closeCreateUpdate}
        onAfterClose={() => setSelectTeam(null)}
        onAfterOpen={() => {}}
        style={{
          content: {
            right: "1px",
            top: "50%",
            bottom: "auto",
            left: "auto",
            padding: "0",
            backgroundColor: "white",
            margin: "0",
            transform: "translate(-2%, -50%)",
            height: "94vh",
            width: "380px",
          },
          overlay: {
            background: "#00000055",
            zIndex: 30,
          },
        }}
      >
        <CreateUpdateTeam close={closeCreateUpdate} data={selectedTeam} />
      </Modal>
    </section>
  );
}

export default Teams;
