import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.css'

function PhoneInputField({ value, onChange, props }) {
  return (
    <PhoneInput
      country={'us'}
      placeholder="Enter phone number"
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

export default PhoneInputField;
