import React from "react";
import { useTemplateContext } from "../../../providers/template-provider/template_provider";
import PreviewButton from "../button";

function ButtonPreview() {
  const { buttons } = useTemplateContext();
  return (
    <div className=" cursor-pointer">
      {buttons.map((item, index) => (
        <PreviewButton key={index} button={item} />
      ))}
    </div>
  );
}

export default ButtonPreview;
