export const fetcher = async (config) => {
  const {
    url,
    method = "GET",
    header,
    body,
    signal,
    multiPart = false,
    isAuth = true,
  } = config;

  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = isAuth && user?.access_token;
  const options = {
    method,
    signal,
    headers: !multiPart
      ? {
          "Content-type": "application/json; charset=UTF-8",
          ...(accessToken && isAuth
            ? { Authorization: `Bearer ${accessToken}` }
            : {}),
          ...(header ? { ...header } : {}),
        }
      : {
          ...(accessToken && isAuth
            ? { Authorization: `Bearer ${accessToken}` }
            : {}),
          ...(header ? { ...header } : {}),
        },
    body: method === "POST" || method === "PUT" ? body : null,
  };

  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        //error
        return Promise.reject({
          status: res.status,
          info: await res
            .clone()
            .json()
            .catch(() => res.text()),
        });
      }
      return Promise.resolve(
        await res
          .clone()
          .json()
          .catch(() => res.text())
      );
    })
    .catch((error) => Promise.reject(error));
};
