import React, { useState, useEffect } from 'react';
import './style.css';

const ToggleSwitch = ({active, onChange}) => {
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  useEffect(()=>{
    setChecked(active);
  }, [active]);

  return (
    <label className="flex items-center cursor-pointer">
      <div className={`relative toggle-slider ${checked ? 'checked' : ''} ${checked ? 'disabled' : ''}`}>
        {/* Hidden input to store the state of the toggle */}
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={handleToggle}
        />
        {/* Slider */}
        <div className="w-8 h-4 rounded-full shadow-inner z-0"></div>
        {/* Knob */}
        <div
          className={`absolute shadow-md w-3 h-3 rounded-full bg-white top-[2px] left-[2px] transition-transform ${
            checked ? 'transform translate-x-4' : ''
          }`}
        ></div>
      </div>
    </label>
  );
};

export default ToggleSwitch;