import React from "react";

function PrivateMessageBubble({ msg }) {
  const content = msg?.message?.text?.body;
  return (
    <div className={`flex items-center justify-center mx-4 my-4`}>
        <div className="flex-1 bg-gray-50 h-px mr-2"/>
        <div className={`bg-gray-50 text-gray-200 font-light text-sm w-fit rounded-full py-1 px-3 max-w-[60%]`}>
          <div className={`text-sm`}>{content}</div>
        </div>
        <div className="flex-1 bg-gray-50 h-px ml-2"/>
    </div>
  );
}

export default PrivateMessageBubble;
