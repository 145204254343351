import React, { useRef, useEffect } from "react";
import Select from "react-select";
import PhoneInputField from "../../../components/phone-input";
import { useTemplateContext } from "../../../providers/template-provider/template_provider";
import { Controller } from "react-hook-form";

function ActionButtonCard({ count, actionType, control, watch }) {
  const { replaceButton, updateButtonText, removeButton, buttons } =
    useTemplateContext();

  const actionTypes = [
    { value: "call_phone_number", label: "Call Phone Number" },
    { value: "copy_offer_code", label: "Copy offer code" },
    { value: "dynamic_link", label: "Dynamic Link" },
    { value: "static_link", label: "Static Link" },
  ];

  const handleChangeType = (value) => {
    replaceButton({ type: value.value }, actionType);
  };

  const handleRemoveCard = () => {
    removeButton(actionType);
  };

  const getValue = () => {
    return actionTypes.find((item) => item.value === actionType);
  };

  useEffect(() => {
    if(watch?.display_text){
      updateButtonText(watch?.display_text[actionType], actionType);
    }
  }, [watch]);

  return (
    <li className="">
      <div className=" rounded-md bg-gray-50 shadow-sm my-4 px-2 py-2">
        <div className="flex items-center justify-between">
          <h3 className="">Button {count + 1}</h3>
          {buttons.length > 1 && (
            <button onClick={handleRemoveCard} className="">
              <span className="material-symbols-outlined text-gray-200 text-md">
                cancel
              </span>
            </button>
          )}
        </div>
        <div className="my-2">
          <label>Action type</label>
          <Select
            value={getValue()}
            options={actionTypes}
            className="my-1"
            onChange={handleChangeType}
            isOptionDisabled={(option) =>
              buttons.some((selectedItem) => option.value === selectedItem.type)
            }
          />
        </div>
        {buttons[count].type === "call_phone_number" && (
          <div className="my-2">
            <label>Phone number</label>
            <br />
            <div className="my-1">
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: "Phone number is required",
                  pattern: {
                    value: /^[0-9\b]+$/,
                    message: "Invalid phone number format",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <PhoneInputField
                      value={""}
                      onChange={(v) => {}}
                      props={field}
                    />
                    {error && (
                      <p className=" text-red-600 text-[11px]">
                        {error?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        )}
        {buttons[count].type === "copy_offer_code" && (
          <div className="my-2">
            <label>Offer code</label>
            <br />
            <Controller
              name="sample_code"
              control={control}
              rules={{
                required: "Sample code is required",
                minLength: {
                  value: 4, // Change the minimum length requirement to your desired value
                  message: "Minimum length is 4 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <input
                    type="text"
                    className="mt-1 w-full py-2 px-3 bg-gray-50 border rounded-sm focus:outline-none"
                    placeholder="Enter sample code"
                    {...field}
                  />
                  {error && (
                    <p className=" text-red-600 text-[11px]">
                      {error?.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>
        )}
        {buttons[count].type === "dynamic_link" && (
          <div className="my-2">
            <label>Website URL</label>
            <div className="mb-2">
              <Controller
                name="website_dynamic"
                control={control}
                rules={{
                  required: "Website URL is required",
                  pattern: {
                    value: /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})$/,
                    message: "Invalid URL format (no paths or parameters)",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <input
                      type="text"
                      className="mt-1 w-full py-2 px-3 bg-gray-50 border rounded-sm focus:outline-none"
                      placeholder="https://www.example.com"
                      {...field}
                    />
                    {error && (
                      <p className=" text-red-600 text-[11px]">
                        {error?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
            <label>Sample URL</label>
            <div className="mb-2">
              <Controller
                name="sample_dynamic"
                control={control}
                rules={{
                  required: "Website URL is required",
                  pattern: {
                    value:
                      /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})(\/\S+|\?[\w%=-]+)?$/,
                    message: "Invalid URL format",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <input
                      type="text"
                      className="mt-1 w-full py-2 px-3 bg-gray-50 border rounded-sm focus:outline-none"
                      placeholder="https://www.example.com/{{1}}"
                      {...field}
                    />
                    {error && (
                      <p className=" text-red-600 text-[11px]">
                        {error?.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <p className=" text-[12px] text-gray-200 leading-4 mt-1">
                To help us review your message template, please add an example
                of the website URL. Do not use real customer information.
              </p>
            </div>
          </div>
        )}
        {buttons[count].type === "static_link" && (
          <div className="my-2">
            <label>Website URL</label>
            <div className="mb-2">
              <Controller
                name="web_site"
                control={control}
                rules={{
                  required: "Website URL is required",
                  pattern: {
                    value:
                      /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w .-]*)*\/?$/,
                    message: "Invalid URL format",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <input
                      type="text"
                      className="mt-1 w-full py-2 px-3 bg-gray-50 border rounded-sm focus:outline-none"
                      placeholder="https://www.example.com"
                      {...field}
                    />
                    {error && (
                      <p className=" text-red-600 text-[11px]">
                        {error?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        )}
        {actionType != "copy_offer_code" && (
          <div className="my-2">
            <label>Button display text</label>
            <br />
            <Controller
              name={`display_text.${actionType}`}
              control={control}
              defaultValue=""
              rules={{
                required: "Button display text is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <input
                    {...field}
                    type="text"
                    className="mt-1 w-full py-2 px-3 bg-gray-50 border rounded-sm focus:outline-none"
                    placeholder="Enter text"
                  />
                  {error && (
                    <p className=" text-red-600 text-[11px]">{error.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        )}
      </div>
    </li>
  );
}

export default ActionButtonCard;
