import React, { useState, useEffect } from "react";
import TableRow from "./table-row";
import CreateUpdateAgent from "../../components/create-update-agent";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Config from "../../config/config";
import { fetcher } from "../../hooks/fetcher/useFetch";
import { ShimmerTable } from "react-shimmer-effects";
import { useDispatch } from "react-redux";
import { saveAgents, saveTeams } from "../../redux/agents/agentSlice";
import Pagination from "../../components/pagination";

function Agents() {
  const [currentPage, setPage] = useState(0);
  const [isCreateUpdateOpen, setShowCreateUpdate] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const dispatch = useDispatch();

  const [teams, setTeams] = useState(null);
  const [query, setQuery] = useState({ search: "", team: "", status: "" });

  const closeCreateUpdate = () => {
    setShowCreateUpdate(false);
  };

  useEffect(() => {
    fetchAgents();
    fetchTeams();
    if (isCreateUpdateOpen) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isCreateUpdateOpen, currentPage, query]);

  //fetch agents
  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await fetcher({
        url: `${Config.baseUrl}agent?limit=10&offset=${
          10 * currentPage
        }&query=${query.search}&status=${query.status}&team=${query.team}`,
        method: "GET",
      });
      if (response.status) {
        if (response.data) {
          setData(response.data[0].data);
          setDataCount(response.data[0].metadata[0].count);
          dispatch(saveAgents(response.data[0].data));
        } else {
          setData(null);
          setDataCount(0);
        }
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        console.log(err);
        toast.error("Some error occured while fetch");
      }
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await fetcher({
        url: `${
          Config.baseUrl
        }team?limit=${1000}&offset=${0}&query=`,
        method: "GET",
      });
      if (response.status) {
        setTeams(response.data[0].data);
        dispatch(saveTeams(response.data[0].data));
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else {
        toast.error("Some error occured");
      }
    }
  };

  const handlePagination = (page) => {
    setPage(page - 1);
  };

  const handleSearchInput = (event) => {
    const currentLoginData = { ...query };
    currentLoginData[event.target.name] = event.target.value;
    setQuery(currentLoginData);
  };

  return (
    <section>
      <div className="cursor-pointer max-h-[90vh] h-fit overflow-x-scroll no-scrollbar">
        <div className="flex justify-between mt-[14px]">
          <div>
            <h2 className="font-semibold text-[20px]">Agents</h2>
            <div className="text-[14px] text-gray-100 mt-[2px]">
              {dataCount} Agents available
            </div>
          </div>
          <div className="mt-2">
            <button
              className=" bg-primary-color text-white px-2 py-1 rounded-md shadow-md text-sm"
              onClick={() => setShowCreateUpdate(true)}
            >
              ADD NEW AGENT
            </button>
          </div>
        </div>
        <hr className="border-gray-50 my-2" />
        <div className="py-2 flex justify-between gap-2 mx-[1px]">
          <div className="relative">
            <input
              className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] pl-8 px-4 py-1"
              placeholder="Search agent name"
              name="search"
              onChange={handleSearchInput}
            ></input>
            <span className="absolute left-1 text-gray-100 top-[3px] material-symbols-outlined">
              search
            </span>
          </div>
          <div className="flex gap-2">
            <div>
              <select
                defaultValue={""}
                onChange={handleSearchInput}
                className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] px-2 py-1"
                name="team"
              >
                <option value={""}>All Team</option>
                {teams &&
                  teams.map((team, index) => (
                    <option key={index} value={team._id}>
                      {team.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <select
                defaultValue={""}
                onChange={handleSearchInput}
                name="status"
                className="outline outline-[1px] rounded-md outline-gray-50 text-[14px] px-2 py-1"
              >
                <option value="">All Status</option>
                <option value="true">Available</option>
                <option value="false">Unvailable</option>
              </select>
            </div>
          </div>
        </div>
        {data != null && (
          <div className="my-2">
            <table className="w-[100%]">
              <thead>
                <tr className="text-gray-200 text-md text-left">
                  <th className="font-medium">NAME</th>
                  <th className="font-medium px-4">EMAIL</th>
                  <th className="font-medium px-4">TEAMS</th>
                  <th className="font-medium px-4">ROLE</th>
                  <th className="font-medium px-4">STATUS</th>
                  <th className="font-medium px-4 text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((agant, index) => (
                  <TableRow
                    key={index}
                    data={agant}
                    onEditClick={(data) => {
                      setShowCreateUpdate(true);
                      setSelectedAgent(data);
                    }}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="6">
                    <Pagination items={dataCount} onChange={handlePagination} />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {
          data == null && <div className=" flex items-center justify-center py-16 text-gray-100 font-medium">
            <p>DATA NOT FOUND</p>
          </div>
        }
        {loading && (
          <div className="px-1">
            <ShimmerTable row={6} col={5} />
          </div>
        )}
      </div>

      <Modal
        isOpen={isCreateUpdateOpen}
        onRequestClose={closeCreateUpdate}
        onAfterClose={() => {
          setSelectedAgent(null);
        }}
        onAfterOpen={() => {}}
        style={{
          content: {
            right: "1px",
            top: "50%",
            bottom: "auto",
            left: "auto",
            padding: "0",
            backgroundColor: "white",
            margin: "0",
            transform: "translate(-2%, -50%)",
            height: "94vh",
            width: "380px",
          },
          overlay: {
            background: "#00000055",
            zIndex: 30,
          },
        }}
      >
        <CreateUpdateAgent data={selectedAgent} close={closeCreateUpdate} teams={teams} />
      </Modal>
    </section>
  );
}

export default Agents;
