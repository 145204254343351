import React from "react";
import CheckBox from "../../../components/check-box";
import CircleAvatar from "../../../components/circle_avatar";
import { DateFormat } from "../../../utils/formater";
import { toast } from "react-toastify";
import Config from "../../../config/config";
import { fetcher } from "../../../hooks/fetcher/useFetch";
import { useSelector } from "react-redux";
import './style.css';

function QueueTableRow({ data, onClick, isSelected, onAssigned}) {
  const { user } = useSelector((state) => state.auth);
  const rowClickHandler = (event) => {
    onClick(data, event.ctrlKey, event.shiftKey);
  };

  const handleActionAssign = (data) => {
      assignChat("user", user._id, [data._id]);
  };

  const assignChat = async (type, userId, room) => {
    try {
      const body = JSON.stringify({room: room, id: userId, type: type});
      console.log(body);
      const response = await fetcher({
        url: `${Config.baseUrl}message/assign-chat`,
        method: "POST",
        body: body,
      });
      if (response.status) {
        toast.success(response.message);
        onAssigned();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      if (err.status === 403) {
        toast.error("session time out!");
      } else if (err.status === 404) {
        toast.error(err.info.message);
      } else {
        toast.error("Some error occured");
      }
    }
  };

  return (
    <>
      <tr onClick={rowClickHandler} className={`row text-sm text-app-black-color select-none mx-2 ${isSelected ? ' border border-primary-color border-l-4 border-spacing-1 ' : 'hover:bg-gray-50'}`}>
        <td className={`${!isSelected ? 'col-action' : ''} font-normalh-10 text-left pl-3`}>
          <CheckBox name={data.roomName}  active={isSelected} />
        </td>
        <td className="font-normal h-8 text-left">
          <div className="flex items-center gap-2">
            <CircleAvatar
              profileImage={null}
              name={data.roomName}
              size="w-[44px] h-[44px]"
            />
            <div>
              <p className="text-[14px]">{data.roomName}</p>
              <p className="text-[12px] text-gray-400">{data.countryCode} {data.phoneNumber}</p>
            </div>
          </div>
        </td>
        <td className="font-normal h-10 text-left">
          <div>{data.lastMessage.content.text.body}</div>
        </td>
        <td className=" font-normal h-10 text-left">
          <div>{DateFormat(new Date(data.lastMessage.timestamp * 1000).toString())}</div>
        </td>
        <td className=''>
            <button
              onClick={() => handleActionAssign(data)}
              className="col-action hover:bg-primary-light hover:text-primary-color rounded-full p-2 flex"
            >
              <span className="material-symbols-outlined">chat_paste_go</span>
            </button>
        </td>
      </tr>
    </>
  );
}

export default QueueTableRow;
