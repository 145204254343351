import React from "react";

function CustomerAddressTab() {
  return (
    <div className="mt-1">
      <ul>
        <li>
          <div className="border border-gray-50 hover:border-primary-color rounded-md px-2 py-1">
            <div className="text-[11px] text-gray-400 flex justify-between h-5">
              <div>Shipping</div>
              <button>
                <span class="material-symbols-outlined">more_horiz</span>
              </button>
            </div>
            <p className=" text-[12px] text-app-black-color">
              Manningachali House, Pallimukku, Pannippara Post, Edavanna,
              Malappuram, Kerela, 676541
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default CustomerAddressTab;
