import { createSlice } from "@reduxjs/toolkit";
import { fetcher } from "../../hooks/fetcher/useFetch";
import Config from "../../config/config";

const user = JSON.parse(sessionStorage.getItem("user"));

const slice = createSlice({
  name: "auth",
  initialState: user
    ? { isAuthenticated: true, user: user }
    : {
        loading: false,
        user: null,
        error: null,
        isAuthenticated: false,
      },
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
      state.isAuthenticated = true;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
      state.isAuthenticated = false;
    },

    logoutRequest: (state) => {
      state.loading = false;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
    logoutFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    setupRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    setupSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.isAuthenticated = true;
    },
    setupFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  setupRequest,
  setupSuccess,
  setupFailure,
} = slice.actions;

//handling login
export const login = (payload) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const response = await fetcher({
      url: `${Config.baseUrl}auth/login`,
      method: "POST",
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
    });
    sessionStorage.setItem("user", JSON.stringify(response?.data));
    dispatch(loginSuccess(response?.data));
  } catch (err) {
    dispatch(loginFailure(err));
  }
};

// register new store

export const setupStore = (payload) => async (dispatch) => {
  dispatch(setupRequest());
  try {
    await fetcher({
      url: `${Config.baseUrl}auth/register`,
      method: "POST",
      isAuth: false,
      body: JSON.stringify(payload),
    });
    dispatch(setupSuccess());
  } catch (err) {
    dispatch(setupFailure(err));
  }
};

// handle sign out
export const logout = () => (dispatch) => {
  dispatch(logoutRequest());
  if (sessionStorage.getItem("user")) {
    sessionStorage.removeItem("user");
    dispatch(loginSuccess());
  }
  dispatch(loginFailure("user not exist"));
};
export default slice.reducer;
