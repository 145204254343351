import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AppLogo } from "../../assets/logos/logo.svg";
import VideoPlayer from "../../components/video-player";
import Button from "../../components/button";
import PhoneInputField from "../../components/phone-input";
import { useDispatch, useSelector } from "react-redux";
import { setupStore } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";

function SignupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({
    website: "",
    name: "",
    email: "",
    password: "",
    mobile_no: "",
    store_name: "",
    iagree : false,
  });
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  const handleChangeInputData = (event) => {
    const currentData = { ...inputData };
    currentData[event.target.name] = event.target.value;
    setInputData(currentData);
  };

  const handleChangePhone = (value) => {
    const currentData = { ...inputData };
    currentData["mobile_no"] = value;
    setInputData(currentData);
  };

  const handleChangeCheckBox = (e) => {
    const currentData = { ...inputData };
    currentData["iagree"] = e.target.checked;
    setInputData(currentData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!inputData.email){
      toast.warning("Enter your valid email address");
      return;
    }

    if(!inputData.mobile_no){
      toast.warning("Enter your mobile number");
      return;
    }

    if(!inputData.store_name){
      toast.warning("Enter your Store name");
      return;
    }

    if(!inputData.website){
      toast.warning("Enter your Store website");
      return;
    }

    if(!inputData.name){
      toast.warning("Enter your name");
      return;
    }

    if(!inputData.password){
      toast.warning("Enter your password");
      return;
    }

    if(!inputData.iagree){
      toast.warning("Please read and agree terms and condition");
      return;
    }

    dispatch(setupStore(inputData));
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/connect-whatsapp");
      toast.success("success login");
    }
    if (error) {
      if (error?.status === 400) {
        console.log(error);
        toast.error(error?.info?.message[0]?.msg);
      } else {
        toast.error("Some error occured while loggin in!");
      }
    }
  }, [isAuthenticated, error]);
  return (
    <div className="flex h-[100vh]">
      <div className="hidden h-full sm:hidden md:block w-[65%]">
        <div className="flex flex-col items-center justify-center min-h-screen max-h-screen overflow-x-scroll no-scrollbar">
          <VideoPlayer />
          <h1 className="text-[34px] text-center font-bold py-[28px]">
            Build your business with Whatsapp
          </h1>
          <p className="text-center text-[14px] w-[60%]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab dolorem
            dolorum voluptate asperiores corrupti rem error suscipit sunt quos?
            Adipisci aperiam animi voluptatibus{" "}
          </p>
          <button className="flex items-center gap-2 text-app-black-color font-medium px-4 py-2 my-6 border border-gray rounded-sm hover:bg-primary-color hover:text-white shadow-lg">
            <span className="material-symbols-outlined">featured_video</span>
            Watch Demo
          </button>
        </div>
      </div>

      <div className="w-full sm:w-full md:w-[35%] bg-white md:flex md:justify-center overflow-x-scroll no-scrollbar">
        <div className="mx-[20px] my-[24px] md:w-[70%]">
          <AppLogo className="h-[48px] w-[164px]" />
          <div className="mt-4">
            <div className="text-[22px] my-[22px]">Create new account</div>
            <form>
              <div className="my-2">
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-none text-sm"
                  type="name"
                  placeholder="Enter your name"
                  required
                  name="name"
                  onChange={handleChangeInputData}
                  autoComplete="on"
                />
              </div>
              <div className="mt-6">
                <PhoneInputField value={""} onChange={handleChangePhone} />
              </div>
              <div className="my-6">
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-none text-sm"
                  type="name"
                  placeholder="Enter your store name"
                  required
                  name="store_name"
                  autoComplete="on"
                  onChange={handleChangeInputData}
                />
              </div>
              <div className="my-6">
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-none text-sm"
                  type="url"
                  placeholder="Company website"
                  required
                  name="website"
                  onChange={handleChangeInputData}
                  autoComplete="on"
                />
              </div>
              <div className="my-6">
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-none text-sm"
                  type="mail"
                  placeholder="Enter your email address"
                  required
                  name="email"
                  onChange={handleChangeInputData}
                  autoComplete="on"
                />
              </div>
              <div className="my-6">
                <input
                  className="border border-gray w-full rounded-sm py-2 px-5 focus:outline-none text-sm"
                  type="password"
                  placeholder="Enter your password"
                  required
                  name="password"
                  onChange={handleChangeInputData}
                />
              </div>
              <div className="my-6 flex items-start gap-4">
                <div className="mt-[3px] accent-primary-color">
                  <input
                    type="checkbox"
                    id="agree"
                    className="w-4 h-4"
                    required
                    onChange={handleChangeCheckBox}
                  />
                </div>
                <div className="">
                  <label htmlFor="agree" className="text-[14px] text-left">
                    I have read and understood the following
                  </label>
                  <button className="text-[12px] text-left text-primary-color underline font-normal">
                    Read this before you activate a number on the API
                  </button>
                </div>
              </div>
              <div className="my-8">
                <Button
                  className={
                    "bg-primary-color text-white rounded-sm py-2 w-[100%]"
                  }
                  label="Continue"
                  state={loading}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
          <div className="text-center pb-6">
            <p className="text-[14px] text-app-black-color">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login")}
                className="text-primary-color hover:font-medium cursor-pointer underline"
              >
                Sign In
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
